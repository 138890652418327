<script setup>
import { ref } from "vue"

import { MeasurementType } from "@/utils/GrowthAssessment"
import { getLengthSchema, getHeightSchema } from "@/schemas/GrowthAssessment"
import { getRelevantGrowthMeasurements } from "@/services/GrowthAssessment"
import GrowthMeasurementForm from "@/components/assessments/GrowthMeasurementForm.vue"
import LengthExample from "@/components/svg/LengthExample.vue"
import HeightThreeQuarterViewExample from "@/components/svg/HeightThreeQuarterViewExample.vue"
import MeasureLength from "@/views/library/MeasureLength.vue"
import MeasureHeight from "@/views/library/MeasureHeight.vue"

const needLength = ref(false)

function extraGetDataFunc(growthAssessment) {
  const wanted = getRelevantGrowthMeasurements(growthAssessment.child, growthAssessment)
  needLength.value = wanted.includes("length")
}

function getSchema() {
  return needLength.value ? getLengthSchema() : getHeightSchema()
}

function getUpdateDataFunc(data) {
  return {
    lengthInCm: parseFloat(data.lengthInCm) || null,
    typeOfLength: needLength.value ? "length" : "height",
  }
}
</script>
<template>
  <GrowthMeasurementForm
    :extra-get-data-func="extraGetDataFunc"
    :get-schema-func="getSchema"
    :extract-value-func="data => parseFloat(data.lengthInCm) || null"
    :get-measurement-type-func="() => needLength ? MeasurementType.Length : MeasurementType.Height"
    :get-update-data-func="getUpdateDataFunc"
  >
    <template #modalContent>
      <MeasureLength v-if="needLength" />
      <MeasureHeight v-else />
    </template>
    <template #exampleImage>
      <div>
        <div
          v-if="needLength"
          class="measurement-example"
        >
          <LengthExample />
        </div>
        <div
          v-else
          class="measurement-example tall"
        >
          <HeightThreeQuarterViewExample />
        </div>
      </div>
    </template>
  </GrowthMeasurementForm>
</template>

<style scoped>
  .measurement-example {
    min-height: 3em;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .measurement-example.tall {
    max-width: 75%;
  }
</style>
