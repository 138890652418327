<template>
  <!-- Original viewbox settings left a lot of room: viewBox="0 0 428 512" -->
  <svg
    width="100%"
    height="100%"
    viewBox="100 30 220 512"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    class="height-profile-example"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:2;"
  >
    <g
      v-if="race === 'c'"
      transform="matrix(1,0,0,1,1.11803,-13.528)"
    >
      <path
        d="M0,520.608L425,520.608"
        style="fill:none;fill-rule:nonzero;"
      />
      <path
        d="M0,520.608L425,520.608"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <path
        d="M208.037,320.648C179.549,319.753 168.224,319.753 149.596,319.753C149.596,319.753 144.063,326.374 144.063,335.79C144.063,345.205 148.024,359.205 150.027,367.774C151.145,372.555 152.013,382.469 152.013,382.469L192.16,382.469C192.16,382.469 202.316,361.648 201.758,347.4C205.336,337.043 208.037,320.648 208.037,320.648Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <path
        d="M158.453,220.558C151.67,227.521 146.064,239.109 145.261,248.464C144.156,261.332 148.854,269.006 150.211,281.849C153.167,309.821 152.717,303.619 149.594,319.785C171.496,319.958 208.035,320.679 208.035,320.679C208.037,308.59 205.326,265.626 205.862,254.126C206.397,242.626 197.104,228.242 187.936,221.955C172.678,211.491 160.999,217.945 158.453,220.558Z"
        style="fill:rgb(83,168,216);fill-rule:nonzero;"
      />
      <path
        d="M196.262,505.042C190.338,503.941 180.393,500.942 175.798,496.534C171.202,492.126 165.217,485.999 165.615,482.944C165.615,482.944 167.965,455.666 171.395,434.86C172.492,433.76 174.268,431.37 174.433,427.313C174.674,421.402 173.992,419.452 173.992,419.452L192.16,382.469L152.087,382.469C154.956,395.598 151.91,412.033 148.823,427.216C140.164,444.491 151.366,469.977 152.359,482.713C152.359,482.713 151.426,485.958 149.725,490.732C148.023,495.507 143.379,504.446 144.622,509.122C145.866,513.797 154.054,513.149 162.031,512.695C170.009,512.242 179.85,511.093 187.767,512.109C195.684,513.125 193.934,513.355 197.962,512.138C201.991,510.919 202.186,506.145 196.262,505.042Z"
        style="fill:rgb(211,211,211);fill-rule:nonzero;"
      />
      <path
        d="M163.381,264.804C163.381,264.804 159.095,283.475 159.632,291.897C160.168,300.318 167.881,334.264 167.881,334.264C167.881,334.264 165.934,346.106 167.881,352.422C167.881,352.422 164.146,350.61 163.733,354.528C163.594,355.852 169.757,360.843 174.354,358.738C176.062,357.334 178.854,359.25 179.879,357.1C181.696,356.67 183.423,356.106 183.78,353.826C185.627,354.528 187.188,354.093 188.118,352.422C190.757,347.685 188.118,343.475 188.118,343.475C188.118,343.475 190.769,347.51 192.193,345.755C193.619,344.001 190.309,340.317 188.118,337.685C185.927,335.054 179.734,329.264 179.734,329.264L176.557,289.792C176.557,289.792 185.863,263.476 185.627,255.055C185.391,246.634 167.013,242.965 163.381,264.804Z"
        style="fill:rgb(211,211,211);fill-rule:nonzero;"
      />
      <path
        d="M190.382,233.732C195.528,247.016 189.98,270.623 188.981,273.851C188.579,275.737 158.748,271.258 158.748,271.258C158.748,271.258 155.309,241.945 163.029,230.35C170.749,218.755 185.235,220.448 190.382,233.732Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <path
        d="M143,82.727L196.16,112.079L142.945,112.079L143,82.727Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M143,82.727L196.16,112.079L142.945,112.079L143,82.727Z"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <path
        d="M193.203,208.405C191.839,211.666 185.092,215.9 190.603,224.099C191.967,225.776 182.795,212.35 162.291,218.6C164.659,212.795 163.672,207.7 163.805,201.121C163.938,194.542 156.949,190.868 153.972,186.637C148.185,178.415 144.978,173.167 144.087,163.061C142.626,146.474 149.438,132.252 166.121,125.801C182.803,119.35 202.639,122.498 214.778,135.79C222.624,144.378 219.797,152.087 218.567,159.378C217.891,163.386 217.891,163.386 218.567,167.789C219.668,174.956 223.443,173.957 224.301,175.475C225.606,177.786 224.301,178.995 221.918,181.266C221.933,182.638 222.115,183.243 223.301,183.786C224.423,184.299 224.548,185.63 224.255,186.795C224.113,187.359 223.94,187.752 223.688,188.083C223.307,188.583 223.103,187.988 223.307,188.583C223.578,189.375 223.776,192.287 222.037,192.399C221.499,193.207 220.558,192.833 220.55,193.804C220.507,198.848 216.276,203.244 209.439,201.971C206.636,202.969 205.296,203.024 202.743,203.625C198.344,204.66 194.715,204.789 193.203,208.405Z"
        style="fill:rgb(211,211,211);fill-rule:nonzero;"
      />
      <path
        d="M204.166,123.481C201.402,122.422 199.066,122.402 196.844,121.802C194.279,121.112 192.458,119.325 189.675,118.904C187.239,118.537 183.926,119.483 180.594,119.562C177.73,119.63 173.912,119.001 171.229,119.514C168.804,119.978 166.395,121.722 164.2,122.57C161.734,123.523 158.849,123.657 156.726,125.006C154.749,126.262 153.139,128.359 151.539,130.029C150.506,131.108 148.147,132.342 147.268,133.548C146.045,135.227 145.718,137.579 144.979,139.498C144.453,140.866 142.313,144.771 141.906,146.2C140.924,149.658 141.991,150.862 141.916,155.287C141.873,157.825 141.27,160.53 141.488,162.532C141.798,165.366 143.094,167.303 143.895,169.356C144.215,170.178 145.544,174.713 145.896,175.475C147.873,179.762 149.329,179.523 152.032,184.299C157.263,193.541 159.58,194.293 163.784,201.971C168.722,197.975 173.442,193.122 174.447,186.637C169.706,181.568 165.186,175.761 170.88,169.031C174.711,164.502 182.027,168.282 183.445,174.136C184.632,173.007 185.543,171.893 186.582,170.737C185.821,162.768 179.249,153.213 182.96,146.2C187.997,136.679 189.979,134.692 198.257,133.764C204.517,133.725 210.718,134.121 216.276,137.368C214.763,135.152 215.504,133.146 213.559,130.802C212.529,129.562 210.657,128.318 208.688,127.192C206.937,126.189 205.545,124.207 204.166,123.481Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <path
        d="M218.891,192.287C219.359,192.334 219.829,192.352 220.292,192.454C220.755,192.556 221.202,192.734 221.659,192.863C221.385,193.285 220.823,193.383 220.55,193.804C220.247,193.475 220.271,193.438 219.949,193.125C219.628,192.812 219.247,192.56 218.891,192.287Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M216.552,179.976C215.336,178.867 218.314,176.752 218.081,177.135C216.76,179.311 219.014,180.069 219.43,179.976C221.083,179.605 221.979,177.594 222.556,178.294C223.133,178.994 222.845,178.63 222.101,179.341C221.493,179.921 221.576,179.863 220.274,180.531C218.974,181.2 217.768,181.084 216.552,179.976Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M211.176,165.973C211.176,165.973 212.649,170.163 211.581,171.444C209.293,171.441 206.954,170.28 205.488,167.954C207.714,166.721 209.349,166.067 211.176,165.973Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M206.023,168.542C208.932,166.685 211.423,167.149 212.606,167.209C213.182,167.239 211.986,166.35 212.231,165.973C209.07,165.54 207.002,167.145 204.957,168.542C206.63,171.273 209.469,171.516 211.581,171.444L212.606,170.686C210.261,170.801 208.293,171.273 206.023,168.542Z"
        style="fill:rgb(124,124,124);fill-rule:nonzero;"
      />
      <path
        d="M212.122,168.708C212.122,169.734 211.642,170.565 210.918,170.565C210.193,170.565 209.714,169.734 209.714,168.708C209.714,167.683 210.299,166.852 211.023,166.852C211.747,166.852 212.122,167.683 212.122,168.708Z"
        style="fill:rgb(124,124,124);fill-rule:nonzero;"
      />
      <path
        d="M202.541,163.386C202.541,163.386 206.621,161.572 208.729,161.255C210.838,160.94 213.106,161.55 213.941,161.544C214.777,161.539 215.414,159.471 214.005,159.204C212.596,158.937 210.24,159.177 208.632,159.559C207.022,159.941 202.541,163.386 202.541,163.386Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M179.873,176.83C181.99,174.992 179.37,170.561 176.173,170.317C171.833,169.985 171.52,171.944 171.229,174.049C171.229,174.049 173.335,170.011 177.474,173.614C178.987,174.932 175.869,175.283 174.955,180.112C174.429,182.897 179.092,184.299 179.092,184.299C179.092,184.299 176.156,182.533 177.474,180.112C178.791,177.69 177.757,178.667 179.873,176.83Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M222.353,186.795C221.779,187.144 220.03,189.891 218.31,186.755C217.919,186.043 217.556,183.015 217.556,183.015C217.556,183.015 217.846,187.046 217.277,189.155C216.709,191.263 214.559,193.59 214.559,193.59L218.31,189.976C218.639,189.912 218.97,189.797 219.351,189.835C220.048,189.906 221.788,189.311 222.49,189.054C223.192,188.798 223.163,188.449 223.757,187.907C224.644,187.098 224.033,185.962 224.301,185.208C223.608,185.474 222.925,186.446 222.353,186.795Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M182.96,195.965C183.646,197.203 189.455,203.916 203.372,203.492C203.372,203.492 194.374,205.421 193.408,207.977C193.408,207.977 184.099,202.946 182.96,195.965Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M174.447,186.637C174.447,186.637 175.698,188.694 180.638,188.5C180.638,188.5 176.367,189.76 175.301,190.633C174.234,191.505 172.907,191.472 172.907,191.472C172.907,191.472 174.466,188.651 174.447,186.637Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <rect
        x="132.646"
        y="512.996"
        width="149.137"
        height="7.629"
        style="fill:white;fill-rule:nonzero;"
      />
      <rect
        x="132.646"
        y="512.996"
        width="149.137"
        height="7.629"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <rect
        x="144.08"
        y="112.638"
        width="149.137"
        height="7.628"
        style="fill:white;fill-rule:nonzero;"
      />
      <rect
        x="144.08"
        y="112.638"
        width="149.137"
        height="7.628"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <rect
        x="132.646"
        y="62.054"
        width="11.434"
        height="450.942"
        style="fill:white;fill-rule:nonzero;"
      />
      <rect
        x="132.646"
        y="62.054"
        width="11.434"
        height="450.942"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <g v-if="showArrows">
        <path
          d="M313.546,175.089L341.501,175.089L341.501,161.708L313.546,161.708L313.546,155.485L302.361,168.399L313.546,181.313L313.546,175.089Z"
          style="fill:rgb(247,141,45);fill-rule:nonzero;"
        />
        <path
          d="M171.398,41.483L171.398,13.528L158.019,13.528L158.019,41.483L151.795,41.483L164.709,52.667L177.623,41.483L171.398,41.483Z"
          style="fill:rgb(247,141,45);fill-rule:nonzero;"
        />
        <path
          d="M293.217,167.912L219.828,167.912"
          style="fill:none;stroke:rgb(247,141,45);stroke-width:2px;stroke-dasharray:6,6;"
        />
        <path
          d="M164.069,62.054L164.069,512.221"
          style="fill:none;stroke:rgb(247,141,45);stroke-width:2px;stroke-dasharray:6,6;"
        />
        <path
          d="M313.546,518.911L341.501,518.911L341.501,505.532L313.546,505.532L313.546,499.307L302.361,512.221L313.546,525.135L313.546,518.911Z"
          style="fill:rgb(247,141,45);fill-rule:nonzero;"
        />
        <path
          d="M293.217,512.221L200.866,512.221"
          style="fill:none;stroke:rgb(247,141,45);stroke-width:2px;stroke-dasharray:6,6;"
        />
      </g>
    </g>
    <g
      v-else-if="race === 'b'"
      transform="matrix(1,0,0,1,1.11803,-13.528)"
    >
      <path
        d="M0,520.607L425,520.607"
        style="fill:none;fill-rule:nonzero;"
      />
      <path
        d="M0,520.607L425,520.607"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <path
        d="M208.037,320.648C179.549,319.754 168.224,319.754 149.596,319.754C149.596,319.754 144.063,326.374 144.063,335.789C144.063,345.205 148.024,359.205 150.027,367.773C151.145,372.555 152.013,382.468 152.013,382.468L192.16,382.468C192.16,382.468 202.316,361.648 201.758,347.4C205.336,337.044 208.037,320.648 208.037,320.648Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <path
        d="M158.453,220.558C151.67,227.521 146.064,239.109 145.261,248.464C144.156,261.332 148.854,269.005 150.211,281.849C153.167,309.82 152.717,303.619 149.594,319.785C171.496,319.958 208.035,320.679 208.035,320.679C208.037,308.59 205.326,265.627 205.862,254.127C206.397,242.627 197.104,228.242 187.936,221.954C172.678,211.491 160.999,217.944 158.453,220.558Z"
        style="fill:rgb(83,168,216);fill-rule:nonzero;"
      />
      <path
        d="M196.262,505.043C190.338,503.941 180.393,500.941 175.798,496.534C171.202,492.126 165.217,485.998 165.615,482.944C165.615,482.944 167.965,455.666 171.395,434.86C172.492,433.759 174.268,431.37 174.433,427.312C174.674,421.402 173.992,419.451 173.992,419.451L192.16,382.468L152.087,382.468C154.956,395.598 151.91,412.033 148.823,427.215C140.164,444.491 151.366,469.977 152.359,482.713C152.359,482.713 151.426,485.957 149.725,490.732C148.023,495.507 143.379,504.446 144.622,509.121C145.866,513.797 154.054,513.149 162.031,512.695C170.009,512.242 179.85,511.094 187.767,512.108C195.684,513.125 193.934,513.355 197.962,512.138C201.991,510.92 202.186,506.144 196.262,505.043Z"
        style="fill:rgb(211,211,211);fill-rule:nonzero;"
      />
      <path
        d="M163.381,264.803C163.381,264.803 159.095,283.475 159.632,291.896C160.168,300.317 167.881,334.264 167.881,334.264C167.881,334.264 165.934,346.106 167.881,352.422C167.881,352.422 164.146,350.61 163.733,354.527C163.594,355.852 169.757,360.843 174.354,358.738C176.062,357.335 178.854,359.249 179.879,357.099C181.696,356.67 183.423,356.106 183.78,353.826C185.627,354.527 187.188,354.093 188.118,352.422C190.757,347.685 188.118,343.474 188.118,343.474C188.118,343.474 190.769,347.509 192.193,345.755C193.619,344.001 190.309,340.317 188.118,337.685C185.927,335.053 179.734,329.264 179.734,329.264L176.557,289.791C176.557,289.791 185.863,263.475 185.627,255.054C185.391,246.633 167.013,242.965 163.381,264.803Z"
        style="fill:rgb(211,211,211);fill-rule:nonzero;"
      />
      <path
        d="M190.382,233.732C195.528,247.015 189.98,270.622 188.981,273.851C188.579,275.737 158.748,271.257 158.748,271.257C158.748,271.257 155.309,241.945 163.029,230.35C170.749,218.755 185.235,220.448 190.382,233.732Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <path
        d="M143,82.727L196.16,112.078L142.945,112.078L143,82.727Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M143,82.727L196.16,112.078L142.945,112.078L143,82.727Z"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <path
        d="M192.773,208.878C191.409,212.139 184.661,216.374 190.173,224.572C191.537,226.25 182.364,212.824 161.86,219.074C164.229,213.269 163.241,208.173 163.375,201.594C163.509,195.015 156.52,191.341 153.541,187.11C147.754,178.888 144.547,173.641 143.657,163.534C142.195,146.948 149.008,132.726 165.69,126.275C182.373,119.824 202.097,123.075 214.349,136.263C220.443,142.823 217.613,151.429 217.845,158.819C217.997,163.689 217.765,166.194 218.686,170.077C219.5,173.513 221.393,173.773 222.193,175.322C223.796,178.421 221.871,179.468 219.488,181.739C219.502,183.112 220.164,183.475 220.749,184.723C221.184,185.649 221.521,185.262 221.813,186.426C222.351,188.565 220.81,188.86 219.829,189.517C221.204,190.177 220.991,191.883 219.607,192.873C219.069,193.68 218.12,194.277 218.111,195.248C218.068,200.293 215.846,203.717 209.009,202.444C206.206,203.443 204.866,203.498 202.313,204.098C197.914,205.133 194.285,205.262 192.773,208.878Z"
        style="fill:rgb(211,211,211);fill-rule:nonzero;"
      />
      <path
        d="M203.3,125.028C194.555,121.682 190.604,119.788 180.164,120.036C158.979,120.538 141.865,133.427 141.486,155.76C141.221,171.43 146.372,175.531 151.603,184.773C156.832,194.014 159.15,194.767 163.354,202.444C168.292,198.449 173.013,193.595 174.018,187.11C169.276,182.041 164.756,176.235 170.449,169.505C174.28,164.976 181.598,168.755 183.015,174.609C184.202,173.481 185.113,172.366 186.151,171.21C185.392,163.242 184.642,154.674 185.84,146.831C187.038,138.988 190.514,136.902 197.826,134.237C204.087,134.198 210.288,134.594 215.846,137.841C214.333,135.626 213.675,134.681 211.729,132.337C209.784,129.993 206.228,126.571 203.3,125.028Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <path
        d="M216.461,192.76C216.93,192.808 217.398,192.825 217.862,192.927C218.325,193.029 218.772,193.207 219.229,193.336C218.955,193.758 218.68,194.178 218.406,194.599C218.104,194.27 217.841,193.912 217.52,193.598C217.198,193.285 216.817,193.033 216.461,192.76Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M217,179.924C219.574,179.511 220.606,178.791 221.871,177.763C221.774,178.037 222.022,178.524 221.278,179.234C220.671,179.815 221.362,179.765 219.582,180.449C218.281,181.118 217.662,180.202 217,179.924Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M210.746,166.446C210.746,166.446 211.719,168.796 210.65,170.077C208.362,170.074 206.597,168.718 204.478,167.609C206.704,166.378 208.918,166.541 210.746,166.446Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M204.998,167.559C208.123,166.571 210.095,166.883 211.278,166.944C211.854,166.973 211.57,166.571 211.815,166.194C208.655,165.76 206.162,166.165 204.117,167.561C206.359,169.107 208.854,170.353 210.968,170.28L211.376,169.962C209.03,170.077 207.636,168.859 204.998,167.559Z"
        style="fill:rgb(124,124,124);fill-rule:nonzero;"
      />
      <path
        d="M211.376,168.427C211.376,169.452 210.896,170.283 210.172,170.283C209.447,170.283 208.968,169.452 208.968,168.427C208.968,167.402 209.553,166.571 210.277,166.571C211.001,166.571 211.376,167.402 211.376,168.427Z"
        style="fill:rgb(124,124,124);fill-rule:nonzero;"
      />
      <path
        d="M202.11,162.538C202.11,162.538 206.19,160.723 208.3,160.407C210.408,160.091 212.677,160.702 213.512,160.696C214.348,160.69 214.983,158.622 213.574,158.355C212.165,158.088 209.811,158.328 208.201,158.711C206.593,159.092 202.11,162.538 202.11,162.538Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M179.443,177.303C181.561,175.465 178.939,171.034 175.743,170.79C171.403,170.458 171.09,172.418 170.799,174.523C170.799,174.523 172.905,170.484 177.044,174.088C178.558,175.406 175.439,175.756 174.525,180.585C173.998,183.371 178.662,184.773 178.662,184.773C178.662,184.773 175.727,183.006 177.044,180.585C178.361,178.164 177.327,179.14 179.443,177.303Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M218.137,188.464C217.564,188.813 216.242,187.956 215.88,187.229C215.518,186.502 214.986,184.773 214.986,184.773C214.986,184.773 215.416,187.52 214.848,189.628C214.279,191.737 212.128,194.064 212.128,194.064L215.88,190.449C216.208,190.385 216.54,190.271 216.921,190.309C217.618,190.379 218.526,190.004 219.229,189.747C219.931,189.491 220.723,189.183 221.316,188.641C222.203,187.832 221.888,186.93 221.813,186.426C221.12,186.693 218.71,188.114 218.137,188.464Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M182.53,196.439C183.216,197.676 189.025,204.389 202.941,203.965C202.941,203.965 193.944,205.895 192.979,208.45C192.979,208.45 183.669,203.42 182.53,196.439Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M174.018,187.11C174.018,187.11 175.269,189.168 180.208,188.974C180.208,188.974 175.938,190.234 174.871,191.107C173.805,191.979 172.478,191.945 172.478,191.945C172.478,191.945 174.036,189.125 174.018,187.11Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M199.001,136.115C199.001,136.115 199.93,139.985 200.431,147.043C200.431,147.043 202.824,139.888 201.941,136.359C201.941,136.359 203.889,142.669 205.196,146.673C205.196,146.673 206.928,132.965 204.478,133.68C202.11,134.371 198.837,132.737 191.3,134.603C191.3,134.603 191.78,137.527 191.3,141.762C191.3,141.762 193.099,138.762 193.194,135.939C193.194,135.939 196.097,144.232 196.017,147.584C196.017,147.584 196.626,137.324 196.017,134.603C196.017,134.603 197.724,138.762 197.8,141.585L199.001,136.115Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <path
        d="M208.131,136.115C208.131,136.115 211.124,140.526 211.624,147.584C211.624,147.584 212.955,139.888 212.072,136.359C212.072,136.359 213.64,141.816 214.947,145.82C214.947,145.82 215.349,142.541 215.124,139.63C215.077,139.033 218.137,145.401 218.137,144.855C218.14,139.675 214.646,136.212 214.404,135.762C213.235,133.591 207.968,132.737 200.431,134.603C200.431,134.603 200.91,137.527 200.431,141.762C200.431,141.762 202.229,138.762 202.325,135.939C202.325,135.939 205.227,144.232 205.146,147.584C205.146,147.584 205.756,137.324 205.146,134.603C205.146,134.603 206.854,138.762 206.931,141.585L208.131,136.115Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <rect
        x="132.646"
        y="512.995"
        width="149.137"
        height="7.628"
        style="fill:white;fill-rule:nonzero;"
      />
      <rect
        x="132.646"
        y="512.995"
        width="149.137"
        height="7.628"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <rect
        x="144.08"
        y="112.637"
        width="149.137"
        height="7.629"
        style="fill:white;fill-rule:nonzero;"
      />
      <rect
        x="144.08"
        y="112.637"
        width="149.137"
        height="7.629"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <rect
        x="132.646"
        y="62.053"
        width="11.434"
        height="450.942"
        style="fill:white;fill-rule:nonzero;"
      />
      <rect
        x="132.646"
        y="62.053"
        width="11.434"
        height="450.942"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <g v-if="showArrows">
        <path
          d="M313.546,175.089L341.501,175.089L341.501,161.709L313.546,161.709L313.546,155.485L302.361,168.399L313.546,181.313L313.546,175.089Z"
          style="fill:rgb(247,141,45);fill-rule:nonzero;"
        />
        <path
          d="M171.398,41.483L171.398,13.528L158.019,13.528L158.019,41.483L151.795,41.483L164.709,52.667L177.623,41.483L171.398,41.483Z"
          style="fill:rgb(247,141,45);fill-rule:nonzero;"
        />
        <path
          d="M293.217,167.912L219.828,167.912"
          style="fill:none;stroke:rgb(247,141,45);stroke-width:2px;stroke-dasharray:6,6;"
        />
        <path
          d="M164.069,62.053L164.069,512.221"
          style="fill:none;stroke:rgb(247,141,45);stroke-width:2px;stroke-dasharray:6,6;"
        />
        <path
          d="M313.546,518.912L341.501,518.912L341.501,505.531L313.546,505.531L313.546,499.307L302.361,512.221L313.546,525.135L313.546,518.912Z"
          style="fill:rgb(247,141,45);fill-rule:nonzero;"
        />
        <path
          d="M293.217,512.221L200.866,512.221"
          style="fill:none;stroke:rgb(247,141,45);stroke-width:2px;stroke-dasharray:6,6;"
        />
      </g>
    </g>
    <g
      v-else
      transform="matrix(1,0,0,1,1.11803,-13.528)"
    >
      <path
        d="M0,520.607L425,520.607"
        style="fill:none;fill-rule:nonzero;"
      />
      <path
        d="M0,520.607L425,520.607"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <path
        d="M208.037,320.648C179.549,319.753 168.224,319.753 149.596,319.753C149.596,319.753 144.063,326.374 144.063,335.789C144.063,345.204 148.024,359.204 150.027,367.773C151.145,372.555 152.013,382.469 152.013,382.469L192.16,382.469C192.16,382.469 202.316,361.648 201.758,347.4C205.336,337.043 208.037,320.648 208.037,320.648Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <path
        d="M158.453,220.558C151.67,227.52 146.064,239.109 145.261,248.464C144.156,261.331 148.854,269.006 150.211,281.849C153.167,309.82 152.717,303.619 149.594,319.785C171.496,319.958 208.035,320.679 208.035,320.679C208.037,308.59 205.326,265.626 205.862,254.126C206.397,242.626 197.104,228.242 187.936,221.954C172.678,211.491 160.999,217.945 158.453,220.558Z"
        style="fill:rgb(83,168,216);fill-rule:nonzero;"
      />
      <path
        d="M196.262,505.043C190.338,503.941 180.393,500.941 175.798,496.534C171.202,492.126 165.217,485.998 165.615,482.944C165.615,482.944 167.965,455.665 171.395,434.86C172.492,433.76 174.268,431.37 174.433,427.312C174.674,421.402 173.992,419.451 173.992,419.451L192.16,382.469L152.087,382.469C154.956,395.598 151.91,412.033 148.823,427.216C140.164,444.49 151.366,469.977 152.359,482.713C152.359,482.713 151.426,485.957 149.725,490.732C148.023,495.507 143.379,504.446 144.622,509.121C145.866,513.797 154.054,513.149 162.031,512.695C170.009,512.242 179.85,511.093 187.767,512.108C195.684,513.123 193.934,513.355 197.962,512.138C201.991,510.919 202.186,506.144 196.262,505.043Z"
        style="fill:rgb(211,211,211);fill-rule:nonzero;"
      />
      <path
        d="M163.381,264.804C163.381,264.804 159.095,283.476 159.632,291.896C160.168,300.317 167.881,334.265 167.881,334.265C167.881,334.265 165.934,346.106 167.881,352.422C167.881,352.422 164.146,350.61 163.733,354.527C163.594,355.852 169.757,360.843 174.354,358.738C176.062,357.334 178.854,359.249 179.879,357.1C181.696,356.67 183.423,356.106 183.78,353.826C185.627,354.527 187.188,354.093 188.118,352.422C190.757,347.685 188.118,343.475 188.118,343.475C188.118,343.475 190.769,347.51 192.193,345.755C193.619,344.001 190.309,340.317 188.118,337.685C185.927,335.054 179.734,329.264 179.734,329.264L176.557,289.791C176.557,289.791 185.863,263.476 185.627,255.055C185.391,246.634 167.013,242.965 163.381,264.804Z"
        style="fill:rgb(211,211,211);fill-rule:nonzero;"
      />
      <path
        d="M190.382,233.732C195.528,247.016 189.98,270.622 188.981,273.851C188.579,275.737 158.748,271.258 158.748,271.258C158.748,271.258 155.309,241.945 163.029,230.35C170.749,218.755 185.235,220.448 190.382,233.732Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <path
        d="M193.372,208.501C192.009,211.762 185.261,215.997 190.772,224.195C192.137,225.872 182.964,212.447 162.46,218.697C164.828,212.892 163.841,207.796 163.975,201.217C164.108,194.638 157.118,190.964 154.141,186.733C148.354,178.511 145.146,173.264 144.256,163.157C142.795,146.571 149.607,132.349 166.29,125.898C182.973,119.447 202.696,122.698 214.948,135.886C221.043,142.446 218.029,150.562 217.077,157.894C216.445,162.754 216.479,165.431 219.934,170.534C221.912,173.458 223.668,173.731 223.977,174.416C225.717,178.289 222.516,179.978 220.087,181.362C220.102,182.735 220.764,183.098 221.349,184.346C221.783,185.271 222.711,185.784 222.418,186.948C222.125,188.112 220.676,189.009 219.695,189.665C221.07,190.325 221.591,191.506 220.207,192.496C219.669,193.303 218.719,193.9 218.711,194.871C218.668,199.916 216.445,203.341 209.608,202.068C206.806,203.066 205.466,203.121 202.913,203.721C198.514,204.756 194.885,204.885 193.372,208.501Z"
        style="fill:rgb(211,211,211);fill-rule:nonzero;"
      />
      <path
        d="M203.898,124.652C195.154,121.305 191.203,119.412 180.764,119.659C159.579,120.161 142.464,133.051 142.086,155.384C141.82,171.053 146.972,175.154 152.202,184.395C157.432,193.637 159.75,194.39 163.953,202.068C168.892,198.072 173.612,193.218 174.616,186.733C169.876,181.664 165.355,175.858 171.049,169.128C174.88,164.599 182.197,168.378 183.614,174.232C184.802,173.103 185.713,171.99 186.751,170.833C185.99,162.865 185.241,154.297 186.439,146.454C187.638,138.611 191.113,136.525 198.426,133.86C204.687,133.821 210.888,134.218 216.445,137.464C214.933,135.249 214.274,134.304 212.329,131.96C210.384,129.617 206.827,126.194 203.898,124.652Z"
        style="fill:rgb(21,66,98);fill-rule:nonzero;"
      />
      <path
        d="M217.061,192.383C217.529,192.431 217.998,192.449 218.461,192.55C218.925,192.652 219.371,192.831 219.828,192.959C219.554,193.381 219.279,193.801 219.006,194.222C218.703,193.893 218.44,193.535 218.119,193.222C217.797,192.909 217.417,192.656 217.061,192.383Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M216.479,180.721C219.054,180.307 223.178,177.115 224.442,176.086C224.346,176.361 224.328,178.125 223.583,178.835C222.976,179.415 221.867,180.678 220.087,181.362C218.786,182.031 217.142,180.998 216.479,180.721Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M211.054,166.494C211.054,166.494 211.859,169.373 210.791,170.655C209.013,170.768 207.49,169.914 205.371,168.806L211.054,166.494Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M205.598,168.653C207.324,168.496 209.205,167.791 211.191,166.711C211.697,166.436 212.209,166.137 212.728,165.817L211.526,165.431C209.468,166.994 207.23,167.919 204.717,168.653C206.816,169.769 208.986,170.743 211.329,171.366L212.167,170.387C210.713,170.437 208.317,170.213 205.598,168.653Z"
        style="fill:rgb(124,124,124);fill-rule:nonzero;"
      />
      <path
        d="M212.013,168.555C212.013,169.653 211.384,170.543 210.608,170.543C209.833,170.543 209.204,169.653 209.204,168.555C209.204,167.457 209.833,166.567 210.608,166.567C211.384,166.567 212.013,167.457 212.013,168.555Z"
        style="fill:rgb(124,124,124);fill-rule:nonzero;"
      />
      <path
        d="M202.913,166.443C202.913,166.443 206.278,163.508 208.201,162.586C210.124,161.664 212.471,161.58 213.268,161.329C214.064,161.077 214.064,158.914 212.639,159.073C211.214,159.232 209.034,160.155 207.608,160.993C206.183,161.831 202.913,166.443 202.913,166.443Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M180.043,176.926C182.16,175.088 179.539,170.658 176.343,170.413C172.002,170.081 171.689,172.04 171.398,174.146C171.398,174.146 173.505,170.107 177.643,173.71C179.157,175.029 176.039,175.379 175.125,180.208C174.598,182.994 179.262,184.395 179.262,184.395C179.262,184.395 176.325,182.63 177.643,180.208C178.96,177.787 177.926,178.763 180.043,176.926Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M220.695,187.693C220.123,188.043 219.744,188.179 219.172,188.392C218.912,188.489 218.68,188.537 218.443,188.578C216.445,188.524 216.841,187.579 216.479,186.852C216.117,186.124 215.586,184.395 215.586,184.395C215.586,184.395 216.016,187.143 215.447,189.251C214.879,191.36 212.728,193.687 212.728,193.687L216.479,190.073C216.808,190.008 217.14,189.893 217.52,189.932C218.218,190.002 218.93,190.076 219.632,189.819C220.334,189.563 220.899,189.24 221.413,188.622C221.927,188.003 222.173,186.999 222.471,186.242C221.777,186.508 221.269,187.344 220.695,187.693Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M183.129,196.062C183.814,197.3 189.625,204.012 203.541,203.588C203.541,203.588 194.543,205.518 193.578,208.073C193.578,208.073 184.269,203.042 183.129,196.062Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M174.616,186.733C174.616,186.733 175.868,188.791 180.808,188.597C180.808,188.597 176.536,189.857 175.47,190.73C174.403,191.602 173.077,191.568 173.077,191.568C173.077,191.568 174.636,188.748 174.616,186.733Z"
        style="fill:rgb(180,180,180);fill-rule:nonzero;"
      />
      <path
        d="M143,82.726L196.16,112.078L142.945,112.078L143,82.726Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M143,82.726L196.16,112.078L142.945,112.078L143,82.726Z"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <rect
        x="132.646"
        y="512.995"
        width="149.137"
        height="7.628"
        style="fill:white;fill-rule:nonzero;"
      />
      <rect
        x="132.646"
        y="512.995"
        width="149.137"
        height="7.628"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <rect
        x="144.08"
        y="112.638"
        width="149.137"
        height="7.628"
        style="fill:white;fill-rule:nonzero;"
      />
      <rect
        x="144.08"
        y="112.638"
        width="149.137"
        height="7.628"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <rect
        x="132.646"
        y="62.054"
        width="11.434"
        height="450.941"
        style="fill:white;fill-rule:nonzero;"
      />
      <rect
        x="132.646"
        y="62.054"
        width="11.434"
        height="450.941"
        style="fill:none;stroke:rgb(124,124,124);stroke-width:1px;"
      />
      <g v-if="showArrows">
        <path
          d="M313.546,175.089L341.501,175.089L341.501,161.709L313.546,161.709L313.546,155.485L302.361,168.399L313.546,181.313L313.546,175.089Z"
          style="fill:rgb(247,141,45);fill-rule:nonzero;"
        />
        <path
          d="M313.546,518.912L341.501,518.912L341.501,505.531L313.546,505.531L313.546,499.308L302.361,512.221L313.546,525.135L313.546,518.912Z"
          style="fill:rgb(247,141,45);fill-rule:nonzero;"
        />
        <path
          d="M171.398,41.483L171.398,13.528L158.019,13.528L158.019,41.483L151.795,41.483L164.709,52.667L177.623,41.483L171.398,41.483Z"
          style="fill:rgb(247,141,45);fill-rule:nonzero;"
        />
        <path
          d="M293.217,167.912L219.828,167.912"
          style="fill:none;stroke:rgb(247,141,45);stroke-width:2px;stroke-dasharray:6,6;"
        />
        <path
          d="M293.217,512.221L200.866,512.221"
          style="fill:none;stroke:rgb(247,141,45);stroke-width:2px;stroke-dasharray:6,6;"
        />
        <path
          d="M164.069,62.054L164.069,512.221"
          style="fill:none;stroke:rgb(247,141,45);stroke-width:2px;stroke-dasharray:6,6;"
        />
      </g>
    </g>
  </svg>
</template>
<script setup>
  import { getRaceCode } from "@/utils/GlobalState"

  const race = getRaceCode()
  defineProps({
    showArrows: {
      type: Boolean,
      default: false,
    }
  })
</script>