<template>
  <svg
    viewBox="0 50 370 412"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    class="height-3-4-view-example"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:2"
  >
    <g transform="translate(-22.878 -38.858)">
      <g v-if="race === 'c'">
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M170.388 90.884h78.404v358.039h-78.404z"
        />
        <path
          class="gray-stroke"
          d="M170.388 90.884h78.404v358.039h-78.404z"
        />
        <path
          d="M244.476 449.117V90.884"
          class="no-fill"
        />
        <path
          d="M244.476 449.117V90.884M156.877 412.105s5.769 4.936 7.803 17.14-16.678 19.383-31.322 19.383c-14.644 0-60.611-1.22-69.967 0-9.355 1.221-8.949-4.529-2.034-9.179 6.916-4.651.814-12.787 7.093-17.421"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M129.802 132.467h114.406v6.803H129.802z"
        />
        <path
          class="gray-stroke"
          d="M129.802 132.467h114.406v6.803H129.802z"
        />
        <path
          d="M244.166 109.675h-6.127l-22.686 22.688h5.784"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M244.166 109.675h-6.127l-22.686 22.688h5.784"
          class="gray-stroke"
        />
        <path
          d="m244.166 109.675-22.791 22.791h22.833l-.042-22.791Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m244.166 109.675-22.791 22.791h22.833l-.042-22.791Z"
          class="gray-stroke"
        />
        <path
          d="M176.868 109.675h-6.127l-22.449 22.449h5.785"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M176.868 109.675h-6.127l-22.449 22.449h5.785"
          class="gray-stroke"
        />
        <path
          d="m176.868 109.675-22.791 22.791h22.833l-.042-22.791Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m176.868 109.675-22.791 22.791h22.833l-.042-22.791Z"
          class="gray-stroke"
        />
        <path
          d="M207.636 132.46v6.817"
          class="no-fill"
        />
        <path
          d="M207.636 132.46v6.817"
          class="gray-stroke"
        />
        <path
          d="M179.37 419.129h-7.921l-29.02 29.021h7.478"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M179.37 419.129h-7.921l-29.02 29.021h7.478"
          class="gray-stroke"
        />
        <path
          d="m179.37 419.129-29.463 29.463h29.518l-.055-29.463Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m179.37 419.129-29.463 29.463h29.518l-.055-29.463ZM132.374 337.18c-.759.41-1.561.82-2.41 1.21M146.224 322.11l-13.76-14.33"
          class="gray-stroke"
        />
        <path
          d="M56.791 363.612c-2.553-16.272 0-44.916 26.229-57.629"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M56.791 363.612c-.83-2.741-1.164-5.596-1.257-8.445-.08-2.851.009-5.708.277-8.55.538-5.681 1.793-11.322 3.94-16.64 2.163-5.305 5.246-10.26 9.177-14.45 3.917-4.215 8.74-7.498 13.875-9.993l.436.899c-5.009 2.514-9.644 5.802-13.422 9.927-3.816 4.081-6.81 8.905-8.915 14.087-2.138 5.173-3.429 10.692-4.009 16.277a68.959 68.959 0 0 0-.351 8.418c.06 2.813.222 5.61.249 8.47Z"
          class="gray-fill"
        />
        <path
          d="M56.258 350.997s-7.51 9.157-1.409 17.801c6.102 8.644 21.356 25.451 50.285 16.715"
          class="gray-stroke"
        />
        <path
          d="M52.443 360.764s-9.933 16.067-9.933 25.221c0 9.152 1.46 18.304 8.933 25.219 7.474 6.916 11.948 10.17 25.372 13.018 9.562 2.027 24.826.03 36.934 1.801 4.89.715 9.266 2.044 12.542 4.502"
          class="gray-stroke"
        />
        <path
          d="M56.791 444.061s-15.138 5.788-23.094 4.733c-7.957-1.053-11.797 1.055-8.543-6.166 3.254-7.22 16.499-5.797 18.305-15.966 1.806-10.169 13.332-11.306 13.332-11.306M97.964 335.54l11.981 22.08.009.01M95.634 379.25c-.19.85-.36 1.74-.51 2.67l-23.191-18.31"
          class="gray-stroke"
        />
        <path
          d="M97.804 251.945c1.122-.992 1.885-2.69 1.132-3.984-.753-1.295-3.333-.875-3.159.613 1.412-.27 2.256-1.897 2.062-3.321-.194-1.424-1.17-2.624-2.27-3.55-3.099-2.608-7.511-3.568-11.413-2.482-.758.212-1.537.524-2.014 1.15-.476.627-.51 1.646.116 2.123.74-.385-.239-1.416-1.063-1.544-6.76-1.046-14.371 3.729-14.946 10.545-.07.84-.026 1.74.445 2.44.47.7 1.483 1.088 2.193.634-3.158-.756-6.698 1.673-7.128 4.891-.432 3.219 2.344 6.493 5.589 6.595.007-.576-.731-.929-1.276-.741-.546.187-.897.713-1.148 1.232-1.159 2.401-.848 5.556 1.069 7.407 1.918 1.853 5.414 1.914 7.13-.126-1.201-.003-1.735 1.806-.929 2.697.805.891 2.279.849 3.321.254 1.042-.596 1.765-1.61 2.492-2.566.725-.957 1.538-1.926 2.657-2.361l17.14-19.906Z"
          class="gray-fill"
        />
        <path
          d="M133.743 277.211c-.32 1.306.776-5.957-8.72-6.166-12.818-7.705-17.95 3.528-23.913 8.65-5.965 5.121-7.76 11.997-9.141 19.735l-.222-.474-2.351-1.859c-1.235-.977-2.498-1.971-3.972-2.523-1.476-.554-3.226-.606-4.55.248-1.623 1.047-2.221 3.239-1.837 5.131.384 1.893 1.57 3.524 2.887 4.937 1.304 1.401 2.802 2.679 4.584 3.385 1.78.706 4.039.433 5.713-.495 2.136 4.908 11.619 9.377 17.328 10.485 5.709 1.109 10.755 1.838 15.301-1.789 6.848-5.463 12.373-13.956 10.974-22.604-1.006-6.224-4.194-6.96-2.081-16.661Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M133.743 277.211c-.32 1.306.776-5.957-8.72-6.166-12.818-7.705-17.95 3.528-23.913 8.65-5.965 5.121-7.76 11.997-9.141 19.735l-.222-.474-2.351-1.859c-1.235-.977-2.498-1.971-3.972-2.523-1.476-.554-3.226-.606-4.55.248-1.623 1.047-2.221 3.239-1.837 5.131.384 1.893 1.57 3.524 2.887 4.937 1.304 1.401 2.802 2.679 4.584 3.385 1.78.706 4.039.433 5.713-.495 2.136 4.908 11.619 9.377 17.328 10.485 5.709 1.109 10.755 1.838 15.301-1.789 6.848-5.463 12.373-13.956 10.974-22.604-1.006-6.224-4.194-6.96-2.081-16.661Z"
          class="gray-stroke"
        />
        <path
          d="M80.593 295.982c-6.12-3.51-8.604-12.172-7.47-22.155 1.224-10.78 14.468-25.121 25.813-25.866 11.609-.762 20.156 1.302 27.437 10.035 3.423 4.105 8.643 14.024 7.37 19.215-2.481-6.166-7.13-5.589-10.962-4.922-3.667.639-6.118-2.386-6.118-2.386s-.731 5.435-2.628 6.149c-20.266 7.627-21.559 26.223-21.559 26.223s-5.085-11.202-11.883-6.293Z"
          class="gray-fill"
        />
        <path
          d="M78.849 308.679s-.199 1.913 4.171 7.677c4.371 5.764 27.525 21.429 32.439 19.187 0 0 4.048-10.356 3.921-16.593"
          class="gray-stroke"
        />
        <path
          d="M121.85 311.137s11.614-.61 8.235-8.381c-1.878-4.317-3.863-7.077-3.863-7.077"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M121.85 311.137c1.837-.326 3.641-.832 5.259-1.628.794-.415 1.546-.908 2.106-1.547.561-.634.917-1.403.973-2.23.136-1.67-.806-3.341-1.529-5.037-.767-1.683-1.56-3.359-2.437-5.016 1.241 1.413 2.281 2.993 3.188 4.64.44.838.877 1.652 1.239 2.551.366.905.618 1.91.521 2.948a4.47 4.47 0 0 1-1.286 2.764c-.705.739-1.577 1.234-2.467 1.603-1.794.718-3.696.982-5.567.952Z"
          class="gray-fill"
        />
        <path
          d="M110.545 302.611s5.321-5.711 12.236-6.932"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M110.545 302.611c.683-.998 1.527-1.873 2.43-2.672a19.825 19.825 0 0 1 2.932-2.128 16.818 16.818 0 0 1 3.309-1.505 12.08 12.08 0 0 1 3.565-.627c-1.126.443-2.223.841-3.278 1.354-1.051.509-2.093 1.028-3.088 1.639-2.017 1.179-3.925 2.528-5.87 3.939Z"
          class="gray-fill"
        />
        <path
          d="M128.477 293.872s1.915-3.496 6.042-2.674"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M128.477 293.872c.127-.614.452-1.167.862-1.64a4.51 4.51 0 0 1 1.523-1.131c.586-.257 1.227-.415 1.865-.383a3.733 3.733 0 0 1 1.792.48c-.619.112-1.181.158-1.718.297a7.207 7.207 0 0 0-1.525.516c-.984.429-1.853 1.08-2.799 1.861Z"
          class="gray-fill"
        />
        <path
          d="M126.373 324.85s7.929 26.559 3.592 33.88c-4.336 7.322-11.527 9.761-11.527 9.761"
          class="gray-stroke"
        />
        <path
          d="M89.054 306.64c-1.728.366-1.422-.657-3.239-2.52.489.608 2.929-.595 3.097-1.357.169-.762.267-1.524-.198-2.151-1.538-2.075-3.595-2.849-4.93-2.899-1.035.365-2.545 1.432-.667 4.139"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M89.054 306.64c-.344.133-.731.252-1.151.122-.424-.137-.702-.476-.94-.752l-.71-.829c-.235-.27-.498-.508-.748-.759l.648-.573c-.072-.047.102.023.291-.025a2.95 2.95 0 0 0 .604-.186c.404-.174.81-.408 1.125-.675.153-.125.279-.301.265-.322.048-.23.088-.456.105-.668.035-.43-.014-.786-.219-1.07-.556-.738-1.21-1.411-1.991-1.907-.775-.495-1.656-.868-2.562-.951l.123-.018c-.736.21-1.402.715-1.472 1.497-.083.781.3 1.591.695 2.328-.53-.65-1.037-1.404-1.079-2.344a1.867 1.867 0 0 1 .478-1.32c.318-.368.732-.61 1.157-.788l.048-.02.076.001c2.137.048 4.063 1.289 5.309 2.944.394.513.494 1.198.426 1.739a6.188 6.188 0 0 1-.14.787c-.128.466-.383.651-.591.847a4.82 4.82 0 0 1-1.416.802 3.777 3.777 0 0 1-.814.211c-.279.01-.676.11-1.094-.319l.649-.574c.51.571.945 1.223 1.298 1.868.19.308.337.629.604.789.268.17.656.174 1.026.165Z"
          class="gray-fill"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 140.528H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 140.528H237v24.765h-7.658z"
        />
        <path
          d="M237 140.528v24.765"
          class="no-fill"
        />
        <path
          d="M237 140.528v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 165.293v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 165.293v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 143.56h-1.752"
          class="no-fill"
        />
        <path
          d="M237 143.56h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 149.799h-1.752"
          class="no-fill"
        />
        <path
          d="M237 149.799h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 155.902h-1.752"
          class="no-fill"
        />
        <path
          d="M237 155.902h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 165.293h-4.429"
          class="no-fill"
        />
        <path
          d="M237 165.293h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 152.851h-3.528"
          class="no-fill"
        />
        <path
          d="M237 152.851h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 146.68h-3.528"
          class="no-fill"
        />
        <path
          d="M237 146.68h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 158.942h-3.528"
          class="no-fill"
        />
        <path
          d="M237 158.942h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 161.982h-1.752"
          class="no-fill"
        />
        <path
          d="M237 161.982h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 165.633H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 165.633H237v24.766h-7.658z"
        />
        <path
          d="M237 165.633v24.766"
          class="no-fill"
        />
        <path
          d="M237 165.633v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 190.399v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 190.399v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 168.667h-1.752"
          class="no-fill"
        />
        <path
          d="M237 168.667h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 174.905h-1.752"
          class="no-fill"
        />
        <path
          d="M237 174.905h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 181.008h-1.752"
          class="no-fill"
        />
        <path
          d="M237 181.008h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 190.399h-4.429"
          class="no-fill"
        />
        <path
          d="M237 190.399h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 177.957h-3.528"
          class="no-fill"
        />
        <path
          d="M237 177.957h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 171.786h-3.528"
          class="no-fill"
        />
        <path
          d="M237 171.786h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 184.048h-3.528"
          class="no-fill"
        />
        <path
          d="M237 184.048h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 187.088h-1.752"
          class="no-fill"
        />
        <path
          d="M237 187.088h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 190.74H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 190.74H237v24.765h-7.658z"
        />
        <path
          d="M237 190.74v24.765"
          class="no-fill"
        />
        <path
          d="M237 190.74v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 215.505V190.74"
          class="no-fill"
        />
        <path
          d="M229.342 215.505V190.74"
          class="gray-stroke"
        />
        <path
          d="M237 193.772h-1.752"
          class="no-fill"
        />
        <path
          d="M237 193.772h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 200.011h-1.752"
          class="no-fill"
        />
        <path
          d="M237 200.011h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 206.114h-1.752"
          class="no-fill"
        />
        <path
          d="M237 206.114h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 215.505h-4.429"
          class="no-fill"
        />
        <path
          d="M237 215.505h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 203.062h-3.528"
          class="no-fill"
        />
        <path
          d="M237 203.062h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 196.891h-3.528"
          class="no-fill"
        />
        <path
          d="M237 196.891h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 209.154h-3.528"
          class="no-fill"
        />
        <path
          d="M237 209.154h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 212.194h-1.752"
          class="no-fill"
        />
        <path
          d="M237 212.194h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 215.845H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 215.845H237v24.766h-7.658z"
        />
        <path
          d="M237 215.845v24.766"
          class="no-fill"
        />
        <path
          d="M237 215.845v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 240.611v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 240.611v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 218.878h-1.752"
          class="no-fill"
        />
        <path
          d="M237 218.878h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 225.117h-1.752"
          class="no-fill"
        />
        <path
          d="M237 225.117h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 231.22h-1.752"
          class="no-fill"
        />
        <path
          d="M237 231.22h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 240.611h-4.429"
          class="no-fill"
        />
        <path
          d="M237 240.611h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 228.168h-3.528"
          class="no-fill"
        />
        <path
          d="M237 228.168h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 221.998h-3.528"
          class="no-fill"
        />
        <path
          d="M237 221.998h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 234.26h-3.528"
          class="no-fill"
        />
        <path
          d="M237 234.26h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 237.299h-1.752"
          class="no-fill"
        />
        <path
          d="M237 237.299h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 240.952H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 240.952H237v24.765h-7.658z"
        />
        <path
          d="M237 240.952v24.765"
          class="no-fill"
        />
        <path
          d="M237 240.952v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 265.717v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 265.717v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 243.984h-1.752"
          class="no-fill"
        />
        <path
          d="M237 243.984h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 250.223h-1.752"
          class="no-fill"
        />
        <path
          d="M237 250.223h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 256.326h-1.752"
          class="no-fill"
        />
        <path
          d="M237 256.326h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 265.717h-4.429"
          class="no-fill"
        />
        <path
          d="M237 265.717h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 253.274h-3.528"
          class="no-fill"
        />
        <path
          d="M237 253.274h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 247.103h-3.528"
          class="no-fill"
        />
        <path
          d="M237 247.103h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 259.366h-3.528"
          class="no-fill"
        />
        <path
          d="M237 259.366h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 262.406h-1.752"
          class="no-fill"
        />
        <path
          d="M237 262.406h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 266.057H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 266.057H237v24.766h-7.658z"
        />
        <path
          d="M237 266.057v24.766"
          class="no-fill"
        />
        <path
          d="M237 266.057v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 290.823v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 290.823v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 269.09h-1.752"
          class="no-fill"
        />
        <path
          d="M237 269.09h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 275.329h-1.752"
          class="no-fill"
        />
        <path
          d="M237 275.329h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 281.432h-1.752"
          class="no-fill"
        />
        <path
          d="M237 281.432h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 290.823h-4.429"
          class="no-fill"
        />
        <path
          d="M237 290.823h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 278.38h-3.528"
          class="no-fill"
        />
        <path
          d="M237 278.38h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 272.209h-3.528"
          class="no-fill"
        />
        <path
          d="M237 272.209h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 284.471h-3.528"
          class="no-fill"
        />
        <path
          d="M237 284.471h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 287.511h-1.752"
          class="no-fill"
        />
        <path
          d="M237 287.511h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 291.164H237v24.764h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 291.164H237v24.764h-7.658z"
        />
        <path
          d="M237 291.164v24.764"
          class="no-fill"
        />
        <path
          d="M237 291.164v24.764"
          class="gray-stroke"
        />
        <path
          d="M229.342 315.928v-24.764"
          class="no-fill"
        />
        <path
          d="M229.342 315.928v-24.764"
          class="gray-stroke"
        />
        <path
          d="M237 294.196h-1.752"
          class="no-fill"
        />
        <path
          d="M237 294.196h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 300.434h-1.752"
          class="no-fill"
        />
        <path
          d="M237 300.434h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 306.538h-1.752"
          class="no-fill"
        />
        <path
          d="M237 306.538h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 315.928h-4.429"
          class="no-fill"
        />
        <path
          d="M237 315.928h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 303.486h-3.528"
          class="no-fill"
        />
        <path
          d="M237 303.486h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 297.315h-3.528"
          class="no-fill"
        />
        <path
          d="M237 297.315h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 309.578h-3.528"
          class="no-fill"
        />
        <path
          d="M237 309.578h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 312.618h-1.752"
          class="no-fill"
        />
        <path
          d="M237 312.618h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 316.269H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 316.269H237v24.766h-7.658z"
        />
        <path
          d="M237 316.269v24.766"
          class="no-fill"
        />
        <path
          d="M237 316.269v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 341.035v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 341.035v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 319.302h-1.752"
          class="no-fill"
        />
        <path
          d="M237 319.302h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 325.541h-1.752"
          class="no-fill"
        />
        <path
          d="M237 325.541h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 331.644h-1.752"
          class="no-fill"
        />
        <path
          d="M237 331.644h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 341.035h-4.429"
          class="no-fill"
        />
        <path
          d="M237 341.035h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 328.592h-3.528"
          class="no-fill"
        />
        <path
          d="M237 328.592h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 322.421h-3.528"
          class="no-fill"
        />
        <path
          d="M237 322.421h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 334.683h-3.528"
          class="no-fill"
        />
        <path
          d="M237 334.683h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 337.723h-1.752"
          class="no-fill"
        />
        <path
          d="M237 337.723h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 341.375H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 341.375H237v24.765h-7.658z"
        />
        <path
          d="M237 341.375v24.765"
          class="no-fill"
        />
        <path
          d="M237 341.375v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 366.14v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 366.14v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 344.408h-1.752"
          class="no-fill"
        />
        <path
          d="M237 344.408h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 350.646h-1.752"
          class="no-fill"
        />
        <path
          d="M237 350.646h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 356.75h-1.752"
          class="no-fill"
        />
        <path
          d="M237 356.75h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 366.14h-4.429"
          class="no-fill"
        />
        <path
          d="M237 366.14h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 353.698h-3.528"
          class="no-fill"
        />
        <path
          d="M237 353.698h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 347.527h-3.528"
          class="no-fill"
        />
        <path
          d="M237 347.527h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 359.79h-3.528"
          class="no-fill"
        />
        <path
          d="M237 359.79h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 362.83h-1.752"
          class="no-fill"
        />
        <path
          d="M237 362.83h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 366.481H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 366.481H237v24.766h-7.658z"
        />
        <path
          d="M237 366.481v24.766"
          class="no-fill"
        />
        <path
          d="M237 366.481v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 391.247v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 391.247v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 369.513h-1.752"
          class="no-fill"
        />
        <path
          d="M237 369.513h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 375.752h-1.752"
          class="no-fill"
        />
        <path
          d="M237 375.752h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 381.855h-1.752"
          class="no-fill"
        />
        <path
          d="M237 381.855h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 391.247h-4.429"
          class="no-fill"
        />
        <path
          d="M237 391.247h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 378.804h-3.528"
          class="no-fill"
        />
        <path
          d="M237 378.804h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 372.633h-3.528"
          class="no-fill"
        />
        <path
          d="M237 372.633h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 384.895h-3.528"
          class="no-fill"
        />
        <path
          d="M237 384.895h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 387.935h-1.752"
          class="no-fill"
        />
        <path
          d="M237 387.935h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 391.586H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 391.586H237v24.766h-7.658z"
        />
        <path
          d="M237 391.586v24.766"
          class="no-fill"
        />
        <path
          d="M237 391.586v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 416.352v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 416.352v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 394.62h-1.752"
          class="no-fill"
        />
        <path
          d="M237 394.62h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 400.858h-1.752"
          class="no-fill"
        />
        <path
          d="M237 400.858h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 406.961h-1.752"
          class="no-fill"
        />
        <path
          d="M237 406.961h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 416.352h-4.429"
          class="no-fill"
        />
        <path
          d="M237 416.352h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 403.91h-3.528"
          class="no-fill"
        />
        <path
          d="M237 403.91h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 397.739h-3.528"
          class="no-fill"
        />
        <path
          d="M237 397.739h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 410.001h-3.528"
          class="no-fill"
        />
        <path
          d="M237 410.001h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 413.042h-1.752"
          class="no-fill"
        />
        <path
          d="M237 413.042h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 416.693H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 416.693H237v24.765h-7.658z"
        />
        <path
          d="M237 416.693v24.765"
          class="no-fill"
        />
        <path
          d="M237 416.693v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 441.458v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 441.458v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 419.725h-1.752"
          class="no-fill"
        />
        <path
          d="M237 419.725h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 425.964h-1.752"
          class="no-fill"
        />
        <path
          d="M237 425.964h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 432.067h-1.752"
          class="no-fill"
        />
        <path
          d="M237 432.067h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 441.458h-4.429"
          class="no-fill"
        />
        <path
          d="M237 441.458h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 429.015h-3.528"
          class="no-fill"
        />
        <path
          d="M237 429.015h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 422.844h-3.528"
          class="no-fill"
        />
        <path
          d="M237 422.844h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 435.107h-3.528"
          class="no-fill"
        />
        <path
          d="M237 435.107h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 438.147h-1.752"
          class="no-fill"
        />
        <path
          d="M237 438.147h-1.752"
          class="gray-stroke"
        />
        <path
          d="M154.38 253.578h.26c-.15-.649-.26 0-.26 0ZM166.663 305.376c2.869-6.837-.069-9.673-.069-9.673s4.507-26.063 4.334-32.328c-.242-8.792-1.08-11.627-1.08-11.627H156.64c.162.697.373 4.727.549 10.869.301 10.461 1.347 19.668-.093 30.377-.732 5.448-2.716 5.33-3.815 9.271-.184.659-.955 4.733-.966 5.463-.999 3.072-.963 3.68-.337 6.791.183.914 3.244-.354 3.244-4.908 1.646 2.573 2.128 7.487 4.93 8.148 6.714 1.585 5.657 0 6.511-.983 1.343-1.547.163-3.027-.458-4.266-.399-.797.88 1.515 1.83.763 1.677-1.328-2.317-5.645-1.372-7.897Z"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M174.195 251.748h-18.973s1.322-17.554 4.168-25.128c2.846-7.575 14.805-13.408 14.805-13.408v38.536Z"
          style="fill:#1d4b6d;fill-rule:nonzero"
        />
        <path
          d="M164.832 337.171s8.005 23.333 8.085 28.021c0 0-.305 1.663.915 6.657 0 0 5.187 43.794 4.272 48.814-.915 5.019-7.452 6.133-10.373 8.583-2.921 2.449-3.868 2.705-8.341 4.84-4.473 2.136-8.439 1.526-8.134 5.187.305 3.66 8.542 2.745 14.949 2.745s8.848-.61 13.729-1.22c4.882-.61 6.712 1.22 11.746-.916 5.034-2.134-3.622-14.005-3.107-19.053.513-5.048 2.064-15.996 3.178-27.596 1.114-11.601-1.874-14.452-2.58-21.164-.706-6.712 2.509-34.898 2.509-34.898"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M214.997 336.909s-6.104 25.723-5.677 32.459c.426 6.735 4.775 11.42 3.18 22.964-1.594 11.544-3.575 24.126-4.299 29.148-.722 5.022 7.509 17.174 2.391 19.099-5.118 1.923-6.688 1.735-11.607 1.735-5.726 0-7.61-.326-14.011-.592-6.401-.266-14.67 1.306-14.822-2.364-.153-3.671 3.784-3.897 8.341-5.845 4.558-1.947 5.515-2.164 8.536-4.49 3.02-2.325 8.066-3.064 9.189-8.041 1.123-4.978-4.451-49.308-4.451-49.308-1.012-5.041-.727-6.592-.727-6.592.116-4.688-3.083-28.173-3.083-28.173s21.33-6.438 27.04 0Z"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M180.816 209.68a13.446 13.446 0 0 0-6.621 3.532c-3.692 3.587-8.004 10.058-9.142 13.408-1.627 4.789-5.393 13.44-4.848 23.093.643 11.39-.392 32.488.675 41.36 0 0 37.664-.08 46.756-.08 0 0-1.797-24.923 6.134-40.681 4.5-8.941 6.204-19.17 2.95-26.666-.846-1.947-4.117-7.484-6.237-8.583-5.533-2.869-10.718-6.718-16.922-6.718-5.188 0-9.72.703-12.745 1.335Z"
          style="fill:#66b1dd;fill-rule:nonzero"
        />
        <path
          d="M207.597 290.993c6.134 8.135 7.574 16.483 7.361 20.228-.212 3.745 0 25.688 0 25.688-7.728 2.892-26.425.262-26.425.262-14.782 3.38-23.739 0-23.739 0s-1.002-9.211-3.057-18.495c-2.901-13.102-.895-27.603-.895-27.603l46.755-.08Z"
          style="fill:#2d5a84;fill-rule:nonzero"
        />
        <path
          d="M210.483 215.063s7.296 2.922 12.366 12.217c2.936 5.383 4.221 24.468 4.221 24.468-8.767 2.126-14.649 1.945-23.797 0 0 0-2.351-24.925 0-30.158 2.351-5.232 7.21-6.527 7.21-6.527Z"
          style="fill:#275579;fill-rule:nonzero"
        />
        <path
          d="M208.57 251.748s2.35 16.672 2.108 25.463c-.173 6.266 0 16.662 0 16.662s-5.422 4.146-5.526 11.177c-.111 7.463-1.06 6.494-.202 7.638.763 1.016 3.357-.07 3.126-3.11.624-1.275 1.177-2.584 1.568-3.248 1.002-1.702 1.668 1.993 1.034 4.349-.634 2.357-7.372 6.049-6.55 8.542.309.935 3.721.916 3.721.916s.531.463 1.741.305c1.108-.144 3.903-1.14 3.903-1.14s3.155-2.443 3.509-3.374c2.621-6.896 2.618-10.066 1.405-14.706-1.295-4.962-1.233-4.839.186-10.149 2.024-7.576 4.869-12.343 6.697-22.648 1.421-8.01 0-16.677 0-16.677-5.611-2.095-11.182-1.972-16.72 0Z"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M188.111 336.785c-.004 1.437.139 2.946.269 4.427.137 1.488.301 2.976.466 4.463.338 2.975.705 5.948 1.095 8.917a506.967 506.967 0 0 1-1.59-8.847 241.715 241.715 0 0 1-.715-4.443c-.214-1.492-.442-2.959-.525-4.517h1ZM190.316 427.025c-.693.688-1.41 1.095-2.139 1.543a46.52 46.52 0 0 1-2.212 1.222 121.948 121.948 0 0 1-4.505 2.216 270.725 270.725 0 0 1-9.157 4.072l-.013.005-.014.003c-.585.134-1.168.469-1.505.983-.342.512-.37 1.188-.43 1.816-.04.632.024 1.295.357 1.842.33.555.936.881 1.551 1.05-.619-.149-1.245-.455-1.604-1.016-.364-.553-.451-1.236-.433-1.881.043-.637.036-1.32.394-1.917.358-.582.96-.962 1.617-1.138l-.027.008a267.287 267.287 0 0 0 8.984-4.395 120.83 120.83 0 0 0 4.393-2.365c.722-.41 1.434-.833 2.125-1.278.677-.441 1.396-.92 1.869-1.433l.749.663Z"
          class="gray-fill"
        />
        <path
          d="M187.079 337.252c-.598-4.083-.989-8.172-1.34-12.265a481.031 481.031 0 0 1-.87-12.288l.1.131a56.32 56.32 0 0 1-4.835-2.067c-.785-.39-1.561-.8-2.307-1.263-.726-.477-1.482-.978-1.961-1.72.499.727 1.264 1.202 2 1.656.754.44 1.54.825 2.332 1.191a55.422 55.422 0 0 0 4.87 1.92l.091.032.008.099c.355 4.087.758 8.17 1.218 12.245.467 4.073.974 8.145 1.681 12.166l-.987.163Z"
          style="fill:#231f20;fill-rule:nonzero"
        />
        <path
          d="m173.884 197.366 1.129 11.993c-1.279 3.782.551 8.755 5.485 8.755 5.523 0 10.129-2.337 12.597-4.057 3.805-2.65 5.495-5.174 5.495-5.174-1.402-5.54-1.847-15.992 1.552-22.213 3.083-5.643-25.905 5.058-26.258 10.696Z"
          style="fill:#d3d3d3;fill-rule:nonzero"
        />
        <clipPath id="a">
          <path
            d="m173.884 197.366 1.129 11.993c-1.279 3.782.551 8.755 5.485 8.755 5.523 0 10.129-2.337 12.597-4.057 3.805-2.65 5.495-5.174 5.495-5.174-1.402-5.54-1.847-15.992 1.552-22.213 3.083-5.643-25.905 5.058-26.258 10.696Z"
            clip-rule="nonzero"
          />
        </clipPath>
        <g clip-path="url(#a)">
          <path
            d="M171.164 197.637c-1.539-5.707 27.323-19.839 30.855-15.382-1.016.949-4.796 10.755-8.157 19.231-2.355 5.939-5.322 5.581-9.218 7.441-2.449 1.169-8.075 3.674-9.631.432-1.557-3.242-2.817-10.791-3.849-11.722Z"
            style="fill:#b4b4b4;fill-rule:nonzero"
          />
        </g>
        <path
          d="M164.149 186.058s.257 1.014 1.584.957c1.325-.057 3.764-.551 5.514.002 1.751.554 2.241-.115 2.482-.459.623-.886-.824-3.248-.824-3.248s.544 2.327-.595 2.748c-.874.323-2.564-.443-3.807-.788-.799-.222-1.857-.574-2.658-.357l-1.696 1.145ZM182.999 170.297c-1.79-.816-6.915-1.171-8.647-1.161-3.362.02-1.916 8.849-1.916 8.849s.763-3.39 1.023-4.435c.426-1.712 1.233-2.426 3.15-2.466 1.917-.04 9.072 1.297 9.072 1.297s-.902-1.272-2.682-2.084ZM175.244 187.854c-.793 1.077-1.442 1.951-1.442 1.951s-4.262-.18-4.593-.266l-1.284.398-1.114-.398s-.455.186-1.43.41c-.472.108-.567-1.186-.477-1.331-.079.128-.752.813-.745 1.773.007.959.977 2.156.949 2.253.098.002-.141-1.799.273-1.987.415-.19 1.026.299 1.333.349.558.094 1.256.29 2.157.301 4.147.048 4.778-.689 5.5-.952l2.791 3.889c-.729-2.086-2.438-4.858-1.918-6.39ZM205.066 177.985s.018-2.787-1.756-2.672c-.76.049-2.612.373-3.4 2.352-.997 2.509 1.253 1.592 1.253 2.893 0 1.301.307 1.001-.953 2.672 0 0 2.83-1.536 3.03-2.481.313-1.492-3.175-.859-1.057-3.126 1.263-1.352 2.883.362 2.883.362Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
        <path
          d="M210.773 171.153c3.001-17.138 3.32-26.991-28.981-26.991-17.885 0-20.735 9.259-21.34 25.104-.144 3.802 1.665 1.923 1.665 6.545 0 1.356-2.669 2.484-2.7 7.221-.033 5.135 1.035 13.405 6.079 15.034 1.261.407 1.024 3.472 4.644 3.472 2.13 0 5.985.71 8.37-2.042 4.05.54 6.697.209 8.285-.06 8.366-1.411 10.404-7.501 11.096-12.761 3.519 1.063 10.317-.886 12.882-15.522Z"
          style="fill:#d3d3d3;fill-rule:nonzero"
        />
        <path
          d="M179.866 174.42c1.582 0 3.465 1.145 4.509 2.143 0 0-.783.114-1.767.733-1.217.766-1.999.871-2.992.882-1.647.019-2.147-.532-2.147-.532-.604-.264-.886.454-.886 0 0-1.374 1.702-3.226 3.283-3.226Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <clipPath id="b">
          <path
            d="M179.866 174.42c1.582 0 3.465 1.145 4.509 2.143 0 0-.783.114-1.767.733-1.217.766-1.999.871-2.992.882-1.647.019-2.147-.532-2.147-.532-.604-.264-.886.454-.886 0 0-1.374 1.702-3.226 3.283-3.226Z"
            clip-rule="nonzero"
          />
        </clipPath>
        <g clip-path="url(#b)">
          <path
            d="M181.663 176.417c0 .995-.797 1.8-1.779 1.8a1.789 1.789 0 0 1-1.778-1.8c0-.993.796-1.798 1.778-1.798s1.779.805 1.779 1.798Z"
            class="gray-fill"
          />
        </g>
        <path
          d="M179.866 174.42c1.582 0 3.465 1.145 4.509 2.143 0 0-.783.114-1.767.733-1.217.766-1.999.871-2.992.882-1.647.019-2.147-.532-2.147-.532-.604-.264-.886.454-.886 0 0-1.374 1.702-3.226 3.283-3.226Z"
          style="fill:none;stroke:#7c7c7c;stroke-width:.28px"
        />
        <path
          d="M178.51 174.42c-1.693.657-1.883 2.876-1.883 2.876s.776-2.271 2.895-2.519c3.038-.357 4.132 1.211 4.853 1.786v-.998s-2.226-2.555-5.865-1.145Z"
          class="gray-fill"
        />
        <path
          d="M166.611 174.42c-1.269 0-2.163.25-3.095 1.879 0 0 .596.536 1.013.997.513.57 1.195.777 2.114.836.548.036 1.135.168 1.704-.202.586-.382 1.414.409 1.415-.045.007-2.32-1.635-3.465-3.151-3.465Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <clipPath id="c">
          <path
            d="M166.611 174.42c-1.269 0-2.163.25-3.095 1.879 0 0 .596.536 1.013.997.513.57 1.195.777 2.114.836.548.036 1.135.168 1.704-.202.586-.382 1.414.409 1.415-.045.007-2.32-1.635-3.465-3.151-3.465Z"
            clip-rule="nonzero"
          />
        </clipPath>
        <g clip-path="url(#c)">
          <path
            d="M164.69 176.512c0 .921.711 1.667 1.587 1.667s1.586-.746 1.586-1.667-.71-1.667-1.586-1.667c-.876 0-1.587.746-1.587 1.667Z"
            class="gray-fill"
          />
        </g>
        <path
          d="M166.611 174.42c-1.269 0-2.163.25-3.095 1.879 0 0 .596.536 1.013.997.513.57 1.195.777 2.114.836.548.036 1.135.168 1.704-.202.586-.382 1.414.409 1.415-.045.007-2.32-1.635-3.465-3.151-3.465Z"
          style="fill:none;stroke:#7c7c7c;stroke-width:.25px"
        />
        <path
          d="M168.787 174.655c1.29.676.975 3.017.975 3.017s0-2.555-3.153-2.756c-1.494-.096-2.688.766-3.093 1.383v-.941s2.448-2.182 5.271-.703Z"
          class="gray-fill"
        />
        <path
          d="M165.201 193.872c.828.434.997.426 1.964.106.187.097 2.459.997 4.863-.803.629-.149-.702 1.602-1.336 2.04-.383.265-.366.229-.612.362-.653.346-1.693-.319-2.459-.319-.766-.001-.919.36-1.572.013-.652-.353-.434-.801-.848-1.399Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
        <path
          d="M170.161 173.997s-.092 2.921-1.116 5.124c-.558 1.201-1.104 3.017-3.228 3.288-2.301.294-2.301 3.671-.454 4.146 0 0 1.255.05 2.599-.213 1.345-.262 5.56 1.135 6.961.548 1.401-.588 1.333-2.622.307-3.63-1.028-1.008-.628-4.681-.307-6.961.321-2.278-3.227-8.77-4.762-2.302Z"
          style="fill:#d3d3d3;fill-rule:nonzero"
        />
        <path
          d="M165.305 185.047s-1.67 1.025-.36.969c1.31-.057 5.725-.046 7.462.48 1.196.362 3.036.047 3.164-1.778.127-1.804-2.499-1.997-2.499-1.997s2.518 2.055.683 2.584c-.53.153-.769-.017-2.769-.163-1.101-.081-3.153-1.688-4.828-1.065-.8.298-.853.97-.853.97ZM176.32 186.89c-.784 1.065-2.043 3.43-2.043 3.43s-3.608-.418-5.675-1.922c-.742-.539-1.956.47-1.956.47s-.546-.698-.967-.262c-.547.564-.948.962-1.453 1.177-.441.187-.159-2.471-.07-2.616-.078.127-1.353 2.206-1.345 3.153.008.948 1.07 2.75 1.042 2.847.098.001-.34-2.638-.048-2.296.288.337 1.588.442 1.891.386.976-.179.967-.399 1.823-.154 3.088.883 4.563.127 6.733-.52l2.072 4.269c-.004-3.15-.942-5.464-.004-7.962ZM205.74 178.632s.018-2.755-1.735-2.64c-.751.049-2.582.368-3.359 2.324-.986 2.48 1.327 2.001 1.26 3.642-.053 1.286.28 1.601-.964 3.252 0 0 2.984-1.498 2.993-2.453.031-3.076-2.958-2.332-.864-4.572 1.248-1.336 2.669.447 2.669.447ZM161.864 170.119s1.881-.374 3.422-.17c1.54.204 3.091 1.029 3.688 1.185.597.157 1.28-1.039.305-1.48-.977-.442-2.684-.74-3.874-.806-1.191-.065-3.541 1.271-3.541 1.271ZM185.624 170.081s-3.544-.466-5.296-.25c-1.751.214-3.513 1.142-4.192 1.315-.679.172-1.46-1.208-.351-1.702 1.11-.494 3.051-.817 4.406-.877 1.354-.062 5.433 1.514 5.433 1.514Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
        <path
          d="m167.807 185.846-.804 2.087 2.046-2.087h-1.242Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
        <path
          d="M214.603 156.614c-.402-1.846.275-3.354-.476-4.829-.735-1.447-2.891-2.868-3.896-3.995-1.309-1.469-2.12-3.589-3.758-4.589-1.933-1.181-4.743-1.159-6.916-1.808-1.958-.586-3.455-1.915-5.426-2.17-2.575-.334-10.536.024-13.075.024-3.117.358-3.92 1.312-6.133 2.211-1.384.562-4.406 1.095-5.443 1.743-1.869 1.165-3.093 3.119-3.864 4.121-2.281 2.972-3.374 8.511-3.374 8.511s2.525-5.29 4.22-5.548c1.695-.26 3.225 1.176 6.664 1.176 3.44 0 9.263-1.985 9.263-1.985 7.583-.545 15.767 3.48 19.184 10.272.084.169.168.34.251.515 2.502 5.264 2.324 5.362-2.084 14.492-.937 1.943.858 1.493.858 1.493s1.268-1.22 3.016-1.505c3.016-.493 6.326 4.83 1.744 9.412 0 0 3.685-1.916 6.386-6.859.784-1.432 2.145-2.831 2.732-4.783.468-1.551.449-3.541.449-5.46 0-1.76.064-8.666-.322-10.439Z"
          style="fill:#154262;fill-rule:nonzero"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M130.07 442.314h114.406v6.803H130.07z"
        />
        <path
          class="gray-stroke"
          d="M130.07 442.314h114.406v6.803H130.07z"
        />
        <path
          d="M207.636 442.314v6.816"
          class="no-fill"
        />
        <path
          d="M207.636 442.314v6.816"
          class="gray-stroke"
        />
        <path
          d="M253.362 419.129h-8.042l-29.462 29.463h7.591"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M253.362 419.129h-8.042l-29.462 29.463h7.591"
          class="gray-stroke"
        />
        <path
          d="m253.362 419.129-29.913 29.912h29.968l-.055-29.912Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m253.362 419.129-29.913 29.912h29.968l-.055-29.912Z"
          class="gray-stroke"
        />
        <path
          d="M189.137 414.612s4.882 1.525 7.729 1.627c2.848.101 5.768-1.209 4.948 1.591-.82 2.81-9.4 1.461-13 .78 1.95 1.431 8.896 2.621 10.33 2.54 1.434-.081 2.705 2.004-.04 2.563-3.875.789-8.39-.373-10.34-1.093l-.04.11c.11.11 1.09 1.05 3.71 2.21 2.75 1.22 3.334.978 4.011 1.63.675.652.269 2.14-1.261 1.94-5.8-.77-6.37-2.36-10.17-2.969-3.08-.491-12.33-2.981-15.81-8.651-2.39-3.89-43.04-11.09-49.42-21.55l-23.91-16.04-.24-.05c4.03-18.66 14.91-20.52 14.91-20.52l-.59-1.1c.41.49 13.63 16.1 19.35 21.05 0 0 11.4 7.361 16.82 11.62 5.42 4.27 20.13 12.28 26.821 13.87 0 0 5.699-2.27 10.799-2.27s9.32-.73 8.69 1.5c-.64 2.23-6.578.349-8.34 3.01-.815 1.23-1.069 2.46 1.601 3.5 3.689 1.45 9.222 2.392 13.052 1.753 3.821-.641 3.339 2.151.789 2.792-1.59.399-6.999.096-10.399.157Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M189.137 414.612s4.882 1.525 7.729 1.627c2.848.101 5.768-1.209 4.948 1.591-.82 2.81-9.4 1.461-13 .78 1.95 1.431 8.896 2.621 10.33 2.54 1.434-.081 2.705 2.004-.04 2.563-3.875.789-8.39-.373-10.34-1.093l-.04.11c.11.11 1.09 1.05 3.71 2.21 2.75 1.22 3.334.978 4.011 1.63.675.652.269 2.14-1.261 1.94-5.8-.77-6.37-2.36-10.17-2.969-3.08-.491-12.33-2.981-15.81-8.651-2.39-3.89-43.04-11.09-49.42-21.55l-23.91-16.04-.24-.05c4.03-18.66 14.91-20.52 14.91-20.52l-.59-1.1c.41.49 13.63 16.1 19.35 21.05 0 0 11.4 7.361 16.82 11.62 5.42 4.27 20.13 12.28 26.821 13.87 0 0 5.699-2.27 10.799-2.27s9.32-.73 8.69 1.5c-.64 2.23-6.578.349-8.34 3.01-.815 1.23-1.069 2.46 1.601 3.5 3.689 1.45 9.222 2.392 13.052 1.753 3.821-.641 3.339 2.151.789 2.792-1.59.399-6.999.096-10.399.157Z"
          class="gray-stroke"
        />
        <path
          d="m109.954 357.63-.009-.01"
          class="no-fill"
        />
        <path
          d="m109.954 357.63-.009-.01"
          style="fill:none;stroke:#d3d3d3;stroke-width:1px"
        />
        <path
          d="M198.994 359.45h-.009"
          style="fill:none;stroke:#e0e6eb;stroke-width:1px"
        />
        <path
          d="M195.267 358.237c.329-.007.649.042.974.068a13.412 13.412 0 0 1 1.903.404l.468.141c.152.066.336-.024.379.563h-.006l.009.037-.009.038h-.009c-.273.526-.405.377-.57.382l-.482-.039a17.93 17.93 0 0 1-.955-.124c-.315-.053-.63-.115-.941-.192-.309-.094-.623-.165-.926-.292l.165-.986Z"
          style="fill:#e3e9ee;fill-rule:nonzero"
        />
        <path
          d="M210.484 369.48c-.89 1.16-1.879.61-4.72-.05-4.1-.94-9.239-1.51-9.239-1.51v.01c3.399 1.16 12.069 4.28 11.939 5.75-.16 1.83-3.12 1.37-6.24.3-13.15-4.5-14.05-.91-14.05-.91s2.24 1.52 5.08 3.2c3.87 2.29.87 4.43-1.42 3.03-2.35-1.43-5.99-3.84-9-6.08-5.04-3.74-7.479-12.98-7.479-12.98s-20.441-9.24-27.761-16.36c-6.319-2.37-14.739-6.46-15.22-6.7 11.62-6.35 13.85-15.07 13.85-15.07l6.95 6.52c6.62 3.46 18.34 15.24 26.61 24 0 0 8.79 1.02 15.4 3.661 5.7 0 7.16-.351 11.27.539 2.66.57-.28 1.9-.28 1.9l-7.18.72c4.63.95 11.8 2.66 11.48 4.16-.36 1.66-5.119.81-8.25 0-4.07-1.05-5.699-.3-5.699-.3s15.5 4.16 13.959 6.17Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M210.484 369.48c-.89 1.16-1.879.61-4.72-.05-4.1-.94-9.239-1.51-9.239-1.51v.01c3.399 1.16 12.069 4.28 11.939 5.75-.16 1.83-3.12 1.37-6.24.3-13.15-4.5-14.05-.91-14.05-.91s2.24 1.52 5.08 3.2c3.87 2.29.87 4.43-1.42 3.03-2.35-1.43-5.99-3.84-9-6.08-5.04-3.74-7.479-12.98-7.479-12.98s-20.441-9.24-27.761-16.36c-6.319-2.37-14.739-6.46-15.22-6.7 11.62-6.35 13.85-15.07 13.85-15.07l6.95 6.52c6.62 3.46 18.34 15.24 26.61 24 0 0 8.79 1.02 15.4 3.661 5.7 0 7.16-.351 11.27.539 2.66.57-.28 1.9-.28 1.9l-7.18.72c4.63.95 11.8 2.66 11.48 4.16-.36 1.66-5.119.81-8.25 0-4.07-1.05-5.699-.3-5.699-.3s15.5 4.16 13.959 6.17Z"
          class="gray-stroke"
        />
        <path
          d="M196.525 367.93c-.83-.28-1.341-.45-1.341-.45"
          style="fill:none;stroke:#e3e9ee;stroke-width:1px"
        />
        <path
          d="M192.71 121.75s-7.223 1.539-10.274 2.454c-3.051.916-10.701 2.958-10.69 5.186.012 2.23-.752 2.28 2.638 2.622 3.389.343 29.617 0 32.703 0 3.086 0 6.134.933 6.134.933 6.209 1.417 16.192 7.045 33.487 20.828l19.016-16.45c-10.323-3.87-29.808-8.635-55.79-14.034 0 0-10.253-3.079-17.224-1.539Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M192.71 121.75s-7.223 1.539-10.274 2.454c-3.051.916-10.701 2.958-10.69 5.186.012 2.23-.752 2.28 2.638 2.622 3.389.343 29.617 0 32.703 0 3.086 0 6.134.933 6.134.933 6.209 1.417 16.192 7.045 33.487 20.828l19.016-16.45c-10.323-3.87-29.808-8.635-55.79-14.034 0 0-10.253-3.079-17.224-1.539Z"
          class="gray-stroke"
        />
        <path
          d="M244.476 262.351s10.102 39.905 9.492 46.328c-.61 6.423-1.073 24.119 1.649 26.864 2.724 2.747 16.317 8.662 37.978 1.848 21.661-6.813 40.525-17.548 42.355-20.892 1.831-3.345-12.188-61.802-14.019-71.26-1.83-9.457-1.354-16.169-4.404-21.966-3.051-5.797-10.198-24.014-17.76-31.685l-10.308 15.744 1.526 3.331s-3.966 5.966-8.542 7.458c-4.577 1.491-17.289 0-17.289 0l-13.525 6.677-23.492-.667s-3.814 2.497-5.085 15.419c-.959 9.748 4.17 19.113 8.441 20.944 4.272 1.83 30.944 3.05 40.489 0"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M244.476 262.351s10.102 39.905 9.492 46.328c-.61 6.423-1.073 24.119 1.649 26.864 2.724 2.747 16.317 8.662 37.978 1.848 21.661-6.813 40.525-17.548 42.355-20.892 1.831-3.345-12.188-61.802-14.019-71.26-1.83-9.457-1.354-16.169-4.404-21.966-3.051-5.797-10.198-24.014-17.76-31.685l-10.308 15.744 1.526 3.331s-3.966 5.966-8.542 7.458c-4.577 1.491-17.289 0-17.289 0l-13.525 6.677-23.492-.667s-3.814 2.497-5.085 15.419c-.959 9.748 4.17 19.113 8.441 20.944 4.272 1.83 30.944 3.05 40.489 0"
          class="gray-stroke"
        />
        <path
          d="M279.101 415.356s5.985-3.338 3.233-11.837"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M279.101 415.356s5.985-3.338 3.233-11.837"
          class="gray-stroke"
        />
        <path
          d="m279.101 409.696 28.226-53.917"
          class="no-fill"
        />
        <path
          d="M279.101 409.696c1.807-4.779 4.279-9.209 6.584-13.727l6.986-13.516 7.064-13.476 7.15-13.431.885.464-6.963 13.529-7.05 13.482-7.128 13.442c-2.399 4.469-4.632 9.025-7.528 13.233Z"
          class="gray-fill"
        />
        <path
          d="M295.756 361.647s33.095-12.984 35.129-16.747c2.034-3.763 5.065-28.401 5.065-28.401"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M295.756 361.647c2.481-1.427 5.073-2.56 7.683-3.655a603.618 603.618 0 0 0 7.797-3.355c5.178-2.262 10.38-4.525 15.318-7.212 1.202-.69 2.467-1.393 3.433-2.263.108-.109.252-.218.31-.324.08-.099.165-.2.219-.37.115-.31.273-.576.36-.922a24.09 24.09 0 0 0 .557-2.007c.653-2.728 1.141-5.517 1.611-8.302.924-5.577 1.699-11.189 2.41-16.799l.992.122c-.712 5.627-1.489 11.24-2.417 16.841-.472 2.799-.959 5.594-1.626 8.376-.158.694-.355 1.39-.571 2.086-.083.345-.271.706-.406 1.056-.054.174-.179.358-.319.539-.131.198-.276.303-.416.446-1.14 1.033-2.382 1.71-3.621 2.44-4.996 2.771-10.184 5.066-15.382 7.337a443.489 443.489 0 0 1-7.86 3.253c-2.628 1.064-5.284 2.06-8.072 2.713Z"
          class="gray-fill"
        />
        <path
          d="m303.386 363.307 3.941 40.212.91 11.132"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m303.386 363.307 3.941 40.212.91 11.132"
          class="gray-stroke"
        />
        <path
          d="M248.792 144.49s-4.316 16.546-4.316 20.614c0 10.205-6.576 11.441-5.356 14.263 1.221 2.821 3.147 3.354 3.147 3.354s-1.113 12.131 6.64 16.962c2.91 1.813 6.564 3.926 10.121 4.349 2.777.329 4.87 6.54 5.329 9.298l.797 4.791s11.194 2.235 17.289 0c7.292-2.676 8.542-7.458 8.542-7.458l.04-7.54s12.412-16.853 12.818-28.128c.407-11.275 4.394-22.095-5.333-33.637-7.11-8.438-43.932-12.48-49.718 3.132Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M248.792 144.49s-4.316 16.546-4.316 20.614c0 10.205-6.576 11.441-5.356 14.263 1.221 2.821 3.147 3.354 3.147 3.354s-1.113 12.131 6.64 16.962c2.91 1.813 6.564 3.926 10.121 4.349 2.777.329 4.87 6.54 5.329 9.298l.797 4.791s11.194 2.235 17.289 0c7.292-2.676 8.542-7.458 8.542-7.458l.04-7.54s12.412-16.853 12.818-28.128c.407-11.275 4.394-22.095-5.333-33.637-7.11-8.438-43.932-12.48-49.718 3.132Z"
          class="gray-stroke"
        />
        <path
          d="M255.722 173.233s2.516 4.087-.329 10.712"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M255.722 173.233c.628.716 1.003 1.627 1.239 2.55.241.928.299 1.895.26 2.85-.074.954-.246 1.898-.56 2.793a9.784 9.784 0 0 1-1.268 2.519c.062-.474.175-.92.264-1.37.073-.454.182-.895.248-1.342.148-.89.265-1.774.319-2.659.096-1.773-.005-3.537-.502-5.341Z"
          class="gray-fill"
        />
        <path
          d="M262.086 182.001s-2.016 7.593-8.118 5.53"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M262.57 182.13c-.322.95-.75 1.792-1.271 2.608-.524.805-1.157 1.551-1.923 2.143a5.383 5.383 0 0 1-2.646 1.059c-.955.142-1.887-.133-2.762-.409.901.198 1.832.38 2.725.162a5.027 5.027 0 0 0 2.365-1.196c.654-.611 1.176-1.349 1.595-2.14.413-.786.75-1.65.95-2.483l.967.256Z"
          class="gray-fill"
        />
        <path
          d="M192.71 126.339s-7.418.441-9.869 2.848c-2.45 2.407 0 2.958 0 2.958h13.699"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M192.71 126.339a59.188 59.188 0 0 0-3.502.685 28.622 28.622 0 0 0-3.377.992c-1.079.412-2.132.92-2.824 1.73-.372.393-.69.873-.727 1.223a.44.44 0 0 0 .153.377c.116.112.354.229.527.269l-.119-.014a332.77 332.77 0 0 1 6.85-.036c2.283-.003 4.566.025 6.849.08v1c-2.283.056-4.566.084-6.849.081-2.284.015-4.567-.004-6.85-.037l-.075-.001-.044-.012a2.327 2.327 0 0 1-1.033-.564c-.318-.286-.508-.806-.452-1.237.137-.846.608-1.35 1.035-1.817.925-.975 2.149-1.435 3.296-1.809 1.166-.374 2.363-.567 3.554-.718a25.211 25.211 0 0 1 3.588-.192Z"
          class="gray-fill"
        />
        <path
          d="m209.12 132.467-19.762.249c-6.588.076-13.175.102-19.763.11l-39.525.101.459-.46.041 6.803h-1l.04-6.803.46-.46 39.525.102c6.588.008 13.175.033 19.763.109l19.762.249Z"
          style="fill:#231f20;fill-rule:nonzero"
        />
        <path
          d="M291.459 207.332s12.89 1.105 15.868 4.862"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M291.502 206.833c2.824.358 5.601.884 8.338 1.617a30.91 30.91 0 0 1 4.015 1.376c1.286.56 2.582 1.261 3.472 2.368-.954-1.049-2.263-1.657-3.566-2.136a30.351 30.351 0 0 0-4.038-1.122 61.906 61.906 0 0 0-8.307-1.106l.086-.997Z"
          class="gray-fill"
        />
        <path
          d="M255.617 335.543s.182 18.93-.224 22.077c-.407 3.147-22.222 65.03-22.222 74.519 0 9.489-.468 17.171 7.576 16.978 8.045-.194 104.542 0 104.542 0s12.204-2.523 13.018-12.285c.813-9.763-10.984-25.21-18.712-23.181-7.73 2.028-54.393.567-60.494 1.705-6.103 1.137-6.103 2.334-14.238 5.804"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M255.617 335.543s.182 18.93-.224 22.077c-.407 3.147-22.222 65.03-22.222 74.519 0 9.489-.468 17.171 7.576 16.978 8.045-.194 104.542 0 104.542 0s12.204-2.523 13.018-12.285c.813-9.763-10.984-25.21-18.712-23.181-7.73 2.028-54.393.567-60.494 1.705-6.103 1.137-6.103 2.334-14.238 5.804"
          class="gray-stroke"
        />
        <path
          d="M337.469 414.651c-4.598 6.699-7.025 8.411-6.518 15.703.508 7.291 24.882 18.44 36.899 18.44 12.015 0 23.406-.95 23.406-11.134 0-10.184-24.223-11.096-32.909-18.531-8.688-7.434-16.28-11.178-20.878-4.478Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M337.469 414.651c-4.598 6.699-7.025 8.411-6.518 15.703.508 7.291 24.882 18.44 36.899 18.44 12.015 0 23.406-.95 23.406-11.134 0-10.184-24.223-11.096-32.909-18.531-8.688-7.434-16.28-11.178-20.878-4.478Z"
          class="gray-stroke"
        />
        <path
          d="M270.443 128.268s-8.357.975-14.373 5.921c-5.492 4.517-7.278 10.301-7.278 10.301s4.566 7.19 3.752 14.919c-.813 7.728 0 12.61 0 12.61s0-3.255 5.085-2.848c5.084.408 4.678 10.17 3.457 13.83 10.896 12.556 19.846 20.061 19.846 20.061l10.093.061 8.742-11.535s20.563-15.383 10.56-47.426c-5.563-17.823-39.884-15.894-39.884-15.894Z"
          class="gray-fill"
        />
        <path
          d="M244.476 165.104s3.189 8.129.749 13.017"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M244.942 164.921c.367 1.095.636 2.179.866 3.287.221 1.105.397 2.22.469 3.345.154 2.231-.015 4.572-1.052 6.568.881-2.067.866-4.35.554-6.514-.151-1.09-.405-2.164-.7-3.223a27.43 27.43 0 0 0-1.068-3.097l.931-.366Z"
          class="gray-fill"
        />
        <g v-if="showArrows">
          <path
            d="M199.949 66.814V38.858h-13.38v27.956h-6.224l12.914 11.184 12.914-11.184h-6.224ZM235.615 423.579h27.957V410.2h-27.957v-6.224l-11.183 12.914 11.183 12.914v-6.225ZM235.615 375.181h27.957v-13.38h-27.957v-6.223l-11.183 12.913 11.183 12.914v-6.224Z"
            class="orange-arrow"
          />
        </g>
        <path
          d="M168.027 201.027c1.106.865 3.471.878 7.915.426 2.256-.229 9.677-4.828 9.56-7.445-.204-4.529-.52-8.907 3.451-8.907 2.981 0-1.38 3.899 3.898 10.324 2.484 3.024 1.422 8.079 1.422 8.079l13.363 20.454 20.501 1.015c-7.321 5.379-4.684 23.379-4.684 23.379s-22.202-3.624-26.364-8.559c-4.162-4.935-10.777-29.066-10.777-29.066s-12.812-3.109-17.263-4.613c-4.45-1.504-6.049-4.691-6.807-7.095-.756-2.405-1.231-5.853-1.231-5.853s.525 2.624 3.485 4.9c2.531 1.945.3.47 3.531 2.961Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M168.027 201.027c1.106.865 3.471.878 7.915.426 2.256-.229 9.677-4.828 9.56-7.445-.204-4.529-.52-8.907 3.451-8.907 2.981 0-1.38 3.899 3.898 10.324 2.484 3.024 1.422 8.079 1.422 8.079l13.363 20.454 20.501 1.015c-7.321 5.379-4.684 23.379-4.684 23.379s-22.202-3.624-26.364-8.559c-4.162-4.935-10.777-29.066-10.777-29.066s-12.812-3.109-17.263-4.613c-4.45-1.504-6.049-4.691-6.807-7.095-.756-2.405-1.231-5.853-1.231-5.853s.525 2.624 3.485 4.9c2.531 1.945.3.47 3.531 2.961Z"
          style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linejoin:round"
        />
      </g>
      <g v-else-if="race === 'b'">
        <path
          d="M76.815 275s-4.425 15.034-7.297 15.823c-2.873.789-7.284 8.607 0 15.891 7.284 7.284 19.394-3.952 19.394-3.952S107.731 270.185 76.815 275Z"
          class="gray-fill"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M170.388 90.883h78.404v358.04h-78.404z"
        />
        <path
          class="gray-stroke"
          d="M170.388 90.883h78.404v358.04h-78.404z"
        />
        <path
          d="M244.476 449.116V90.883"
          class="no-fill"
        />
        <path
          d="M244.476 449.116V90.883M156.877 412.105s5.769 4.937 7.803 17.14c2.034 12.204-16.678 19.384-31.322 19.384-14.644 0-60.611-1.221-69.967 0-9.355 1.219-8.949-4.531-2.034-9.181 6.916-4.65.814-12.786 7.093-17.42"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M129.802 132.467h114.406v6.803H129.802z"
        />
        <path
          class="gray-stroke"
          d="M129.802 132.467h114.406v6.803H129.802z"
        />
        <path
          d="M244.166 109.675h-6.127l-22.686 22.687h5.784"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M244.166 109.675h-6.127l-22.686 22.687h5.784"
          class="gray-stroke"
        />
        <path
          d="m244.166 109.675-22.791 22.791h22.833l-.042-22.791Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m244.166 109.675-22.791 22.791h22.833l-.042-22.791Z"
          class="gray-stroke"
        />
        <path
          d="M176.868 109.675h-6.127l-22.449 22.449h5.785"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M176.868 109.675h-6.127l-22.449 22.449h5.785"
          class="gray-stroke"
        />
        <path
          d="m176.868 109.675-22.791 22.791h22.833l-.042-22.791Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m176.868 109.675-22.791 22.791h22.833l-.042-22.791Z"
          class="gray-stroke"
        />
        <path
          d="M207.636 132.46v6.817"
          class="no-fill"
        />
        <path
          d="M207.636 132.46v6.817"
          class="gray-stroke"
        />
        <path
          d="M179.37 419.129h-7.921l-29.02 29.021h7.478"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M179.37 419.129h-7.921l-29.02 29.021h7.478"
          class="gray-stroke"
        />
        <path
          d="m179.37 419.129-29.463 29.463h29.518l-.055-29.463Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m179.37 419.129-29.463 29.463h29.518l-.055-29.463ZM132.374 337.18c-.759.41-1.561.82-2.41 1.21M146.224 322.11l-13.76-14.33"
          class="gray-stroke"
        />
        <path
          d="M56.791 363.612c-2.553-16.271 0-44.916 26.229-57.629"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M56.791 363.612c-.83-2.74-1.164-5.597-1.257-8.444-.08-2.852.009-5.709.277-8.551.538-5.681 1.793-11.321 3.94-16.641 2.163-5.304 5.246-10.259 9.177-14.45 3.917-4.215 8.74-7.498 13.875-9.993l.436.9c-5.009 2.513-9.644 5.802-13.422 9.926-3.816 4.082-6.81 8.905-8.915 14.088-2.138 5.174-3.429 10.692-4.009 16.276a68.984 68.984 0 0 0-.351 8.419c.06 2.814.222 5.611.249 8.47Z"
          class="gray-fill"
        />
        <path
          d="M56.258 350.998s-7.51 9.156-1.409 17.8c6.102 8.644 21.356 25.452 50.285 16.715"
          class="gray-stroke"
        />
        <path
          d="M52.443 360.764s-9.933 16.068-9.933 25.22c0 9.152 1.46 18.306 8.933 25.221 7.474 6.915 11.948 10.169 25.372 13.016 9.562 2.029 24.826.032 36.934 1.801 4.89.715 9.266 2.045 12.542 4.502"
          class="gray-stroke"
        />
        <path
          d="M56.791 444.06s-15.138 5.788-23.094 4.735c-7.957-1.055-11.797 1.053-8.543-6.166 3.254-7.221 16.499-5.797 18.305-15.967 1.806-10.169 13.332-11.307 13.332-11.307M97.964 335.54l11.981 22.08.009.01M95.634 379.25c-.19.85-.36 1.74-.51 2.67l-23.191-18.31"
          class="gray-stroke"
        />
        <path
          d="M133.743 277.212c-.32 1.305.776-5.958-8.72-6.166-12.818-7.705-17.95 3.527-23.913 8.648-5.965 5.122-7.76 11.997-9.141 19.736l-.222-.473-2.351-1.86c-1.235-.977-2.498-1.97-3.972-2.523-1.476-.554-3.226-.607-4.55.248-1.623 1.046-2.221 3.239-1.837 5.132.384 1.892 1.57 3.522 2.887 4.936 1.304 1.402 2.802 2.679 4.584 3.385 1.78.706 4.039.434 5.713-.495 2.136 4.908 11.619 9.376 17.328 10.485s10.755 1.837 15.301-1.789c6.848-5.463 12.373-13.956 10.974-22.604-1.006-6.224-4.194-6.959-2.081-16.66Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M133.743 277.212c-.32 1.305.776-5.958-8.72-6.166-12.818-7.705-17.95 3.527-23.913 8.648-5.965 5.122-7.76 11.997-9.141 19.736l-.222-.473-2.351-1.86c-1.235-.977-2.498-1.97-3.972-2.523-1.476-.554-3.226-.607-4.55.248-1.623 1.046-2.221 3.239-1.837 5.132.384 1.892 1.57 3.522 2.887 4.936 1.304 1.402 2.802 2.679 4.584 3.385 1.78.706 4.039.434 5.713-.495 2.136 4.908 11.619 9.376 17.328 10.485s10.755 1.837 15.301-1.789c6.848-5.463 12.373-13.956 10.974-22.604-1.006-6.224-4.194-6.959-2.081-16.66Z"
          class="gray-stroke"
        />
        <path
          d="M80.593 295.981c-6.12-3.509-8.604-12.171-7.47-22.154 1.224-10.779 14.468-25.12 25.813-25.866 11.609-.763 20.156 1.302 27.437 10.035 3.423 4.105 8.643 14.024 7.37 19.216-2.481-6.166-7.13-5.589-10.962-4.922-3.667.638-6.118-2.387-6.118-2.387s-.731 5.436-2.628 6.149c-20.266 7.627-21.559 26.223-21.559 26.223s-5.085-11.202-11.883-6.294Z"
          class="gray-fill"
        />
        <path
          d="M78.849 308.678s-.199 1.913 4.171 7.678c4.371 5.764 27.525 21.429 32.439 19.188 0 0 4.048-10.357 3.921-16.594"
          class="gray-stroke"
        />
        <path
          d="M122.781 308.678s9.683 1.849 6.304-5.921c-1.877-4.318-3.863-7.078-3.863-7.078"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M122.781 308.678c1.646.064 3.339-.007 4.775-.563.71-.286 1.289-.753 1.55-1.378.263-.626.252-1.385.069-2.13-.158-.757-.446-1.48-.78-2.238-.315-.756-.657-1.499-.994-2.247-.699-1.485-1.4-2.974-2.179-4.443 1.101 1.251 2.046 2.637 2.871 4.084.8 1.467 1.631 2.896 2.06 4.633.184.856.229 1.833-.188 2.735-.437.89-1.297 1.483-2.146 1.715-1.717.501-3.441.269-5.038-.168Z"
          class="gray-fill"
        />
        <path
          d="M110.545 302.611s5.321-5.711 12.236-6.932"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M110.545 302.611c.683-.999 1.527-1.873 2.43-2.672a19.753 19.753 0 0 1 2.932-2.128 16.818 16.818 0 0 1 3.309-1.505 12.146 12.146 0 0 1 3.565-.627c-1.126.444-2.223.84-3.278 1.353-1.051.511-2.093 1.029-3.088 1.641-2.017 1.177-3.925 2.527-5.87 3.938Z"
          class="gray-fill"
        />
        <path
          d="M128.477 293.872s1.915-3.495 6.042-2.674"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M128.477 293.872c.127-.614.452-1.167.862-1.64a4.537 4.537 0 0 1 1.523-1.131c.586-.257 1.227-.416 1.865-.383a3.722 3.722 0 0 1 1.792.48c-.619.112-1.181.158-1.718.298a7.146 7.146 0 0 0-1.525.514c-.984.43-1.853 1.081-2.799 1.862Z"
          class="gray-fill"
        />
        <path
          d="M126.373 324.849s7.929 26.559 3.592 33.881c-4.336 7.322-11.527 9.761-11.527 9.761"
          class="gray-stroke"
        />
        <path
          d="M89.054 306.64c-1.728.367-1.422-.657-3.239-2.52.489.608 2.929-.596 3.097-1.358.169-.761.267-1.524-.198-2.15-1.538-2.075-3.595-2.849-4.93-2.898-1.035.365-2.545 1.431-.667 4.138"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M89.054 306.64c-.344.133-.731.252-1.151.122-.424-.136-.702-.475-.94-.753l-.71-.828c-.235-.269-.498-.508-.748-.758l.648-.575c-.072-.046.102.025.291-.024.191-.032.401-.103.604-.187.404-.172.81-.407 1.125-.673.153-.125.279-.301.265-.323a5.51 5.51 0 0 0 .105-.668c.035-.429-.014-.787-.219-1.07-.556-.738-1.21-1.411-1.991-1.907-.775-.494-1.656-.867-2.562-.95l.123-.019c-.736.21-1.402.714-1.472 1.497-.083.78.3 1.591.695 2.328-.53-.65-1.037-1.405-1.079-2.344a1.87 1.87 0 0 1 .478-1.32c.318-.368.732-.61 1.157-.788l.048-.02.076.002c2.137.047 4.063 1.289 5.309 2.943.394.513.494 1.198.426 1.739a6.204 6.204 0 0 1-.14.786c-.128.467-.383.652-.591.848-.44.366-.909.61-1.416.803a3.836 3.836 0 0 1-.814.211c-.279.009-.676.109-1.094-.32l.649-.574c.51.571.945 1.223 1.298 1.868.19.308.337.628.604.789.268.17.656.173 1.026.165Z"
          class="gray-fill"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 140.528H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 140.528H237v24.765h-7.658z"
        />
        <path
          d="M237 140.528v24.765"
          class="no-fill"
        />
        <path
          d="M237 140.528v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 165.293v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 165.293v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 143.56h-1.752"
          class="no-fill"
        />
        <path
          d="M237 143.56h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 149.799h-1.752"
          class="no-fill"
        />
        <path
          d="M237 149.799h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 155.902h-1.752"
          class="no-fill"
        />
        <path
          d="M237 155.902h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 165.293h-4.429"
          class="no-fill"
        />
        <path
          d="M237 165.293h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 152.851h-3.528"
          class="no-fill"
        />
        <path
          d="M237 152.851h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 146.68h-3.528"
          class="no-fill"
        />
        <path
          d="M237 146.68h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 158.942h-3.528"
          class="no-fill"
        />
        <path
          d="M237 158.942h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 161.982h-1.752"
          class="no-fill"
        />
        <path
          d="M237 161.982h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 165.633H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 165.633H237v24.766h-7.658z"
        />
        <path
          d="M237 165.633v24.766"
          class="no-fill"
        />
        <path
          d="M237 165.633v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 190.399v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 190.399v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 168.666h-1.752"
          class="no-fill"
        />
        <path
          d="M237 168.666h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 174.905h-1.752"
          class="no-fill"
        />
        <path
          d="M237 174.905h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 181.008h-1.752"
          class="no-fill"
        />
        <path
          d="M237 181.008h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 190.399h-4.429"
          class="no-fill"
        />
        <path
          d="M237 190.399h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 177.956h-3.528"
          class="no-fill"
        />
        <path
          d="M237 177.956h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 171.785h-3.528"
          class="no-fill"
        />
        <path
          d="M237 171.785h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 184.048h-3.528"
          class="no-fill"
        />
        <path
          d="M237 184.048h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 187.088h-1.752"
          class="no-fill"
        />
        <path
          d="M237 187.088h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 190.739H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 190.739H237v24.766h-7.658z"
        />
        <path
          d="M237 190.739v24.766"
          class="no-fill"
        />
        <path
          d="M237 190.739v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 215.505v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 215.505v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 193.772h-1.752"
          class="no-fill"
        />
        <path
          d="M237 193.772h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 200.011h-1.752"
          class="no-fill"
        />
        <path
          d="M237 200.011h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 206.114h-1.752"
          class="no-fill"
        />
        <path
          d="M237 206.114h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 215.505h-4.429"
          class="no-fill"
        />
        <path
          d="M237 215.505h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 203.062h-3.528"
          class="no-fill"
        />
        <path
          d="M237 203.062h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 196.891h-3.528"
          class="no-fill"
        />
        <path
          d="M237 196.891h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 209.154h-3.528"
          class="no-fill"
        />
        <path
          d="M237 209.154h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 212.193h-1.752"
          class="no-fill"
        />
        <path
          d="M237 212.193h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 215.845H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 215.845H237v24.766h-7.658z"
        />
        <path
          d="M237 215.845v24.766"
          class="no-fill"
        />
        <path
          d="M237 215.845v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 240.611v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 240.611v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 218.878h-1.752"
          class="no-fill"
        />
        <path
          d="M237 218.878h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 225.117h-1.752"
          class="no-fill"
        />
        <path
          d="M237 225.117h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 231.22h-1.752"
          class="no-fill"
        />
        <path
          d="M237 231.22h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 240.611h-4.429"
          class="no-fill"
        />
        <path
          d="M237 240.611h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 228.168h-3.528"
          class="no-fill"
        />
        <path
          d="M237 228.168h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 221.997h-3.528"
          class="no-fill"
        />
        <path
          d="M237 221.997h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 234.26h-3.528"
          class="no-fill"
        />
        <path
          d="M237 234.26h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 237.299h-1.752"
          class="no-fill"
        />
        <path
          d="M237 237.299h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 240.952H237v24.764h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 240.952H237v24.764h-7.658z"
        />
        <path
          d="M237 240.952v24.764"
          class="no-fill"
        />
        <path
          d="M237 240.952v24.764"
          class="gray-stroke"
        />
        <path
          d="M229.342 265.716v-24.764"
          class="no-fill"
        />
        <path
          d="M229.342 265.716v-24.764"
          class="gray-stroke"
        />
        <path
          d="M237 243.984h-1.752"
          class="no-fill"
        />
        <path
          d="M237 243.984h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 250.222h-1.752"
          class="no-fill"
        />
        <path
          d="M237 250.222h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 256.326h-1.752"
          class="no-fill"
        />
        <path
          d="M237 256.326h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 265.716h-4.429"
          class="no-fill"
        />
        <path
          d="M237 265.716h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 253.274h-3.528"
          class="no-fill"
        />
        <path
          d="M237 253.274h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 247.103h-3.528"
          class="no-fill"
        />
        <path
          d="M237 247.103h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 259.366h-3.528"
          class="no-fill"
        />
        <path
          d="M237 259.366h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 262.406h-1.752"
          class="no-fill"
        />
        <path
          d="M237 262.406h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 266.057H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 266.057H237v24.766h-7.658z"
        />
        <path
          d="M237 266.057v24.766"
          class="no-fill"
        />
        <path
          d="M237 266.057v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 290.823v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 290.823v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 269.089h-1.752"
          class="no-fill"
        />
        <path
          d="M237 269.089h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 275.329h-1.752"
          class="no-fill"
        />
        <path
          d="M237 275.329h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 281.431h-1.752"
          class="no-fill"
        />
        <path
          d="M237 281.431h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 290.823h-4.429"
          class="no-fill"
        />
        <path
          d="M237 290.823h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 278.38h-3.528"
          class="no-fill"
        />
        <path
          d="M237 278.38h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 272.21h-3.528"
          class="no-fill"
        />
        <path
          d="M237 272.21h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 284.471h-3.528"
          class="no-fill"
        />
        <path
          d="M237 284.471h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 287.511h-1.752"
          class="no-fill"
        />
        <path
          d="M237 287.511h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 291.163H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 291.163H237v24.765h-7.658z"
        />
        <path
          d="M237 291.163v24.765"
          class="no-fill"
        />
        <path
          d="M237 291.163v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 315.928v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 315.928v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 294.196h-1.752"
          class="no-fill"
        />
        <path
          d="M237 294.196h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 300.434h-1.752"
          class="no-fill"
        />
        <path
          d="M237 300.434h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 306.538h-1.752"
          class="no-fill"
        />
        <path
          d="M237 306.538h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 315.928h-4.429"
          class="no-fill"
        />
        <path
          d="M237 315.928h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 303.486h-3.528"
          class="no-fill"
        />
        <path
          d="M237 303.486h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 297.315h-3.528"
          class="no-fill"
        />
        <path
          d="M237 297.315h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 309.578h-3.528"
          class="no-fill"
        />
        <path
          d="M237 309.578h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 312.617h-1.752"
          class="no-fill"
        />
        <path
          d="M237 312.617h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 316.269H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 316.269H237v24.766h-7.658z"
        />
        <path
          d="M237 316.269v24.766"
          class="no-fill"
        />
        <path
          d="M237 316.269v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 341.035v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 341.035v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 319.301h-1.752"
          class="no-fill"
        />
        <path
          d="M237 319.301h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 325.541h-1.752"
          class="no-fill"
        />
        <path
          d="M237 325.541h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 331.643h-1.752"
          class="no-fill"
        />
        <path
          d="M237 331.643h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 341.035h-4.429"
          class="no-fill"
        />
        <path
          d="M237 341.035h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 328.591h-3.528"
          class="no-fill"
        />
        <path
          d="M237 328.591h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 322.421h-3.528"
          class="no-fill"
        />
        <path
          d="M237 322.421h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 334.683h-3.528"
          class="no-fill"
        />
        <path
          d="M237 334.683h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 337.723h-1.752"
          class="no-fill"
        />
        <path
          d="M237 337.723h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 341.375H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 341.375H237v24.765h-7.658z"
        />
        <path
          d="M237 341.375v24.765"
          class="no-fill"
        />
        <path
          d="M237 341.375v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 366.14v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 366.14v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 344.408h-1.752"
          class="no-fill"
        />
        <path
          d="M237 344.408h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 350.646h-1.752"
          class="no-fill"
        />
        <path
          d="M237 350.646h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 356.75h-1.752"
          class="no-fill"
        />
        <path
          d="M237 356.75h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 366.14h-4.429"
          class="no-fill"
        />
        <path
          d="M237 366.14h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 353.698h-3.528"
          class="no-fill"
        />
        <path
          d="M237 353.698h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 347.527h-3.528"
          class="no-fill"
        />
        <path
          d="M237 347.527h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 359.789h-3.528"
          class="no-fill"
        />
        <path
          d="M237 359.789h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 362.829h-1.752"
          class="no-fill"
        />
        <path
          d="M237 362.829h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 366.481H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 366.481H237v24.765h-7.658z"
        />
        <path
          d="M237 366.481v24.765"
          class="no-fill"
        />
        <path
          d="M237 366.481v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 391.246v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 391.246v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 369.513h-1.752"
          class="no-fill"
        />
        <path
          d="M237 369.513h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 375.752h-1.752"
          class="no-fill"
        />
        <path
          d="M237 375.752h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 381.855h-1.752"
          class="no-fill"
        />
        <path
          d="M237 381.855h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 391.246h-4.429"
          class="no-fill"
        />
        <path
          d="M237 391.246h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 378.803h-3.528"
          class="no-fill"
        />
        <path
          d="M237 378.803h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 372.632h-3.528"
          class="no-fill"
        />
        <path
          d="M237 372.632h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 384.895h-3.528"
          class="no-fill"
        />
        <path
          d="M237 384.895h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 387.935h-1.752"
          class="no-fill"
        />
        <path
          d="M237 387.935h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 391.587H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 391.587H237v24.765h-7.658z"
        />
        <path
          d="M237 391.587v24.765"
          class="no-fill"
        />
        <path
          d="M237 391.587v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 416.352v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 416.352v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 394.62h-1.752"
          class="no-fill"
        />
        <path
          d="M237 394.62h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 400.858h-1.752"
          class="no-fill"
        />
        <path
          d="M237 400.858h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 406.962h-1.752"
          class="no-fill"
        />
        <path
          d="M237 406.962h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 416.352h-4.429"
          class="no-fill"
        />
        <path
          d="M237 416.352h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 403.91h-3.528"
          class="no-fill"
        />
        <path
          d="M237 403.91h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 397.739h-3.528"
          class="no-fill"
        />
        <path
          d="M237 397.739h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 410.001h-3.528"
          class="no-fill"
        />
        <path
          d="M237 410.001h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 413.041h-1.752"
          class="no-fill"
        />
        <path
          d="M237 413.041h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 416.693H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 416.693H237v24.765h-7.658z"
        />
        <path
          d="M237 416.693v24.765"
          class="no-fill"
        />
        <path
          d="M237 416.693v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 441.458v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 441.458v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 419.725h-1.752"
          class="no-fill"
        />
        <path
          d="M237 419.725h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 425.964h-1.752"
          class="no-fill"
        />
        <path
          d="M237 425.964h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 432.067h-1.752"
          class="no-fill"
        />
        <path
          d="M237 432.067h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 441.458h-4.429"
          class="no-fill"
        />
        <path
          d="M237 441.458h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 429.015h-3.528"
          class="no-fill"
        />
        <path
          d="M237 429.015h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 422.844h-3.528"
          class="no-fill"
        />
        <path
          d="M237 422.844h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 435.107h-3.528"
          class="no-fill"
        />
        <path
          d="M237 435.107h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 438.147h-1.752"
          class="no-fill"
        />
        <path
          d="M237 438.147h-1.752"
          class="gray-stroke"
        />
        <path
          d="M154.38 253.578h.26c-.15-.649-.26 0-.26 0ZM166.663 305.377c2.869-6.838-.069-9.674-.069-9.674s4.507-26.063 4.334-32.328c-.242-8.792-1.08-11.627-1.08-11.627H156.64c.162.697.373 4.727.549 10.868.301 10.461 1.347 19.669-.093 30.378-.732 5.447-2.716 5.33-3.815 9.271-.184.659-.955 4.733-.966 5.463-.999 3.071-.963 3.68-.337 6.791.183.913 3.244-.354 3.244-4.909 1.646 2.574 2.128 7.487 4.93 8.149 6.714 1.584 5.657 0 6.511-.984 1.343-1.546.163-3.026-.458-4.266-.399-.796.88 1.516 1.83.764 1.677-1.328-2.317-5.644-1.372-7.896Z"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M174.195 251.748h-18.973s1.322-17.554 4.168-25.129c2.846-7.574 14.805-13.406 14.805-13.406v38.535Z"
          style="fill:#1d4b6d;fill-rule:nonzero"
        />
        <path
          d="M164.832 337.171s8.005 23.333 8.085 28.02c0 0-.305 1.663.915 6.657 0 0 5.187 43.794 4.272 48.814-.915 5.02-7.452 6.134-10.373 8.583-2.921 2.449-3.868 2.706-8.341 4.841-4.473 2.135-8.439 1.525-8.134 5.186.305 3.661 8.542 2.746 14.949 2.746s8.848-.61 13.729-1.221c4.882-.61 6.712 1.221 11.746-.915 5.034-2.135-3.622-14.005-3.107-19.053.513-5.048 2.064-15.995 3.178-27.596 1.114-11.601-1.874-14.452-2.58-21.164-.706-6.712 2.509-34.898 2.509-34.898"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M214.997 336.908s-6.104 25.724-5.677 32.46c.426 6.735 4.775 11.42 3.18 22.964-1.594 11.544-3.575 24.126-4.299 29.148-.722 5.023 7.509 17.173 2.391 19.098-5.118 1.925-6.688 1.735-11.607 1.735-5.726 0-7.61-.325-14.011-.591-6.401-.266-14.67 1.306-14.822-2.365-.153-3.67 3.784-3.895 8.341-5.844 4.558-1.948 5.515-2.164 8.536-4.49 3.02-2.325 8.066-3.063 9.189-8.041 1.123-4.977-4.451-49.308-4.451-49.308-1.012-5.041-.727-6.593-.727-6.593.116-4.687-3.083-28.173-3.083-28.173s21.33-6.438 27.04 0Z"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M180.816 209.68a13.44 13.44 0 0 0-6.621 3.533c-3.692 3.586-8.004 10.057-9.142 13.406-1.627 4.789-5.393 13.441-4.848 23.095.643 11.389-.392 32.487.675 41.359 0 0 37.664-.08 46.756-.08 0 0-1.797-24.923 6.134-40.681 4.5-8.941 6.204-19.17 2.95-26.666-.846-1.948-4.117-7.485-6.237-8.584-5.533-2.869-10.718-6.717-16.922-6.717-5.188 0-9.72.703-12.745 1.335Z"
          style="fill:#66b1dd;fill-rule:nonzero"
        />
        <path
          d="M207.597 290.993c6.134 8.136 7.574 16.483 7.361 20.228-.212 3.745 0 25.687 0 25.687-7.728 2.892-26.425.263-26.425.263-14.782 3.38-23.739 0-23.739 0s-1.002-9.211-3.057-18.495c-2.901-13.102-.895-27.603-.895-27.603l46.755-.08Z"
          style="fill:#2d5a84;fill-rule:nonzero"
        />
        <path
          d="M210.483 215.062s7.296 2.922 12.366 12.217c2.936 5.383 4.221 24.469 4.221 24.469-8.767 2.125-14.649 1.945-23.797 0 0 0-2.351-24.926 0-30.158 2.351-5.232 7.21-6.528 7.21-6.528Z"
          style="fill:#275579;fill-rule:nonzero"
        />
        <path
          d="M208.57 251.748s2.35 16.672 2.108 25.464c-.173 6.265 0 16.66 0 16.66s-5.422 4.146-5.526 11.178c-.111 7.463-1.06 6.495-.202 7.638.763 1.015 3.357-.071 3.126-3.11.624-1.275 1.177-2.585 1.568-3.248 1.002-1.701 1.668 1.993 1.034 4.349-.634 2.357-7.372 6.049-6.55 8.541.309.935 3.721.917 3.721.917s.531.462 1.741.305c1.108-.145 3.903-1.141 3.903-1.141s3.155-2.442 3.509-3.373c2.621-6.896 2.618-10.066 1.405-14.707-1.295-4.962-1.233-4.838.186-10.148 2.024-7.577 4.869-12.344 6.697-22.648 1.421-8.01 0-16.677 0-16.677-5.611-2.095-11.182-1.973-16.72 0Z"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M188.111 336.785c-.004 1.436.139 2.946.269 4.428.137 1.487.301 2.974.466 4.461a515.31 515.31 0 0 0 1.095 8.918 506.967 506.967 0 0 1-1.59-8.847 243.098 243.098 0 0 1-.715-4.443c-.214-1.492-.442-2.96-.525-4.517h1ZM190.316 427.024c-.693.69-1.41 1.095-2.139 1.544-.729.432-1.468.833-2.212 1.222a120.358 120.358 0 0 1-4.505 2.215 268.762 268.762 0 0 1-9.157 4.073l-.013.005-.014.004c-.585.133-1.168.468-1.505.981-.342.512-.37 1.188-.43 1.817-.04.632.024 1.294.357 1.842.33.554.936.881 1.551 1.05-.619-.148-1.245-.455-1.604-1.016-.364-.552-.451-1.236-.433-1.881.043-.637.036-1.32.394-1.916.358-.583.96-.962 1.617-1.139l-.027.009a269.248 269.248 0 0 0 8.984-4.396 120.83 120.83 0 0 0 4.393-2.365c.722-.41 1.434-.833 2.125-1.277.677-.442 1.396-.921 1.869-1.434l.749.662Z"
          class="gray-fill"
        />
        <path
          d="M187.079 337.253c-.598-4.083-.989-8.173-1.34-12.266a481.355 481.355 0 0 1-.87-12.288l.1.13a55.695 55.695 0 0 1-4.835-2.067 27.574 27.574 0 0 1-2.307-1.262c-.726-.477-1.482-.978-1.961-1.72.499.727 1.264 1.201 2 1.656a27.5 27.5 0 0 0 2.332 1.192 56.046 56.046 0 0 0 4.87 1.919l.091.032.008.099c.355 4.086.758 8.17 1.218 12.244.467 4.074.974 8.146 1.681 12.167l-.987.164Z"
          style="fill:#231f20;fill-rule:nonzero"
        />
        <path
          d="m173.884 197.365 1.129 11.994c-1.279 3.782.551 8.755 5.485 8.755 5.523 0 10.129-2.338 12.597-4.057 3.805-2.651 5.495-5.174 5.495-5.174-1.402-5.54-1.847-15.992 1.552-22.213 3.083-5.643-25.905 5.058-26.258 10.695Z"
          style="fill:#d3d3d3;fill-rule:nonzero"
        />
        <clipPath id="a">
          <path
            d="m173.884 197.365 1.129 11.994c-1.279 3.782.551 8.755 5.485 8.755 5.523 0 10.129-2.338 12.597-4.057 3.805-2.651 5.495-5.174 5.495-5.174-1.402-5.54-1.847-15.992 1.552-22.213 3.083-5.643-25.905 5.058-26.258 10.695Z"
            clip-rule="nonzero"
          />
        </clipPath>
        <g clip-path="url(#a)">
          <path
            d="M171.164 197.637c-1.539-5.707 27.323-19.839 30.855-15.382-1.016.949-4.796 10.755-8.157 19.231-2.355 5.939-5.322 5.581-9.218 7.441-2.449 1.169-8.075 3.674-9.631.432-1.557-3.242-2.817-10.791-3.849-11.722Z"
            style="fill:#b4b4b4;fill-rule:nonzero"
          />
        </g>
        <path
          d="m173.801 195.473 1.142 12.131c-1.294 3.825.119 11.294 5.11 11.294 5.586 0 10.244-2.364 12.741-4.103 3.848-2.682 5.558-5.233 5.558-5.233-1.418-5.603-1.43-18.614 2.009-24.907 3.117-5.707-26.204 5.116-26.56 10.818Z"
          style="fill:#d3d3d3;fill-rule:nonzero"
        />
        <clipPath id="b">
          <path
            d="m173.801 195.473 1.142 12.131c-1.294 3.825.119 11.294 5.11 11.294 5.586 0 10.244-2.364 12.741-4.103 3.848-2.682 5.558-5.233 5.558-5.233-1.418-5.603-1.43-18.614 2.009-24.907 3.117-5.707-26.204 5.116-26.56 10.818Z"
            clip-rule="nonzero"
          />
        </clipPath>
        <g clip-path="url(#b)">
          <path
            d="M171.051 195.748c-1.558-5.773 27.635-20.066 31.208-15.558-1.027.959-4.852 10.878-8.251 19.451-2.382 6.008-5.383 5.645-9.324 7.527-2.477 1.182-8.167 3.715-9.741.436-1.575-3.279-2.85-10.914-3.892-11.856Z"
            style="fill:#b4b4b4;fill-rule:nonzero"
          />
        </g>
        <path
          d="M210.49 171.521c3.185-17.518 3.399-27.623-29.659-27.623-18.302 0-21.156 8.995-21.156 24.255 0 3.893.493 5.251.561 8.631.028 1.388-1.026 7.004-1.201 9.761-.281 4.451.704 9.112 6.413 12.802 1.138.736 2.753 3.698 6.458 3.698 2.18 0 5.507.323 7.948-2.494 1.605.394 4.472.19 6.097-.084 8.562-1.444 12.219-5.539 12.926-10.922 3.728-1.019 9.199-4.751 11.613-18.024Z"
          style="fill:#d3d3d3;fill-rule:nonzero"
        />
        <path
          d="M214.334 156.303c-2.664-11.438-12.645-15.443-21.751-16.548-3.303 0-9.058-.007-12.372-.007-8.776 1.009-14.01 4.879-15.933 7.384-2.335 3.042-3.13 7.951-3.13 7.951s2.259-4.653 3.995-4.919c1.735-.265 3.3 1.205 6.82 1.205 3.52 0 9.479-2.031 9.479-2.031 7.76-.558 16.136 3.561 19.632 10.511.087.173.173.349.258.528 2.562 5.386 2.378 5.487-2.132 14.831-.959 1.988.877 1.527.877 1.527s1.299-1.248 3.087-1.54c3.086-.504 6.866 5.03 2.177 9.719 0 0 9.202-8.722 9.202-16.236 0-2.147.235-10.47-.209-12.375Z"
          style="fill:#154262;fill-rule:nonzero"
        />
        <path
          d="M165.884 193.973c.847.444 1.061.102 2.051-.226.998.519 2.097.93 2.739.771.645-.153 1.479.382.829.83-.391.271-.374.234-.626.369-.668.355-1.732-.325-2.516-.325-.785-.001-.941.368-1.609.012-.668-.36-.444-.819-.868-1.431Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
        <path
          d="M178.861 174.866c1.618 0 1.835-.224 4.664 1.604 0 0-1.018.573-1.741 1.06-1.219.823-2.164 1.17-3.179 1.182-1.686.018-2.198-.545-2.198-.545-.619-.271-.907.464-.907 0 0-1.407 1.742-3.301 3.361-3.301Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <clipPath id="c">
          <path
            d="M178.861 174.866c1.618 0 1.835-.224 4.664 1.604 0 0-1.018.573-1.741 1.06-1.219.823-2.164 1.17-3.179 1.182-1.686.018-2.198-.545-2.198-.545-.619-.271-.907.464-.907 0 0-1.407 1.742-3.301 3.361-3.301Z"
            clip-rule="nonzero"
          />
        </clipPath>
        <g clip-path="url(#c)">
          <path
            d="M180.699 176.91a1.831 1.831 0 0 1-1.82 1.842 1.83 1.83 0 0 1-1.82-1.842 1.83 1.83 0 0 1 1.82-1.842c1.005 0 1.82.825 1.82 1.842Z"
            class="gray-fill"
          />
        </g>
        <path
          d="M178.861 174.866c1.618 0 1.835-.224 4.664 1.604 0 0-1.018.573-1.741 1.06-1.219.823-2.164 1.17-3.179 1.182-1.686.018-2.198-.545-2.198-.545-.619-.271-.907.464-.907 0 0-1.407 1.742-3.301 3.361-3.301Z"
          style="fill:none;stroke:#7c7c7c;stroke-width:.28px"
        />
        <path
          d="M176.753 175.429c-1.477 1.127-1.208 2.38-1.208 2.38s.505-2.235 2.684-2.389c3.403-.241 4.779.418 5.296 1.05l.715-1.026s-4.542-2.264-7.487-.015Z"
          class="gray-fill"
        />
        <path
          d="M165.294 174.866c-1.298 0-1.962-.945-2.94 1.714 0 0 .285.129.711.601.526.582 1.327 1.531 2.804 1.531.563 0 .883-.093 1.495-.426.497-.27.827.074.858-.39.138-2.007-1.376-3.03-2.928-3.03Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <clipPath id="d">
          <path
            d="M165.294 174.866c-1.298 0-1.962-.945-2.94 1.714 0 0 .285.129.711.601.526.582 1.327 1.531 2.804 1.531.563 0 .883-.093 1.495-.426.497-.27.827.074.858-.39.138-2.007-1.376-3.03-2.928-3.03Z"
            clip-rule="nonzero"
          />
        </clipPath>
        <g clip-path="url(#d)">
          <path
            d="M164.133 177.006c0 .942.727 1.706 1.623 1.706.897 0 1.624-.764 1.624-1.706s-.727-1.706-1.624-1.706c-.896 0-1.623.764-1.623 1.706Z"
            class="gray-fill"
          />
        </g>
        <path
          d="M165.294 174.866c-1.298 0-1.962-.945-2.94 1.714 0 0 .285.129.711.601.526.582 1.327 1.531 2.804 1.531.563 0 .883-.093 1.495-.426.497-.27.827.074.858-.39.138-2.007-1.376-3.03-2.928-3.03Z"
          style="fill:none;stroke:#7c7c7c;stroke-width:.25px"
        />
        <path
          d="M167.522 175.428c1.185 1.128.7 2.468.7 2.468s.009-2.504-2.93-2.524c-1.532-.011-2.524.575-2.938 1.208l-.427-.917s3.232-2.483 5.595-.235Z"
          class="gray-fill"
        />
        <path
          d="M165.548 185.995s-.834 1.412.507 1.354c1.341-.057 3.734-.205 5.505.355 1.77.559 1.948-.513 2.192-.861.631-.896-.516-2.889-.516-2.889s.377 1.938-.777 2.364c-.884.327-2.089-.386-3.345-.735-.81-.224-1.989-.807-2.795-.472-.808.335-.771.884-.771.884ZM175.236 188.526c-.802 1.09-2.025 3.217-2.025 3.217s-4.152-2.157-4.521-2.256c-.369-.099-.642.394-1.036.444-.395.049-.741-.444-1.086-.296-.345.148-.381 1.416-1.367 1.642-.477.11-.574-1.199-.482-1.347-.08.13-.762.824-.754 1.794.008.97.988 2.181.959 2.28.101.001-.142-1.82.277-2.011.419-.191 1.649-.683 1.959-.631.564.095.819-.31 1.705-.099 2.291.545 3.732 1.434 4.462 1.168l2.632 2.839c-1.5-1.694-1.929-4.822-.723-6.744ZM205.341 179.176s.018-2.819-1.776-2.703c-.769.05-2.643.378-3.439 2.38-1.009 2.538 1.267 1.611 1.267 2.926 0 1.316.309 1.013-.963 2.703 0 0 2.861-1.554 3.063-2.511.318-1.508-3.211-.868-1.068-3.161 1.276-1.367 2.916.366 2.916.366Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
        <path
          d="M171.575 151.54s-.529 2.203-.814 6.22c0 0-1.362-4.073-.86-6.082 0 0-1.108 3.593-1.853 5.872 0 0-.985-7.804.409-7.397 1.348.394 3.211-.537 7.502.525 0 0-.273 1.665 0 4.076 0 0-1.024-1.707-1.078-3.315 0 0-1.652 4.721-1.606 6.629 0 0-.348-5.84 0-7.39 0 0-.972 2.369-1.016 3.975l-.684-3.113Z"
          style="fill:#154262;fill-rule:nonzero"
        />
        <path
          d="M166.377 151.54s-1.703 2.511-1.988 6.528c0 0-.757-4.381-.255-6.39 0 0-.893 3.106-1.637 5.386 0 0-.228-1.866-.1-3.523.026-.34-1.715 3.284-1.715 2.974-.002-2.949 1.986-4.92 2.124-5.176.666-1.236 3.665-1.723 7.955-.661 0 0-.272 1.665 0 4.076 0 0-1.023-1.707-1.078-3.315 0 0-1.652 4.721-1.607 6.629 0 0-.346-5.84 0-7.39 0 0-.971 2.369-1.015 3.975l-.684-3.113ZM177.984 151.54s.529 2.203.814 6.22c0 0 1.362-4.073.86-6.082 0 0 1.109 3.593 1.853 5.872 0 0 .985-7.804-.409-7.397-1.348.394-3.211-.537-7.502.525 0 0 .273 1.665 0 4.076 0 0 1.024-1.707 1.078-3.315 0 0 1.652 4.721 1.607 6.629 0 0 .347-5.84 0-7.39 0 0 .972 2.369 1.015 3.975l.684-3.113Z"
          style="fill:#154262;fill-rule:nonzero"
        />
        <path
          d="M183.182 151.54s1.703 2.511 1.988 6.528c0 0 .757-4.381.255-6.39 0 0 .893 3.106 1.637 5.386 0 0 1.149-3.764 1.021-5.421-.026-.34 1.583 9.101 1.583 8.79.002-2.948 1.583-11.053 0-11.195-1.397-.124-7.65-.631-11.941.431 0 0 1.346 2.674 1.073 5.085 0 0 1.024-1.707 1.078-3.315 0 0 1.653 4.721 1.607 6.629 0 0 .346-5.84 0-7.39 0 0 .971 2.369 1.015 3.975l.684-3.113Z"
          style="fill:#154262;fill-rule:nonzero"
        />
        <path
          d="M191.686 152.672s2.025 2.985 2.364 7.761c0 0-.019-5.373-.617-7.761 0 0 1.981 3.857 2.866 6.567 0 0-1.279-5.373-1.715-7.045-.434-1.671-3.197-2.707-8.109-2.585 0 0 .912 4.589.587 7.455 0 0 .63-2.601.694-4.512 0 0 1.965 5.613 1.91 7.881 0 0 .412-6.943 0-8.785 0 0 1.156 2.815 1.207 4.726l.813-3.702Z"
          style="fill:#154262;fill-rule:nonzero"
        />
        <path
          d="M159.675 170.463s3.187-.382 4.763-.174c1.576.209 3.163 1.053 3.774 1.213.611.161 1.311-1.063.312-1.515-.999-.451-2.746-.757-3.965-.825-1.219-.067-4.884 1.301-4.884 1.301ZM184.753 170.424s-3.184-.394-4.762-.192c-1.577.203-3.168 1.041-3.779 1.199-.611.158-1.306-1.069-.306-1.517 1.001-.447 2.75-.747 3.968-.809 1.219-.062 4.879 1.319 4.879 1.319Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M130.07 442.313h114.406v6.803H130.07z"
        />
        <path
          class="gray-stroke"
          d="M130.07 442.313h114.406v6.803H130.07z"
        />
        <path
          d="M207.636 442.313v6.817"
          class="no-fill"
        />
        <path
          d="M207.636 442.313v6.817"
          class="gray-stroke"
        />
        <path
          d="M253.362 419.129h-8.042l-29.462 29.463h7.591"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M253.362 419.129h-8.042l-29.462 29.463h7.591"
          class="gray-stroke"
        />
        <path
          d="m253.362 419.129-29.913 29.912h29.968l-.055-29.912Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m253.362 419.129-29.913 29.912h29.968l-.055-29.912Z"
          class="gray-stroke"
        />
        <path
          d="M189.137 414.611s4.882 1.525 7.729 1.628c2.848.101 5.768-1.209 4.948 1.591-.82 2.81-9.4 1.46-13 .78 1.95 1.43 8.896 2.621 10.33 2.54 1.434-.081 2.705 2.004-.04 2.564-3.875.789-8.39-.375-10.34-1.094l-.04.11c.11.109 1.09 1.05 3.71 2.21 2.75 1.22 3.334.979 4.011 1.63.675.651.269 2.14-1.261 1.94-5.8-.77-6.37-2.36-10.17-2.97-3.08-.491-12.33-2.98-15.81-8.65-2.39-3.89-43.04-11.091-49.42-21.551l-23.91-16.04-.24-.049c4.03-18.661 14.91-20.52 14.91-20.52l-.59-1.1c.41.49 13.63 16.1 19.35 21.05 0 0 11.4 7.36 16.82 11.619 5.42 4.271 20.13 12.281 26.821 13.871 0 0 5.699-2.27 10.799-2.27s9.32-.73 8.69 1.5c-.64 2.23-6.578.349-8.34 3.01-.815 1.23-1.069 2.46 1.601 3.5 3.689 1.45 9.222 2.392 13.052 1.752 3.821-.64 3.339 2.152.789 2.792-1.59.4-6.999.097-10.399.157Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M189.137 414.611s4.882 1.525 7.729 1.628c2.848.101 5.768-1.209 4.948 1.591-.82 2.81-9.4 1.46-13 .78 1.95 1.43 8.896 2.621 10.33 2.54 1.434-.081 2.705 2.004-.04 2.564-3.875.789-8.39-.375-10.34-1.094l-.04.11c.11.109 1.09 1.05 3.71 2.21 2.75 1.22 3.334.979 4.011 1.63.675.651.269 2.14-1.261 1.94-5.8-.77-6.37-2.36-10.17-2.97-3.08-.491-12.33-2.98-15.81-8.65-2.39-3.89-43.04-11.091-49.42-21.551l-23.91-16.04-.24-.049c4.03-18.661 14.91-20.52 14.91-20.52l-.59-1.1c.41.49 13.63 16.1 19.35 21.05 0 0 11.4 7.36 16.82 11.619 5.42 4.271 20.13 12.281 26.821 13.871 0 0 5.699-2.27 10.799-2.27s9.32-.73 8.69 1.5c-.64 2.23-6.578.349-8.34 3.01-.815 1.23-1.069 2.46 1.601 3.5 3.689 1.45 9.222 2.392 13.052 1.752 3.821-.64 3.339 2.152.789 2.792-1.59.4-6.999.097-10.399.157Z"
          class="gray-stroke"
        />
        <path
          d="m109.954 357.63-.009-.01"
          class="no-fill"
        />
        <path
          d="m109.954 357.63-.009-.01"
          style="fill:none;stroke:#d3d3d3;stroke-width:1px"
        />
        <path
          d="M198.994 359.45h-.009"
          style="fill:none;stroke:#e0e6eb;stroke-width:1px"
        />
        <path
          d="M195.267 358.237c.329-.008.649.042.974.067.322.043.641.102.958.169.317.072.632.147.945.237l.468.14c.152.066.336-.024.379.563h-.006l.009.037-.009.037h-.009c-.273.527-.405.377-.57.383l-.482-.04c-.32-.03-.638-.075-.955-.123-.315-.053-.63-.115-.941-.192-.309-.093-.623-.165-.926-.292l.165-.986Z"
          style="fill:#e3e9ee;fill-rule:nonzero"
        />
        <path
          d="M210.484 369.48c-.89 1.16-1.879.609-4.72-.05-4.1-.94-9.239-1.51-9.239-1.51v.01c3.399 1.159 12.069 4.28 11.939 5.75-.16 1.83-3.12 1.369-6.24.3-13.15-4.5-14.05-.91-14.05-.91s2.24 1.519 5.08 3.2c3.87 2.29.87 4.43-1.42 3.029-2.35-1.429-5.99-3.839-9-6.079-5.04-3.74-7.479-12.98-7.479-12.98s-20.441-9.24-27.761-16.36c-6.319-2.37-14.739-6.46-15.22-6.7 11.62-6.35 13.85-15.07 13.85-15.07l6.95 6.52c6.62 3.459 18.34 15.24 26.61 24 0 0 8.79 1.02 15.4 3.66 5.7 0 7.16-.35 11.27.54 2.66.57-.28 1.9-.28 1.9l-7.18.72c4.63.95 11.8 2.66 11.48 4.16-.36 1.66-5.119.81-8.25 0-4.07-1.05-5.699-.3-5.699-.3s15.5 4.16 13.959 6.17Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M210.484 369.48c-.89 1.16-1.879.609-4.72-.05-4.1-.94-9.239-1.51-9.239-1.51v.01c3.399 1.159 12.069 4.28 11.939 5.75-.16 1.83-3.12 1.369-6.24.3-13.15-4.5-14.05-.91-14.05-.91s2.24 1.519 5.08 3.2c3.87 2.29.87 4.43-1.42 3.029-2.35-1.429-5.99-3.839-9-6.079-5.04-3.74-7.479-12.98-7.479-12.98s-20.441-9.24-27.761-16.36c-6.319-2.37-14.739-6.46-15.22-6.7 11.62-6.35 13.85-15.07 13.85-15.07l6.95 6.52c6.62 3.459 18.34 15.24 26.61 24 0 0 8.79 1.02 15.4 3.66 5.7 0 7.16-.35 11.27.54 2.66.57-.28 1.9-.28 1.9l-7.18.72c4.63.95 11.8 2.66 11.48 4.16-.36 1.66-5.119.81-8.25 0-4.07-1.05-5.699-.3-5.699-.3s15.5 4.16 13.959 6.17Z"
          class="gray-stroke"
        />
        <path
          d="M196.525 367.93c-.83-.28-1.341-.45-1.341-.45"
          style="fill:none;stroke:#e3e9ee;stroke-width:1px"
        />
        <path
          d="M192.71 121.75s-7.223 1.539-10.274 2.454c-3.051.915-10.701 2.958-10.69 5.186.012 2.229-.752 2.281 2.638 2.622 3.389.342 29.617 0 32.703 0 3.086 0 6.134.933 6.134.933 6.209 1.417 16.192 7.044 33.487 20.828l19.016-16.451c-10.323-3.869-29.808-8.634-55.79-14.033 0 0-10.253-3.079-17.224-1.539Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M192.71 121.75s-7.223 1.539-10.274 2.454c-3.051.915-10.701 2.958-10.69 5.186.012 2.229-.752 2.281 2.638 2.622 3.389.342 29.617 0 32.703 0 3.086 0 6.134.933 6.134.933 6.209 1.417 16.192 7.044 33.487 20.828l19.016-16.451c-10.323-3.869-29.808-8.634-55.79-14.033 0 0-10.253-3.079-17.224-1.539Z"
          class="gray-stroke"
        />
        <path
          d="M244.476 262.351s10.102 39.904 9.492 46.327c-.61 6.424-1.073 24.12 1.649 26.866 2.724 2.746 16.317 8.66 37.978 1.847 21.661-6.813 40.525-17.548 42.355-20.892 1.831-3.345-12.188-61.802-14.019-71.26-1.83-9.458-1.354-16.17-4.404-21.966-3.051-5.797-10.198-24.014-17.76-31.685l-10.308 15.743 1.526 3.332s-3.966 5.966-8.542 7.457c-4.577 1.492-17.289 0-17.289 0l-13.525 6.678-23.492-.667s-3.814 2.498-5.085 15.419c-.959 9.748 4.17 19.113 8.441 20.943 4.272 1.831 30.944 3.051 40.489 0"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M244.476 262.351s10.102 39.904 9.492 46.327c-.61 6.424-1.073 24.12 1.649 26.866 2.724 2.746 16.317 8.66 37.978 1.847 21.661-6.813 40.525-17.548 42.355-20.892 1.831-3.345-12.188-61.802-14.019-71.26-1.83-9.458-1.354-16.17-4.404-21.966-3.051-5.797-10.198-24.014-17.76-31.685l-10.308 15.743 1.526 3.332s-3.966 5.966-8.542 7.457c-4.577 1.492-17.289 0-17.289 0l-13.525 6.678-23.492-.667s-3.814 2.498-5.085 15.419c-.959 9.748 4.17 19.113 8.441 20.943 4.272 1.831 30.944 3.051 40.489 0"
          class="gray-stroke"
        />
        <path
          d="M279.101 415.355s5.985-3.337 3.233-11.837"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M279.101 415.355s5.985-3.337 3.233-11.837"
          class="gray-stroke"
        />
        <path
          d="m279.101 409.696 28.226-53.918"
          class="no-fill"
        />
        <path
          d="M279.101 409.696c1.807-4.778 4.279-9.209 6.584-13.727l6.986-13.516 7.064-13.476 7.15-13.43.885.463-6.963 13.529-7.05 13.482-7.128 13.443c-2.399 4.468-4.632 9.024-7.528 13.232Z"
          class="gray-fill"
        />
        <path
          d="M295.756 361.646s33.095-12.983 35.129-16.746c2.034-3.763 5.065-28.401 5.065-28.401"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M295.756 361.646c2.481-1.426 5.073-2.559 7.683-3.654a603.672 603.672 0 0 0 7.797-3.356c5.178-2.261 10.38-4.525 15.318-7.212 1.202-.69 2.467-1.393 3.433-2.263.108-.109.252-.218.31-.323.08-.1.165-.201.219-.37.115-.31.273-.577.36-.921.208-.657.403-1.322.557-2.009.653-2.727 1.141-5.517 1.611-8.302.924-5.577 1.699-11.188 2.41-16.799l.992.122c-.712 5.628-1.489 11.241-2.417 16.841-.472 2.8-.959 5.595-1.626 8.376a28.036 28.036 0 0 1-.571 2.087c-.083.345-.271.705-.406 1.057-.054.173-.179.357-.319.538-.131.197-.276.302-.416.446-1.14 1.032-2.382 1.71-3.621 2.439-4.996 2.771-10.184 5.067-15.382 7.338a436.04 436.04 0 0 1-7.86 3.252c-2.628 1.064-5.284 2.061-8.072 2.713Z"
          class="gray-fill"
        />
        <path
          d="m303.386 363.307 3.941 40.211.91 11.133"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m303.386 363.307 3.941 40.211.91 11.133"
          class="gray-stroke"
        />
        <path
          d="M248.792 144.489s-4.316 16.547-4.316 20.615c0 4.067-6.576 11.441-5.356 14.263 1.221 2.821 5.356 4.578 5.356 4.578s1.086 10.849 4.431 15.738c1.936 2.829 6.564 3.926 10.121 4.348 2.777.33 4.87 6.54 5.329 9.298l.797 4.791s11.194 2.236 17.289 0c7.292-2.675 8.542-7.457 8.542-7.457l-1.526-3.332s13.978-21.061 14.384-32.336c.407-11.275 4.394-22.095-5.333-33.637-7.11-8.437-43.932-12.48-49.718 3.131Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M248.792 144.489s-4.316 16.547-4.316 20.615c0 4.067-6.576 11.441-5.356 14.263 1.221 2.821 5.356 4.578 5.356 4.578s1.086 10.849 4.431 15.738c1.936 2.829 6.564 3.926 10.121 4.348 2.777.33 4.87 6.54 5.329 9.298l.797 4.791s11.194 2.236 17.289 0c7.292-2.675 8.542-7.457 8.542-7.457l-1.526-3.332s13.978-21.061 14.384-32.336c.407-11.275 4.394-22.095-5.333-33.637-7.11-8.437-43.932-12.48-49.718 3.131Z"
          class="gray-stroke"
        />
        <path
          d="M255.722 173.233s2.516 4.086-.329 10.712"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M255.722 173.233c.628.716 1.003 1.627 1.239 2.549.241.93.299 1.896.26 2.851-.074.954-.246 1.898-.56 2.793a9.8 9.8 0 0 1-1.268 2.519c.062-.474.175-.92.264-1.37.073-.455.182-.895.248-1.342.148-.89.265-1.773.319-2.659.096-1.773-.005-3.537-.502-5.341Z"
          class="gray-fill"
        />
        <path
          d="M262.086 182.002s-2.016 7.592-8.118 5.529"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M262.57 182.13c-.322.949-.75 1.792-1.271 2.608-.524.805-1.157 1.55-1.923 2.143a5.383 5.383 0 0 1-2.646 1.059c-.955.141-1.887-.133-2.762-.409.901.198 1.832.379 2.725.162a5.02 5.02 0 0 0 2.365-1.197c.654-.61 1.176-1.348 1.595-2.139.413-.786.75-1.651.95-2.484l.967.257Z"
          class="gray-fill"
        />
        <path
          d="M192.71 126.339s-7.418.441-9.869 2.848c-2.45 2.407 0 2.958 0 2.958h13.699"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M192.71 126.339a58.39 58.39 0 0 0-3.502.685 28.434 28.434 0 0 0-3.377.992c-1.079.412-2.132.92-2.824 1.729-.372.394-.69.873-.727 1.224a.44.44 0 0 0 .153.377c.116.111.354.228.527.268l-.119-.013c2.283-.033 4.566-.051 6.85-.036 2.283-.003 4.566.025 6.849.08v1c-2.283.055-4.566.084-6.849.081-2.284.014-4.567-.004-6.85-.037l-.075-.001-.044-.012a2.337 2.337 0 0 1-1.033-.564c-.318-.286-.508-.806-.452-1.237.137-.847.608-1.35 1.035-1.817.925-.975 2.149-1.436 3.296-1.809 1.166-.373 2.363-.567 3.554-.719a25.358 25.358 0 0 1 3.588-.191Z"
          class="gray-fill"
        />
        <path
          d="m209.12 132.467-19.762.249c-6.588.076-13.175.102-19.763.109l-39.525.102.459-.46.041 6.803h-1l.04-6.803.46-.46 39.525.102c6.588.008 13.175.033 19.763.109l19.762.249Z"
          style="fill:#231f20;fill-rule:nonzero"
        />
        <path
          d="M289.459 207.331s14.89 1.106 17.868 4.862"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M289.496 206.832c3.145.348 6.248.861 9.321 1.568 1.53.371 3.05.795 4.529 1.341 1.447.58 2.957 1.241 3.981 2.452-1.086-1.152-2.603-1.718-4.064-2.216-1.49-.462-3.015-.802-4.549-1.088a80.368 80.368 0 0 0-9.292-1.059l.074-.998Z"
          class="gray-fill"
        />
        <path
          d="M255.617 335.544s.182 18.929-.224 22.076c-.407 3.146-22.222 65.03-22.222 74.519 0 9.49-.468 17.171 7.576 16.977 8.045-.194 104.542 0 104.542 0s12.204-2.522 13.018-12.284c.813-9.763-10.984-25.21-18.712-23.181-7.73 2.028-54.393.567-60.494 1.704-6.103 1.138-6.103 2.335-14.238 5.804"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M255.617 335.544s.182 18.929-.224 22.076c-.407 3.146-22.222 65.03-22.222 74.519 0 9.49-.468 17.171 7.576 16.977 8.045-.194 104.542 0 104.542 0s12.204-2.522 13.018-12.284c.813-9.763-10.984-25.21-18.712-23.181-7.73 2.028-54.393.567-60.494 1.704-6.103 1.138-6.103 2.335-14.238 5.804"
          class="gray-stroke"
        />
        <path
          d="M337.469 414.651c-4.598 6.699-7.025 8.411-6.518 15.702.508 7.292 24.882 18.442 36.899 18.442 12.015 0 23.406-.951 23.406-11.135s-24.223-11.097-32.909-18.531c-8.688-7.435-16.28-11.178-20.878-4.478Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M337.469 414.651c-4.598 6.699-7.025 8.411-6.518 15.702.508 7.292 24.882 18.442 36.899 18.442 12.015 0 23.406-.951 23.406-11.135s-24.223-11.097-32.909-18.531c-8.688-7.435-16.28-11.178-20.878-4.478Z"
          class="gray-stroke"
        />
        <path
          d="m256.07 130.896-7 1.132a4.441 4.441 0 0 0-3.279 6.338l3.001 6.123s4.566 7.191 3.752 14.919c-.813 7.729 0 12.611 0 12.611s0-3.255 5.085-2.848c5.084.407 4.678 10.169 3.457 13.831 10.896 12.555 15.124 25.343 15.124 25.343l13.249-1.014 10.308-15.743s19.155-18.266 8.438-50.078c-6.441-19.118-52.135-10.614-52.135-10.614Z"
          class="gray-fill"
        />
        <path
          d="M244.476 165.104s3.189 8.129.749 13.016"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M244.942 164.921c.367 1.095.636 2.179.866 3.287.221 1.105.397 2.219.469 3.345.154 2.23-.015 4.573-1.052 6.567.881-2.066.866-4.349.554-6.513-.151-1.09-.405-2.164-.7-3.223-.3-1.052-.652-2.11-1.068-3.098l.931-.365Z"
          class="gray-fill"
        />
        <g v-if="showArrows">
          <path
            d="M199.949 66.814V38.858h-13.38v27.956h-6.224l12.914 11.184 12.914-11.184h-6.224ZM235.615 423.58h27.957V410.2h-27.957v-6.224l-11.183 12.913 11.183 12.914v-6.223ZM235.615 375.181h27.957v-13.38h-27.957v-6.224l-11.183 12.914 11.183 12.914v-6.224Z"
            class="orange-arrow"
          />
        </g>
        <path
          d="M174.377 201.594c2.13-.777 11.242-4.969 11.125-7.586-.204-4.529-.52-8.907 3.451-8.907 2.981 0-1.38 3.9 3.898 10.324 2.484 3.023 1.422 8.08 1.422 8.08l13.363 20.453 20.501 1.015c-7.321 5.379-4.684 23.378-4.684 23.378s-22.202-3.623-26.364-8.558c-4.162-4.935-10.777-29.066-10.777-29.066s-11.474-1.228-15.924-2.732c-4.45-1.504-7.704-4.62-8.461-7.026-.758-2.404-1.775-7.405-1.775-7.405s2.486 4.382 4.813 5.709c1.294.738 3.249 4.57 9.412 2.321Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M174.377 201.594c2.13-.777 11.242-4.969 11.125-7.586-.204-4.529-.52-8.907 3.451-8.907 2.981 0-1.38 3.9 3.898 10.324 2.484 3.023 1.422 8.08 1.422 8.08l13.363 20.453 20.501 1.015c-7.321 5.379-4.684 23.378-4.684 23.378s-22.202-3.623-26.364-8.558c-4.162-4.935-10.777-29.066-10.777-29.066s-11.474-1.228-15.924-2.732c-4.45-1.504-7.704-4.62-8.461-7.026-.758-2.404-1.775-7.405-1.775-7.405s2.486 4.382 4.813 5.709c1.294.738 3.249 4.57 9.412 2.321Z"
          style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linejoin:round"
        />
      </g>
      <g v-else>
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M170.388 90.883h78.404v358.04h-78.404z"
        />
        <path
          class="gray-stroke"
          d="M170.388 90.883h78.404v358.04h-78.404z"
        />
        <path
          d="M244.476 449.116V90.883"
          class="no-fill"
        />
        <path
          d="M244.476 449.116V90.883M156.877 412.105s5.769 4.936 7.803 17.14c2.034 12.203-16.678 19.383-31.322 19.383-14.644 0-60.611-1.22-69.967 0-9.355 1.221-8.949-4.529-2.034-9.18 6.916-4.65.814-12.786 7.093-17.42"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M129.802 132.467h114.406v6.803H129.802z"
        />
        <path
          class="gray-stroke"
          d="M129.802 132.467h114.406v6.803H129.802z"
        />
        <path
          d="M244.166 109.675h-6.127l-22.686 22.687h5.784"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M244.166 109.675h-6.127l-22.686 22.687h5.784"
          class="gray-stroke"
        />
        <path
          d="m244.166 109.675-22.791 22.791h22.833l-.042-22.791Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m244.166 109.675-22.791 22.791h22.833l-.042-22.791Z"
          class="gray-stroke"
        />
        <path
          d="M176.868 109.675h-6.127l-22.449 22.449h5.785"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M176.868 109.675h-6.127l-22.449 22.449h5.785"
          class="gray-stroke"
        />
        <path
          d="m176.868 109.675-22.791 22.791h22.833l-.042-22.791Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m176.868 109.675-22.791 22.791h22.833l-.042-22.791Z"
          class="gray-stroke"
        />
        <path
          d="M207.636 132.46v6.817"
          class="no-fill"
        />
        <path
          d="M207.636 132.46v6.817"
          class="gray-stroke"
        />
        <path
          d="M179.37 419.129h-7.921l-29.02 29.021h7.478"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M179.37 419.129h-7.921l-29.02 29.021h7.478"
          class="gray-stroke"
        />
        <path
          d="m179.37 419.129-29.463 29.463h29.518l-.055-29.463Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m179.37 419.129-29.463 29.463h29.518l-.055-29.463ZM132.374 337.18c-.759.41-1.561.82-2.41 1.21M146.224 322.11l-13.76-14.33"
          class="gray-stroke"
        />
        <path
          d="M56.791 363.611c-2.553-16.271 0-44.916 26.229-57.628"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M56.791 363.611c-.83-2.739-1.164-5.595-1.257-8.444-.08-2.851.009-5.708.277-8.55.538-5.681 1.793-11.322 3.94-16.64 2.163-5.305 5.246-10.26 9.177-14.451 3.917-4.214 8.74-7.498 13.875-9.993l.436.9c-5.009 2.513-9.644 5.802-13.422 9.926-3.816 4.082-6.81 8.906-8.915 14.088-2.138 5.174-3.429 10.692-4.009 16.277a68.967 68.967 0 0 0-.351 8.418c.06 2.814.222 5.61.249 8.469Z"
          class="gray-fill"
        />
        <path
          d="M56.258 350.998s-7.51 9.156-1.409 17.8c6.102 8.644 21.356 25.452 50.285 16.715"
          class="gray-stroke"
        />
        <path
          d="M52.443 360.764s-9.933 16.068-9.933 25.22c0 9.153 1.46 18.306 8.933 25.221 7.474 6.915 11.948 10.169 25.372 13.017 9.562 2.028 24.826.03 36.934 1.8 4.89.715 9.266 2.045 12.542 4.502"
          class="gray-stroke"
        />
        <path
          d="M56.791 444.061s-15.138 5.788-23.094 4.733c-7.957-1.054-11.797 1.055-8.543-6.166 3.254-7.22 16.499-5.796 18.305-15.966 1.806-10.169 13.332-11.307 13.332-11.307M97.964 335.54l11.981 22.08.009.01M95.634 379.25c-.19.85-.36 1.74-.51 2.67l-23.191-18.31"
          class="gray-stroke"
        />
        <path
          d="M97.804 251.945c1.122-.991 1.885-2.69 1.132-3.984-.753-1.294-3.333-.875-3.159.612 1.412-.269 2.256-1.896 2.062-3.32-.194-1.424-1.17-2.624-2.27-3.549-3.099-2.609-7.511-3.569-11.413-2.482-.758.211-1.537.523-2.014 1.15-.476.626-.51 1.645.116 2.122.74-.385-.239-1.417-1.063-1.544-6.76-1.046-14.371 3.729-14.946 10.545-.07.84-.026 1.74.445 2.44.47.7 1.483 1.088 2.193.634-3.158-.756-6.698 1.673-7.128 4.891-.432 3.219 2.344 6.494 5.589 6.596.007-.577-.731-.929-1.276-.742-.546.187-.897.712-1.148 1.232-1.159 2.401-.848 5.556 1.069 7.408 1.918 1.852 5.414 1.913 7.13-.127-1.201-.003-1.735 1.806-.929 2.697.805.891 2.279.85 3.321.254 1.042-.596 1.765-1.61 2.492-2.566.725-.957 1.538-1.925 2.657-2.361l17.14-19.906Z"
          class="gray-fill"
        />
        <path
          d="M133.743 277.211c-.32 1.306.776-5.957-8.72-6.165-12.818-7.706-17.95 3.527-23.913 8.648-5.965 5.122-7.76 11.997-9.141 19.736l-.222-.473-2.351-1.86c-1.235-.977-2.498-1.97-3.972-2.523-1.476-.554-3.226-.606-4.55.248-1.623 1.046-2.221 3.24-1.837 5.132.384 1.892 1.57 3.523 2.887 4.936 1.304 1.402 2.802 2.679 4.584 3.385 1.78.706 4.039.433 5.713-.495 2.136 4.908 11.619 9.376 17.328 10.485s10.755 1.838 15.301-1.789c6.848-5.463 12.373-13.956 10.974-22.604-1.006-6.224-4.194-6.959-2.081-16.661Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M133.743 277.211c-.32 1.306.776-5.957-8.72-6.165-12.818-7.706-17.95 3.527-23.913 8.648-5.965 5.122-7.76 11.997-9.141 19.736l-.222-.473-2.351-1.86c-1.235-.977-2.498-1.97-3.972-2.523-1.476-.554-3.226-.606-4.55.248-1.623 1.046-2.221 3.24-1.837 5.132.384 1.892 1.57 3.523 2.887 4.936 1.304 1.402 2.802 2.679 4.584 3.385 1.78.706 4.039.433 5.713-.495 2.136 4.908 11.619 9.376 17.328 10.485s10.755 1.838 15.301-1.789c6.848-5.463 12.373-13.956 10.974-22.604-1.006-6.224-4.194-6.959-2.081-16.661Z"
          class="gray-stroke"
        />
        <path
          d="M80.593 295.981c-6.12-3.509-8.604-12.171-7.47-22.154 1.224-10.78 14.468-25.12 25.813-25.866 11.609-.763 20.156 1.303 27.437 10.035 3.423 4.106 8.643 14.025 7.37 19.215-2.481-6.165-7.13-5.588-10.962-4.921-3.667.638-6.118-2.387-6.118-2.387s-.731 5.436-2.628 6.149c-20.266 7.627-21.559 26.223-21.559 26.223s-5.085-11.202-11.883-6.294Z"
          class="gray-fill"
        />
        <path
          d="M78.849 308.679s-.199 1.913 4.171 7.677c4.371 5.764 27.525 21.429 32.439 19.188 0 0 4.048-10.357 3.921-16.594"
          class="gray-stroke"
        />
        <path
          d="M122.781 308.679s9.683 1.848 6.304-5.923c-1.877-4.318-3.863-7.076-3.863-7.076"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M122.781 308.679c1.646.062 3.339-.008 4.775-.564.71-.286 1.289-.753 1.55-1.378.263-.627.252-1.385.069-2.13-.158-.756-.446-1.48-.78-2.238-.315-.756-.657-1.499-.994-2.247-.699-1.484-1.4-2.974-2.179-4.442 1.101 1.25 2.046 2.636 2.871 4.083.8 1.467 1.631 2.896 2.06 4.633.184.855.229 1.833-.188 2.734-.437.891-1.297 1.484-2.146 1.716-1.717.502-3.441.269-5.038-.167Z"
          class="gray-fill"
        />
        <path
          d="M110.545 302.611s5.321-5.711 12.236-6.931"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M110.545 302.611c.683-.999 1.527-1.873 2.43-2.672a19.825 19.825 0 0 1 2.932-2.128 16.818 16.818 0 0 1 3.309-1.505 12.047 12.047 0 0 1 3.565-.626c-1.126.443-2.223.84-3.278 1.352-1.051.51-2.093 1.029-3.088 1.64-2.017 1.179-3.925 2.528-5.87 3.939Z"
          class="gray-fill"
        />
        <path
          d="M128.477 293.872s1.915-3.496 6.042-2.674"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M128.477 293.872c.127-.614.452-1.167.862-1.64.411-.48.941-.858 1.523-1.131.586-.257 1.227-.415 1.865-.382a3.71 3.71 0 0 1 1.792.479c-.619.113-1.181.158-1.718.297a7.15 7.15 0 0 0-1.525.516c-.984.429-1.853 1.08-2.799 1.861Z"
          class="gray-fill"
        />
        <path
          d="M126.373 324.85s7.929 26.558 3.592 33.88c-4.336 7.322-11.527 9.761-11.527 9.761"
          class="gray-stroke"
        />
        <path
          d="M89.054 306.64c-1.728.366-1.422-.657-3.239-2.52.489.608 2.929-.596 3.097-1.357.169-.762.267-1.525-.198-2.151-1.538-2.075-3.595-2.849-4.93-2.899-1.035.366-2.545 1.432-.667 4.139"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M89.054 306.64c-.344.133-.731.253-1.151.122-.424-.136-.702-.475-.94-.752l-.71-.829c-.235-.269-.498-.508-.748-.758l.648-.574c-.072-.047.102.024.291-.025a2.89 2.89 0 0 0 .604-.186c.404-.174.81-.408 1.125-.675.153-.125.279-.3.265-.322a5.51 5.51 0 0 0 .105-.668c.035-.43-.014-.786-.219-1.07-.556-.738-1.21-1.411-1.991-1.907-.775-.495-1.656-.868-2.562-.951l.123-.019c-.736.211-1.402.715-1.472 1.498-.083.781.3 1.591.695 2.328-.53-.65-1.037-1.405-1.079-2.344a1.867 1.867 0 0 1 .478-1.32c.318-.368.732-.61 1.157-.788l.048-.02.076.001c2.137.048 4.063 1.289 5.309 2.944.394.513.494 1.198.426 1.739a6.188 6.188 0 0 1-.14.787c-.128.466-.383.651-.591.847-.44.366-.909.611-1.416.802a3.777 3.777 0 0 1-.814.211c-.279.01-.676.11-1.094-.319l.649-.574c.51.571.945 1.223 1.298 1.868.19.308.337.628.604.789.268.17.656.173 1.026.165Z"
          class="gray-fill"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 140.528H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 140.528H237v24.765h-7.658z"
        />
        <path
          d="M237 140.528v24.765"
          class="no-fill"
        />
        <path
          d="M237 140.528v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 165.293v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 165.293v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 143.56h-1.752"
          class="no-fill"
        />
        <path
          d="M237 143.56h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 149.799h-1.752"
          class="no-fill"
        />
        <path
          d="M237 149.799h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 155.902h-1.752"
          class="no-fill"
        />
        <path
          d="M237 155.902h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 165.293h-4.429"
          class="no-fill"
        />
        <path
          d="M237 165.293h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 152.851h-3.528"
          class="no-fill"
        />
        <path
          d="M237 152.851h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 146.68h-3.528"
          class="no-fill"
        />
        <path
          d="M237 146.68h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 158.942h-3.528"
          class="no-fill"
        />
        <path
          d="M237 158.942h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 161.982h-1.752"
          class="no-fill"
        />
        <path
          d="M237 161.982h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 165.634H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 165.634H237v24.765h-7.658z"
        />
        <path
          d="M237 165.634v24.765"
          class="no-fill"
        />
        <path
          d="M237 165.634v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 190.399v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 190.399v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 168.666h-1.752"
          class="no-fill"
        />
        <path
          d="M237 168.666h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 174.905h-1.752"
          class="no-fill"
        />
        <path
          d="M237 174.905h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 181.008h-1.752"
          class="no-fill"
        />
        <path
          d="M237 181.008h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 190.399h-4.429"
          class="no-fill"
        />
        <path
          d="M237 190.399h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 177.956h-3.528"
          class="no-fill"
        />
        <path
          d="M237 177.956h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 171.786h-3.528"
          class="no-fill"
        />
        <path
          d="M237 171.786h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 184.048h-3.528"
          class="no-fill"
        />
        <path
          d="M237 184.048h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 187.088h-1.752"
          class="no-fill"
        />
        <path
          d="M237 187.088h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 190.74H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 190.74H237v24.765h-7.658z"
        />
        <path
          d="M237 190.74v24.765"
          class="no-fill"
        />
        <path
          d="M237 190.74v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 215.505V190.74"
          class="no-fill"
        />
        <path
          d="M229.342 215.505V190.74"
          class="gray-stroke"
        />
        <path
          d="M237 193.772h-1.752"
          class="no-fill"
        />
        <path
          d="M237 193.772h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 200.011h-1.752"
          class="no-fill"
        />
        <path
          d="M237 200.011h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 206.114h-1.752"
          class="no-fill"
        />
        <path
          d="M237 206.114h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 215.505h-4.429"
          class="no-fill"
        />
        <path
          d="M237 215.505h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 203.062h-3.528"
          class="no-fill"
        />
        <path
          d="M237 203.062h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 196.892h-3.528"
          class="no-fill"
        />
        <path
          d="M237 196.892h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 209.154h-3.528"
          class="no-fill"
        />
        <path
          d="M237 209.154h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 212.194h-1.752"
          class="no-fill"
        />
        <path
          d="M237 212.194h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 215.846H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 215.846H237v24.765h-7.658z"
        />
        <path
          d="M237 215.846v24.765"
          class="no-fill"
        />
        <path
          d="M237 215.846v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 240.611v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 240.611v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 218.878h-1.752"
          class="no-fill"
        />
        <path
          d="M237 218.878h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 225.117h-1.752"
          class="no-fill"
        />
        <path
          d="M237 225.117h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 231.22h-1.752"
          class="no-fill"
        />
        <path
          d="M237 231.22h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 240.611h-4.429"
          class="no-fill"
        />
        <path
          d="M237 240.611h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 228.168h-3.528"
          class="no-fill"
        />
        <path
          d="M237 228.168h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 221.997h-3.528"
          class="no-fill"
        />
        <path
          d="M237 221.997h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 234.26h-3.528"
          class="no-fill"
        />
        <path
          d="M237 234.26h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 237.3h-1.752"
          class="no-fill"
        />
        <path
          d="M237 237.3h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 240.951H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 240.951H237v24.766h-7.658z"
        />
        <path
          d="M237 240.951v24.766"
          class="no-fill"
        />
        <path
          d="M237 240.951v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 265.717v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 265.717v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 243.984h-1.752"
          class="no-fill"
        />
        <path
          d="M237 243.984h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 250.223h-1.752"
          class="no-fill"
        />
        <path
          d="M237 250.223h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 256.326h-1.752"
          class="no-fill"
        />
        <path
          d="M237 256.326h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 265.717h-4.429"
          class="no-fill"
        />
        <path
          d="M237 265.717h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 253.274h-3.528"
          class="no-fill"
        />
        <path
          d="M237 253.274h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 247.103h-3.528"
          class="no-fill"
        />
        <path
          d="M237 247.103h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 259.366h-3.528"
          class="no-fill"
        />
        <path
          d="M237 259.366h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 262.405h-1.752"
          class="no-fill"
        />
        <path
          d="M237 262.405h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 266.057H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 266.057H237v24.766h-7.658z"
        />
        <path
          d="M237 266.057v24.766"
          class="no-fill"
        />
        <path
          d="M237 266.057v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 290.823v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 290.823v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 269.09h-1.752"
          class="no-fill"
        />
        <path
          d="M237 269.09h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 275.329h-1.752"
          class="no-fill"
        />
        <path
          d="M237 275.329h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 281.432h-1.752"
          class="no-fill"
        />
        <path
          d="M237 281.432h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 290.823h-4.429"
          class="no-fill"
        />
        <path
          d="M237 290.823h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 278.38h-3.528"
          class="no-fill"
        />
        <path
          d="M237 278.38h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 272.209h-3.528"
          class="no-fill"
        />
        <path
          d="M237 272.209h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 284.472h-3.528"
          class="no-fill"
        />
        <path
          d="M237 284.472h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 287.511h-1.752"
          class="no-fill"
        />
        <path
          d="M237 287.511h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 291.163H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 291.163H237v24.766h-7.658z"
        />
        <path
          d="M237 291.163v24.766"
          class="no-fill"
        />
        <path
          d="M237 291.163v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 315.929v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 315.929v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 294.196h-1.752"
          class="no-fill"
        />
        <path
          d="M237 294.196h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 300.435h-1.752"
          class="no-fill"
        />
        <path
          d="M237 300.435h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 306.538h-1.752"
          class="no-fill"
        />
        <path
          d="M237 306.538h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 315.929h-4.429"
          class="no-fill"
        />
        <path
          d="M237 315.929h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 303.486h-3.528"
          class="no-fill"
        />
        <path
          d="M237 303.486h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 297.315h-3.528"
          class="no-fill"
        />
        <path
          d="M237 297.315h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 309.578h-3.528"
          class="no-fill"
        />
        <path
          d="M237 309.578h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 312.617h-1.752"
          class="no-fill"
        />
        <path
          d="M237 312.617h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 316.269H237v24.766h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 316.269H237v24.766h-7.658z"
        />
        <path
          d="M237 316.269v24.766"
          class="no-fill"
        />
        <path
          d="M237 316.269v24.766"
          class="gray-stroke"
        />
        <path
          d="M229.342 341.035v-24.766"
          class="no-fill"
        />
        <path
          d="M229.342 341.035v-24.766"
          class="gray-stroke"
        />
        <path
          d="M237 319.302h-1.752"
          class="no-fill"
        />
        <path
          d="M237 319.302h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 325.54h-1.752"
          class="no-fill"
        />
        <path
          d="M237 325.54h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 331.643h-1.752"
          class="no-fill"
        />
        <path
          d="M237 331.643h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 341.035h-4.429"
          class="no-fill"
        />
        <path
          d="M237 341.035h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 328.592h-3.528"
          class="no-fill"
        />
        <path
          d="M237 328.592h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 322.421h-3.528"
          class="no-fill"
        />
        <path
          d="M237 322.421h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 334.683h-3.528"
          class="no-fill"
        />
        <path
          d="M237 334.683h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 337.723h-1.752"
          class="no-fill"
        />
        <path
          d="M237 337.723h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 341.375H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 341.375H237v24.765h-7.658z"
        />
        <path
          d="M237 341.375v24.765"
          class="no-fill"
        />
        <path
          d="M237 341.375v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 366.14v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 366.14v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 344.408h-1.752"
          class="no-fill"
        />
        <path
          d="M237 344.408h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 350.646h-1.752"
          class="no-fill"
        />
        <path
          d="M237 350.646h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 356.749h-1.752"
          class="no-fill"
        />
        <path
          d="M237 356.749h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 366.14h-4.429"
          class="no-fill"
        />
        <path
          d="M237 366.14h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 353.698h-3.528"
          class="no-fill"
        />
        <path
          d="M237 353.698h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 347.527h-3.528"
          class="no-fill"
        />
        <path
          d="M237 347.527h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 359.789h-3.528"
          class="no-fill"
        />
        <path
          d="M237 359.789h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 362.829h-1.752"
          class="no-fill"
        />
        <path
          d="M237 362.829h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 366.481H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 366.481H237v24.765h-7.658z"
        />
        <path
          d="M237 366.481v24.765"
          class="no-fill"
        />
        <path
          d="M237 366.481v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 391.246v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 391.246v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 369.514h-1.752"
          class="no-fill"
        />
        <path
          d="M237 369.514h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 375.752h-1.752"
          class="no-fill"
        />
        <path
          d="M237 375.752h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 381.855h-1.752"
          class="no-fill"
        />
        <path
          d="M237 381.855h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 391.246h-4.429"
          class="no-fill"
        />
        <path
          d="M237 391.246h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 378.804h-3.528"
          class="no-fill"
        />
        <path
          d="M237 378.804h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 372.633h-3.528"
          class="no-fill"
        />
        <path
          d="M237 372.633h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 384.895h-3.528"
          class="no-fill"
        />
        <path
          d="M237 384.895h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 387.935h-1.752"
          class="no-fill"
        />
        <path
          d="M237 387.935h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 391.587H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 391.587H237v24.765h-7.658z"
        />
        <path
          d="M237 391.587v24.765"
          class="no-fill"
        />
        <path
          d="M237 391.587v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 416.352v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 416.352v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 394.619h-1.752"
          class="no-fill"
        />
        <path
          d="M237 394.619h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 400.858h-1.752"
          class="no-fill"
        />
        <path
          d="M237 400.858h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 406.961h-1.752"
          class="no-fill"
        />
        <path
          d="M237 406.961h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 416.352h-4.429"
          class="no-fill"
        />
        <path
          d="M237 416.352h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 403.91h-3.528"
          class="no-fill"
        />
        <path
          d="M237 403.91h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 397.739h-3.528"
          class="no-fill"
        />
        <path
          d="M237 397.739h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 410.001h-3.528"
          class="no-fill"
        />
        <path
          d="M237 410.001h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 413.041h-1.752"
          class="no-fill"
        />
        <path
          d="M237 413.041h-1.752"
          class="gray-stroke"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M229.342 416.693H237v24.765h-7.658z"
        />
        <path
          class="gray-stroke"
          d="M229.342 416.693H237v24.765h-7.658z"
        />
        <path
          d="M237 416.693v24.765"
          class="no-fill"
        />
        <path
          d="M237 416.693v24.765"
          class="gray-stroke"
        />
        <path
          d="M229.342 441.458v-24.765"
          class="no-fill"
        />
        <path
          d="M229.342 441.458v-24.765"
          class="gray-stroke"
        />
        <path
          d="M237 419.725h-1.752"
          class="no-fill"
        />
        <path
          d="M237 419.725h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 425.964h-1.752"
          class="no-fill"
        />
        <path
          d="M237 425.964h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 432.067h-1.752"
          class="no-fill"
        />
        <path
          d="M237 432.067h-1.752"
          class="gray-stroke"
        />
        <path
          d="M237 441.458h-4.429"
          class="no-fill"
        />
        <path
          d="M237 441.458h-4.429"
          class="gray-stroke"
        />
        <path
          d="M237 429.016h-3.528"
          class="no-fill"
        />
        <path
          d="M237 429.016h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 422.845h-3.528"
          class="no-fill"
        />
        <path
          d="M237 422.845h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 435.107h-3.528"
          class="no-fill"
        />
        <path
          d="M237 435.107h-3.528"
          class="gray-stroke"
        />
        <path
          d="M237 438.147h-1.752"
          class="no-fill"
        />
        <path
          d="M237 438.147h-1.752"
          class="gray-stroke"
        />
        <path
          d="M154.38 253.578h.26c-.15-.649-.26 0-.26 0ZM166.663 305.376c2.869-6.838-.069-9.673-.069-9.673s4.507-26.063 4.334-32.329c-.242-8.791-1.08-11.627-1.08-11.627H156.64c.162.698.373 4.728.549 10.869.301 10.461 1.347 19.669-.093 30.378-.732 5.447-2.716 5.33-3.815 9.271-.184.659-.955 4.732-.966 5.463-.999 3.072-.963 3.68-.337 6.79.183.915 3.244-.353 3.244-4.908 1.646 2.575 2.128 7.488 4.93 8.149 6.714 1.585 5.657 0 6.511-.984 1.343-1.546.163-3.027-.458-4.265-.399-.797.88 1.515 1.83.762 1.677-1.327-2.317-5.643-1.372-7.896Z"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M174.195 251.747h-18.973s1.322-17.553 4.168-25.127c2.846-7.575 14.805-13.408 14.805-13.408v38.535Z"
          style="fill:#1d4b6d;fill-rule:nonzero"
        />
        <path
          d="M164.832 337.171s8.005 23.333 8.085 28.02c0 0-.305 1.663.915 6.658 0 0 5.187 43.794 4.272 48.813-.915 5.02-7.452 6.134-10.373 8.583-2.921 2.449-3.868 2.705-8.341 4.841-4.473 2.136-8.439 1.525-8.134 5.186.305 3.662 8.542 2.746 14.949 2.746s8.848-.61 13.729-1.22c4.882-.611 6.712 1.22 11.746-.915 5.034-2.136-3.622-14.006-3.107-19.054.513-5.048 2.064-15.996 3.178-27.596 1.114-11.601-1.874-14.452-2.58-21.164-.706-6.712 2.509-34.898 2.509-34.898"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M214.997 336.908s-6.104 25.724-5.677 32.46c.426 6.735 4.775 11.42 3.18 22.964-1.594 11.544-3.575 24.126-4.299 29.148-.722 5.022 7.509 17.173 2.391 19.098-5.118 1.925-6.688 1.735-11.607 1.735-5.726 0-7.61-.325-14.011-.591-6.401-.266-14.67 1.306-14.822-2.365-.153-3.67 3.784-3.896 8.341-5.843 4.558-1.949 5.515-2.165 8.536-4.491 3.02-2.326 8.066-3.064 9.189-8.041 1.123-4.978-4.451-49.308-4.451-49.308-1.012-5.04-.727-6.593-.727-6.593.116-4.687-3.083-28.173-3.083-28.173s21.33-6.437 27.04 0Z"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M180.816 209.68a13.446 13.446 0 0 0-6.621 3.532c-3.692 3.587-8.004 10.058-9.142 13.408-1.627 4.788-5.393 13.44-4.848 23.093.643 11.39-.392 32.488.675 41.36 0 0 37.664-.08 46.756-.08 0 0-1.797-24.923 6.134-40.681 4.5-8.941 6.204-19.17 2.95-26.667-.846-1.946-4.117-7.483-6.237-8.583-5.533-2.868-10.718-6.717-16.922-6.717-5.188 0-9.72.703-12.745 1.335Z"
          style="fill:#66b1dd;fill-rule:nonzero"
        />
        <path
          d="M207.597 290.993c6.134 8.135 7.574 16.483 7.361 20.228-.212 3.745 0 25.687 0 25.687-7.728 2.893-26.425.263-26.425.263-14.782 3.38-23.739 0-23.739 0s-1.002-9.211-3.057-18.495c-2.901-13.102-.895-27.603-.895-27.603l46.755-.08Z"
          style="fill:#2d5a84;fill-rule:nonzero"
        />
        <path
          d="M210.483 215.062s7.296 2.923 12.366 12.217c2.936 5.384 4.221 24.468 4.221 24.468-8.767 2.126-14.649 1.946-23.797 0 0 0-2.351-24.925 0-30.157 2.351-5.233 7.21-6.528 7.21-6.528Z"
          style="fill:#275579;fill-rule:nonzero"
        />
        <path
          d="M208.57 251.747s2.35 16.673 2.108 25.464c-.173 6.266 0 16.661 0 16.661s-5.422 4.146-5.526 11.178c-.111 7.463-1.06 6.494-.202 7.638.763 1.015 3.357-.071 3.126-3.11.624-1.275 1.177-2.585 1.568-3.248 1.002-1.702 1.668 1.993 1.034 4.35-.634 2.356-7.372 6.048-6.55 8.541.309.934 3.721.916 3.721.916s.531.463 1.741.305c1.108-.144 3.903-1.14 3.903-1.14s3.155-2.443 3.509-3.373c2.621-6.897 2.618-10.067 1.405-14.707-1.295-4.962-1.233-4.839.186-10.149 2.024-7.577 4.869-12.344 6.697-22.648 1.421-8.01 0-16.678 0-16.678-5.611-2.094-11.182-1.972-16.72 0Z"
          style="fill:#d8d9d8;fill-rule:nonzero"
        />
        <path
          d="M188.111 336.785c-.004 1.437.139 2.946.269 4.427.137 1.488.301 2.975.466 4.463.338 2.974.705 5.947 1.095 8.917a506.967 506.967 0 0 1-1.59-8.847 241.715 241.715 0 0 1-.715-4.443c-.214-1.492-.442-2.959-.525-4.517h1ZM190.316 427.024c-.693.689-1.41 1.095-2.139 1.544-.729.431-1.468.833-2.212 1.221a120.404 120.404 0 0 1-4.505 2.217 268.74 268.74 0 0 1-9.157 4.072l-.013.004-.014.004c-.585.134-1.168.469-1.505.982-.342.512-.37 1.189-.43 1.817-.04.632.024 1.295.357 1.842.33.554.936.881 1.551 1.05-.619-.148-1.245-.455-1.604-1.016-.364-.553-.451-1.236-.433-1.881.043-.637.036-1.32.394-1.916.358-.583.96-.963 1.617-1.139l-.027.008a267.287 267.287 0 0 0 8.984-4.395 119.3 119.3 0 0 0 4.393-2.365c.722-.41 1.434-.833 2.125-1.278.677-.441 1.396-.921 1.869-1.433l.749.662Z"
          class="gray-fill"
        />
        <path
          d="M187.079 337.252c-.598-4.083-.989-8.172-1.34-12.265a481.355 481.355 0 0 1-.87-12.288l.1.13a55.998 55.998 0 0 1-4.835-2.066c-.785-.391-1.561-.8-2.307-1.263-.726-.477-1.482-.978-1.961-1.72.499.727 1.264 1.201 2 1.656.754.44 1.54.825 2.332 1.191a55.736 55.736 0 0 0 4.87 1.92l.091.032.008.099c.355 4.087.758 8.17 1.218 12.245.467 4.072.974 8.145 1.681 12.166l-.987.163Z"
          style="fill:#231f20;fill-rule:nonzero"
        />
        <path
          d="m173.884 197.365 1.129 11.994c-1.279 3.782.551 8.755 5.485 8.755 5.523 0 10.129-2.338 12.597-4.057 3.805-2.651 5.495-5.174 5.495-5.174-1.402-5.54-1.847-15.992 1.552-22.213 3.083-5.643-25.905 5.057-26.258 10.695Z"
          style="fill:#d3d3d3;fill-rule:nonzero"
        />
        <clipPath id="a">
          <path
            d="m173.884 197.365 1.129 11.994c-1.279 3.782.551 8.755 5.485 8.755 5.523 0 10.129-2.338 12.597-4.057 3.805-2.651 5.495-5.174 5.495-5.174-1.402-5.54-1.847-15.992 1.552-22.213 3.083-5.643-25.905 5.057-26.258 10.695Z"
            clip-rule="nonzero"
          />
        </clipPath>
        <g clip-path="url(#a)">
          <path
            d="M171.164 197.637c-1.539-5.707 27.323-19.839 30.855-15.381-1.016.948-4.796 10.755-8.157 19.23-2.355 5.94-5.322 5.581-9.218 7.441-2.449 1.17-8.075 3.674-9.631.432-1.557-3.242-2.817-10.791-3.849-11.722Z"
            style="fill:#b4b4b4;fill-rule:nonzero"
          />
        </g>
        <path
          d="M210.157 170.416c3.15-17.32 3.361-27.31-29.322-27.31-18.096 0-21.55 10.943-21.55 26.03 0 3.849 1.951 4.053 2.018 7.395.027 1.372-1.846 6.013-2.018 8.738-.278 4.401.696 9.01 6.34 12.659 1.126.727 2.723 3.655 6.386 3.655 2.154 0 5.444.319 7.856-2.466 1.588.391 4.423.189 6.03-.082 8.465-1.429 12.08-5.477 12.779-10.799 3.685-1.007 9.096-4.697 11.481-17.82Z"
          style="fill:#d3d3d3;fill-rule:nonzero"
        />
        <path
          d="M214.634 161.652c0-23.433-24.46-23.793-34.413-22.649-8.677.998-13.851 4.824-15.753 7.301-2.309 3.007-3.094 7.86-3.094 7.86s2.235-4.6 3.949-4.863c1.716-.262 3.263 1.191 6.744 1.191 3.479 0 9.371-2.008 9.371-2.008 7.673-.551 15.954 3.52 19.411 10.393.086.171.171.345.255.521 2.531 5.326 2.35 5.426-2.109 14.663-.948 1.966.868 1.511.868 1.511s1.283-1.234 3.051-1.523c3.052-.499 6.788 4.972 2.152 9.609 0 0 9.568-5.795 9.568-22.006Z"
          style="fill:#154262;fill-rule:nonzero"
        />
        <path
          d="M165.93 192.789c.988.975 1.595.978 2.573.653.987.514 2.581.492 3.215.334.638-.151 1.462-1.401 2.167-1.617-.261.378-.95 1.883-1.593 2.327-.387.268-.758.499-1.006.633-.661.351-1.719.547-2.495.547-.774-.001-1.54-.753-2.201-1.105-.66-.356-.241-1.167-.66-1.772ZM168.957 172.482c-3.052-2.509-7.925-.917-7.925-.917l-.402-1.321s4.861-1.174 6.324-1.105c2.205.104 2.003 3.343 2.003 3.343Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
        <path
          d="M178.886 173.723c1.6 0 2.74.127 5.285 2.808 0 0-.611.154-1.452.341-1.276.283-3.082.642-4.086.653-1.667.018-2.173-.539-2.173-.539-.612-.267-.896.46-.896 0 0-1.39 1.722-3.263 3.322-3.263Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <clipPath id="b">
          <path
            d="M178.886 173.723c1.6 0 2.74.127 5.285 2.808 0 0-.611.154-1.452.341-1.276.283-3.082.642-4.086.653-1.667.018-2.173-.539-2.173-.539-.612-.267-.896.46-.896 0 0-1.39 1.722-3.263 3.322-3.263Z"
            clip-rule="nonzero"
          />
        </clipPath>
        <g clip-path="url(#b)">
          <path
            d="M181.3 175.744a1.811 1.811 0 0 1-1.8 1.821c-.994 0-1.8-.816-1.8-1.821a1.81 1.81 0 0 1 1.8-1.821c.994 0 1.8.815 1.8 1.821Z"
            class="gray-fill"
          />
        </g>
        <path
          d="M178.886 173.723c1.6 0 2.74.127 5.285 2.808 0 0-.611.154-1.452.341-1.276.283-3.082.642-4.086.653-1.667.018-2.173-.539-2.173-.539-.612-.267-.896.46-.896 0 0-1.39 1.722-3.263 3.322-3.263Z"
          style="fill:none;stroke:#7c7c7c;stroke-width:.28px"
        />
        <path
          d="M176.802 174.28c-1.46 1.114-1.193 2.353-1.193 2.353s.499-2.21 2.652-2.363c3.365-.237 5.4 1.636 5.91 2.261l.473-.907s-4.931-3.567-7.842-1.344Z"
          class="gray-fill"
        />
        <path
          d="M166.003 173.723c-1.283 0-2.47.179-3.437 2.808 0 0 1.587.994 3.475.994.557 0 .874-.091 1.479-.42.491-.267.816.073.848-.386.135-1.985-.831-2.996-2.365-2.996Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <clipPath id="c">
          <path
            d="M166.003 173.723c-1.283 0-2.47.179-3.437 2.808 0 0 1.587.994 3.475.994.557 0 .874-.091 1.479-.42.491-.267.816.073.848-.386.135-1.985-.831-2.996-2.365-2.996Z"
            clip-rule="nonzero"
          />
        </clipPath>
        <g clip-path="url(#c)">
          <path
            d="M164.448 175.724c0 .931.719 1.687 1.606 1.687.887 0 1.605-.756 1.605-1.687 0-.932-.718-1.686-1.605-1.686s-1.606.754-1.606 1.686Z"
            class="gray-fill"
          />
        </g>
        <path
          d="M166.003 173.723c-1.283 0-2.47.179-3.437 2.808 0 0 1.587.994 3.475.994.557 0 .874-.091 1.479-.42.491-.267.816.073.848-.386.135-1.985-.831-2.996-2.365-2.996Z"
          style="fill:none;stroke:#7c7c7c;stroke-width:.25px"
        />
        <path
          d="M167.676 174.28c1.172 1.114.756 1.732.756 1.732s-.534-1.762-2.262-1.941c-1.506-.157-3.194 1.835-3.604 2.46l-.422-.907s3.195-3.567 5.532-1.344Z"
          class="gray-fill"
        />
        <path
          d="M164.149 186.059s.257 1.013 1.584.956c1.325-.057 3.764-.551 5.514.003 1.751.553 2.241-.116 2.482-.459.623-.887-.824-3.249-.824-3.249s.544 2.327-.595 2.749c-.874.323-2.564-.445-3.807-.79-.799-.221-1.857-.573-2.658-.356l-1.696 1.146ZM182.999 170.297c-1.79-.817-6.915-1.171-8.647-1.161-3.362.02-1.916 8.848-1.916 8.848s.763-3.389 1.023-4.434c.426-1.712 1.233-2.427 3.15-2.466 1.917-.04 9.072 1.297 9.072 1.297s-.902-1.273-2.682-2.084ZM175.244 187.854c-.793 1.077-1.442 1.951-1.442 1.951s-4.262-.181-4.593-.266l-1.284.398-1.114-.398s-.455.186-1.43.41c-.472.109-.567-1.186-.477-1.332-.079.129-.752.815-.745 1.774.007.959.977 2.156.949 2.253.098.002-.141-1.799.273-1.988.415-.188 1.026.299 1.333.351.558.093 1.256.289 2.157.3 4.147.047 4.778-.69 5.5-.952l2.791 3.888c-.729-2.085-2.438-4.857-1.918-6.389ZM205.066 177.984s.018-2.787-1.756-2.672c-.76.05-2.612.373-3.4 2.352-.997 2.51 1.253 1.593 1.253 2.894 0 1.301.307 1.002-.953 2.672 0 0 2.83-1.536 3.03-2.482.313-1.491-3.175-.858-1.057-3.126 1.263-1.351 2.883.362 2.883.362Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
        <path
          style="fill:#fff;fill-rule:nonzero"
          d="M130.07 442.313h114.406v6.803H130.07z"
        />
        <path
          class="gray-stroke"
          d="M130.07 442.313h114.406v6.803H130.07z"
        />
        <path
          d="M207.636 442.313v6.817"
          class="no-fill"
        />
        <path
          d="M207.636 442.313v6.817"
          class="gray-stroke"
        />
        <path
          d="M253.362 419.129h-8.042l-29.462 29.463h7.591"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M253.362 419.129h-8.042l-29.462 29.463h7.591"
          class="gray-stroke"
        />
        <path
          d="m253.362 419.129-29.913 29.912h29.968l-.055-29.912Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m253.362 419.129-29.913 29.912h29.968l-.055-29.912Z"
          class="gray-stroke"
        />
        <path
          d="M189.137 414.611s4.882 1.526 7.729 1.628c2.848.101 5.768-1.209 4.948 1.591-.82 2.81-9.4 1.46-13 .78 1.95 1.43 8.896 2.621 10.33 2.54 1.434-.081 2.705 2.004-.04 2.563-3.875.789-8.39-.373-10.34-1.093l-.04.11c.11.11 1.09 1.05 3.71 2.21 2.75 1.22 3.334.978 4.011 1.63.675.652.269 2.14-1.261 1.94-5.8-.77-6.37-2.36-10.17-2.97-3.08-.49-12.33-2.98-15.81-8.65-2.39-3.89-43.04-11.09-49.42-21.55l-23.91-16.04-.24-.05c4.03-18.66 14.91-20.52 14.91-20.52l-.59-1.1c.41.49 13.63 16.1 19.35 21.05 0 0 11.4 7.36 16.82 11.62 5.42 4.27 20.13 12.28 26.821 13.87 0 0 5.699-2.27 10.799-2.27s9.32-.73 8.69 1.5c-.64 2.23-6.578.348-8.34 3.01-.815 1.231-1.069 2.46 1.601 3.5 3.689 1.45 9.222 2.392 13.052 1.752 3.821-.64 3.339 2.152.789 2.792-1.59.4-6.999.097-10.399.157Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M189.137 414.611s4.882 1.526 7.729 1.628c2.848.101 5.768-1.209 4.948 1.591-.82 2.81-9.4 1.46-13 .78 1.95 1.43 8.896 2.621 10.33 2.54 1.434-.081 2.705 2.004-.04 2.563-3.875.789-8.39-.373-10.34-1.093l-.04.11c.11.11 1.09 1.05 3.71 2.21 2.75 1.22 3.334.978 4.011 1.63.675.652.269 2.14-1.261 1.94-5.8-.77-6.37-2.36-10.17-2.97-3.08-.49-12.33-2.98-15.81-8.65-2.39-3.89-43.04-11.09-49.42-21.55l-23.91-16.04-.24-.05c4.03-18.66 14.91-20.52 14.91-20.52l-.59-1.1c.41.49 13.63 16.1 19.35 21.05 0 0 11.4 7.36 16.82 11.62 5.42 4.27 20.13 12.28 26.821 13.87 0 0 5.699-2.27 10.799-2.27s9.32-.73 8.69 1.5c-.64 2.23-6.578.348-8.34 3.01-.815 1.231-1.069 2.46 1.601 3.5 3.689 1.45 9.222 2.392 13.052 1.752 3.821-.64 3.339 2.152.789 2.792-1.59.4-6.999.097-10.399.157Z"
          class="gray-stroke"
        />
        <path
          d="m109.954 357.63-.009-.01"
          class="no-fill"
        />
        <path
          d="m109.954 357.63-.009-.01"
          style="fill:none;stroke:#d3d3d3;stroke-width:1px"
        />
        <path
          d="M198.994 359.45h-.009"
          style="fill:none;stroke:#e0e6eb;stroke-width:1px"
        />
        <path
          d="M195.267 358.237c.329-.008.649.042.974.068.322.043.641.101.958.168.317.072.632.147.945.237l.468.14c.152.065.336-.024.379.563h-.006l.009.037-.009.037h-.009c-.273.527-.405.377-.57.383l-.482-.04c-.32-.03-.638-.075-.955-.123-.315-.053-.63-.115-.941-.192-.309-.094-.623-.164-.926-.292l.165-.986Z"
          style="fill:#e3e9ee;fill-rule:nonzero"
        />
        <path
          d="M210.484 369.48c-.89 1.16-1.879.61-4.72-.05-4.1-.94-9.239-1.51-9.239-1.51v.01c3.399 1.16 12.069 4.28 11.939 5.75-.16 1.83-3.12 1.37-6.24.3-13.15-4.5-14.05-.91-14.05-.91s2.24 1.52 5.08 3.2c3.87 2.29.87 4.43-1.42 3.03-2.35-1.43-5.99-3.84-9-6.08-5.04-3.74-7.479-12.98-7.479-12.98s-20.441-9.24-27.761-16.36c-6.319-2.37-14.739-6.46-15.22-6.7 11.62-6.35 13.85-15.07 13.85-15.07l6.95 6.52c6.62 3.46 18.34 15.24 26.61 24 0 0 8.79 1.02 15.4 3.66 5.7 0 7.16-.35 11.27.54 2.66.57-.28 1.9-.28 1.9l-7.18.72c4.63.95 11.8 2.66 11.48 4.16-.36 1.66-5.119.81-8.25 0-4.07-1.05-5.699-.3-5.699-.3s15.5 4.16 13.959 6.17Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M210.484 369.48c-.89 1.16-1.879.61-4.72-.05-4.1-.94-9.239-1.51-9.239-1.51v.01c3.399 1.16 12.069 4.28 11.939 5.75-.16 1.83-3.12 1.37-6.24.3-13.15-4.5-14.05-.91-14.05-.91s2.24 1.52 5.08 3.2c3.87 2.29.87 4.43-1.42 3.03-2.35-1.43-5.99-3.84-9-6.08-5.04-3.74-7.479-12.98-7.479-12.98s-20.441-9.24-27.761-16.36c-6.319-2.37-14.739-6.46-15.22-6.7 11.62-6.35 13.85-15.07 13.85-15.07l6.95 6.52c6.62 3.46 18.34 15.24 26.61 24 0 0 8.79 1.02 15.4 3.66 5.7 0 7.16-.35 11.27.54 2.66.57-.28 1.9-.28 1.9l-7.18.72c4.63.95 11.8 2.66 11.48 4.16-.36 1.66-5.119.81-8.25 0-4.07-1.05-5.699-.3-5.699-.3s15.5 4.16 13.959 6.17Z"
          class="gray-stroke"
        />
        <path
          d="M196.525 367.93c-.83-.28-1.341-.45-1.341-.45"
          style="fill:none;stroke:#e3e9ee;stroke-width:1px"
        />
        <path
          d="M192.71 121.75s-7.223 1.539-10.274 2.454c-3.051.915-10.701 2.958-10.69 5.187.012 2.228-.752 2.279 2.638 2.621 3.389.342 29.617 0 32.703 0 3.086 0 6.134.933 6.134.933 6.209 1.417 16.192 7.045 33.487 20.828l19.016-16.45c-10.323-3.87-29.808-8.635-55.79-14.034 0 0-10.253-3.079-17.224-1.539Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M192.71 121.75s-7.223 1.539-10.274 2.454c-3.051.915-10.701 2.958-10.69 5.187.012 2.228-.752 2.279 2.638 2.621 3.389.342 29.617 0 32.703 0 3.086 0 6.134.933 6.134.933 6.209 1.417 16.192 7.045 33.487 20.828l19.016-16.45c-10.323-3.87-29.808-8.635-55.79-14.034 0 0-10.253-3.079-17.224-1.539Z"
          class="gray-stroke"
        />
        <path
          d="M244.476 262.352s10.102 39.904 9.492 46.327c-.61 6.423-1.073 24.119 1.649 26.865 2.724 2.745 16.317 8.66 37.978 1.847 21.661-6.813 40.525-17.548 42.355-20.893 1.831-3.344-12.188-61.802-14.019-71.259-1.83-9.458-1.354-16.17-4.404-21.966-3.051-5.797-10.198-24.014-17.76-31.685l-10.308 15.743 1.526 3.332s-3.966 5.966-8.542 7.458c-4.577 1.491-17.289 0-17.289 0l-13.525 6.677-23.492-.667s-3.814 2.498-5.085 15.419c-.959 9.749 4.17 19.113 8.441 20.943 4.272 1.831 30.944 3.051 40.489 0"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M244.476 262.352s10.102 39.904 9.492 46.327c-.61 6.423-1.073 24.119 1.649 26.865 2.724 2.745 16.317 8.66 37.978 1.847 21.661-6.813 40.525-17.548 42.355-20.893 1.831-3.344-12.188-61.802-14.019-71.259-1.83-9.458-1.354-16.17-4.404-21.966-3.051-5.797-10.198-24.014-17.76-31.685l-10.308 15.743 1.526 3.332s-3.966 5.966-8.542 7.458c-4.577 1.491-17.289 0-17.289 0l-13.525 6.677-23.492-.667s-3.814 2.498-5.085 15.419c-.959 9.749 4.17 19.113 8.441 20.943 4.272 1.831 30.944 3.051 40.489 0"
          class="gray-stroke"
        />
        <path
          d="M279.101 415.355s5.985-3.337 3.233-11.837"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M279.101 415.355s5.985-3.337 3.233-11.837"
          class="gray-stroke"
        />
        <path
          d="m279.101 409.696 28.226-53.918"
          class="no-fill"
        />
        <path
          d="M279.101 409.696c1.807-4.779 4.279-9.209 6.584-13.727l6.986-13.516 7.064-13.476 7.15-13.43.885.463-6.963 13.529-7.05 13.482-7.128 13.442c-2.399 4.469-4.632 9.025-7.528 13.233Z"
          class="gray-fill"
        />
        <path
          d="M295.756 361.646s33.095-12.983 35.129-16.746c2.034-3.763 5.065-28.402 5.065-28.402"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M295.756 361.646c2.481-1.426 5.073-2.559 7.683-3.654a617.883 617.883 0 0 0 7.797-3.355c5.178-2.263 10.38-4.526 15.318-7.212 1.202-.691 2.467-1.394 3.433-2.264.108-.108.252-.218.31-.323.08-.1.165-.2.219-.37.115-.31.273-.576.36-.922.208-.656.403-1.321.557-2.008.653-2.728 1.141-5.517 1.611-8.302.924-5.577 1.699-11.188 2.41-16.799l.992.123c-.712 5.627-1.489 11.241-2.417 16.84-.472 2.799-.959 5.595-1.626 8.376a27.904 27.904 0 0 1-.571 2.087c-.083.344-.271.705-.406 1.056-.054.174-.179.358-.319.538-.131.198-.276.304-.416.447-1.14 1.032-2.382 1.71-3.621 2.44-4.996 2.77-10.184 5.066-15.382 7.338a451.11 451.11 0 0 1-7.86 3.252c-2.628 1.063-5.284 2.06-8.072 2.712Z"
          class="gray-fill"
        />
        <path
          d="m303.386 363.307 3.941 40.211.91 11.132"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="m303.386 363.307 3.941 40.211.91 11.132"
          class="gray-stroke"
        />
        <path
          d="M248.792 144.489s-4.316 16.547-4.316 20.615c0 4.067-6.576 11.441-5.356 14.263 1.221 2.821 5.356 4.578 5.356 4.578s1.086 10.849 4.431 15.738c1.936 2.829 6.564 3.926 10.121 4.348 2.777.33 4.87 6.54 5.329 9.298l.797 4.792s11.194 2.235 17.289 0c7.292-2.676 8.542-7.458 8.542-7.458l-1.526-3.332s13.978-21.061 14.384-32.336c.407-11.275 4.394-22.095-5.333-33.638-7.11-8.436-43.932-12.479-49.718 3.132Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M248.792 144.489s-4.316 16.547-4.316 20.615c0 4.067-6.576 11.441-5.356 14.263 1.221 2.821 5.356 4.578 5.356 4.578s1.086 10.849 4.431 15.738c1.936 2.829 6.564 3.926 10.121 4.348 2.777.33 4.87 6.54 5.329 9.298l.797 4.792s11.194 2.235 17.289 0c7.292-2.676 8.542-7.458 8.542-7.458l-1.526-3.332s13.978-21.061 14.384-32.336c.407-11.275 4.394-22.095-5.333-33.638-7.11-8.436-43.932-12.479-49.718 3.132Z"
          class="gray-stroke"
        />
        <path
          d="M255.722 173.232s2.516 4.088-.329 10.713"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M255.722 173.232c.628.717 1.003 1.628 1.239 2.55.241.929.299 1.896.26 2.852-.074.953-.246 1.897-.56 2.792a9.784 9.784 0 0 1-1.268 2.519c.062-.474.175-.92.264-1.37.073-.455.182-.895.248-1.342.148-.89.265-1.773.319-2.659.096-1.773-.005-3.537-.502-5.342Z"
          class="gray-fill"
        />
        <path
          d="M262.086 182.002s-2.016 7.592-8.118 5.529"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M262.57 182.13c-.322.95-.75 1.792-1.271 2.608-.524.805-1.157 1.55-1.923 2.143a5.372 5.372 0 0 1-2.646 1.058c-.955.143-1.887-.132-2.762-.408.901.198 1.832.38 2.725.161a5.009 5.009 0 0 0 2.365-1.196c.654-.609 1.176-1.349 1.595-2.139.413-.786.75-1.651.95-2.483l.967.256Z"
          class="gray-fill"
        />
        <path
          d="M192.71 126.339s-7.418.441-9.869 2.848c-2.45 2.407 0 2.958 0 2.958h13.699"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M192.71 126.339a59.188 59.188 0 0 0-3.502.685 28.622 28.622 0 0 0-3.377.992c-1.079.412-2.132.921-2.824 1.73-.372.393-.69.872-.727 1.223a.44.44 0 0 0 .153.377c.116.111.354.229.527.269l-.119-.014c2.283-.033 4.566-.051 6.85-.036 2.283-.003 4.566.025 6.849.08v1c-2.283.055-4.566.084-6.849.081-2.284.015-4.567-.004-6.85-.037l-.075-.001-.044-.012a2.337 2.337 0 0 1-1.033-.564c-.318-.286-.508-.806-.452-1.237.137-.847.608-1.35 1.035-1.816.925-.976 2.149-1.437 3.296-1.81 1.166-.374 2.363-.567 3.554-.718a25.211 25.211 0 0 1 3.588-.192Z"
          class="gray-fill"
        />
        <path
          d="m209.12 132.467-19.762.249c-6.588.076-13.175.101-19.763.11l-39.525.101.459-.46.041 6.803h-1l.04-6.803.46-.46 39.525.102c6.588.008 13.175.033 19.763.109l19.762.249Z"
          style="fill:#231f20;fill-rule:nonzero"
        />
        <path
          d="M289.459 207.331s14.89 1.106 17.868 4.863"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M289.496 206.833c3.145.347 6.248.859 9.321 1.566 1.53.372 3.05.796 4.529 1.342 1.447.58 2.957 1.24 3.981 2.453-1.086-1.153-2.603-1.719-4.064-2.217-1.49-.462-3.015-.801-4.549-1.088a80.368 80.368 0 0 0-9.292-1.059l.074-.997Z"
          class="gray-fill"
        />
        <path
          d="M255.617 335.544s.182 18.929-.224 22.076c-.407 3.147-22.222 65.03-22.222 74.519 0 9.489-.468 17.171 7.576 16.977 8.045-.193 104.542 0 104.542 0s12.204-2.522 13.018-12.285c.813-9.762-10.984-25.209-18.712-23.181-7.73 2.029-54.393.568-60.494 1.705-6.103 1.138-6.103 2.335-14.238 5.804"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M255.617 335.544s.182 18.929-.224 22.076c-.407 3.147-22.222 65.03-22.222 74.519 0 9.489-.468 17.171 7.576 16.977 8.045-.193 104.542 0 104.542 0s12.204-2.522 13.018-12.285c.813-9.762-10.984-25.209-18.712-23.181-7.73 2.029-54.393.568-60.494 1.705-6.103 1.138-6.103 2.335-14.238 5.804"
          class="gray-stroke"
        />
        <path
          d="M337.469 414.65c-4.598 6.701-7.025 8.412-6.518 15.703.508 7.292 24.882 18.441 36.899 18.441 12.015 0 23.406-.95 23.406-11.134 0-10.184-24.223-11.097-32.909-18.531-8.688-7.435-16.28-11.178-20.878-4.479Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M337.469 414.65c-4.598 6.701-7.025 8.412-6.518 15.703.508 7.292 24.882 18.441 36.899 18.441 12.015 0 23.406-.95 23.406-11.134 0-10.184-24.223-11.097-32.909-18.531-8.688-7.435-16.28-11.178-20.878-4.479Z"
          class="gray-stroke"
        />
        <path
          d="m256.07 130.896-7 1.132a4.441 4.441 0 0 0-3.279 6.338l3.001 6.123s4.566 7.191 3.752 14.92c-.813 7.728 0 12.61 0 12.61s0-3.254 5.085-2.848c5.084.407 4.678 10.17 3.457 13.831 10.896 12.555 15.124 25.343 15.124 25.343l13.249-1.014 10.308-15.743s19.155-18.266 8.438-50.078c-6.441-19.118-52.135-10.614-52.135-10.614Z"
          class="gray-fill"
        />
        <path
          d="M244.476 165.104s3.189 8.128.749 13.017"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M244.942 164.921c.367 1.095.636 2.179.866 3.287.221 1.105.397 2.22.469 3.345.154 2.23-.015 4.573-1.052 6.568.881-2.067.866-4.35.554-6.515-.151-1.089-.405-2.164-.7-3.222-.3-1.052-.652-2.11-1.068-3.098l.931-.365Z"
          class="gray-fill"
        />
        <g v-if="showArrows">
          <path
            d="M199.949 66.814V38.858h-13.38v27.956h-6.224l12.914 11.184 12.914-11.184h-6.224ZM235.615 423.58h27.957V410.2h-27.957v-6.224l-11.183 12.914 11.183 12.914v-6.224ZM235.615 375.181h27.957v-13.38h-27.957v-6.224l-11.183 12.914 11.183 12.914v-6.224Z"
            class="orange-arrow"
          />
        </g>
        <path
          d="M169.019 201.311c1.106.865 2.479.593 6.923.142 2.256-.229 9.677-4.828 9.56-7.445-.204-4.529-.52-8.907 3.451-8.907 2.981 0-1.38 3.899 3.898 10.325 2.484 3.022 1.422 8.078 1.422 8.078l13.363 20.454 20.501 1.015c-7.321 5.379-4.684 23.379-4.684 23.379s-22.202-3.624-26.364-8.559c-4.162-4.935-10.777-29.066-10.777-29.066s-11.474-1.227-15.924-2.731c-4.45-1.504-6.497-6.092-7.255-8.496-.758-2.406-1.656-6.886-1.656-6.886s-.151.553 2.152 3.492c2.837 3.623 2.159 2.713 5.39 5.205Z"
          style="fill:#fff;fill-rule:nonzero"
        />
        <path
          d="M169.019 201.311c1.106.865 2.479.593 6.923.142 2.256-.229 9.677-4.828 9.56-7.445-.204-4.529-.52-8.907 3.451-8.907 2.981 0-1.38 3.899 3.898 10.325 2.484 3.022 1.422 8.078 1.422 8.078l13.363 20.454 20.501 1.015c-7.321 5.379-4.684 23.379-4.684 23.379s-22.202-3.624-26.364-8.559c-4.162-4.935-10.777-29.066-10.777-29.066s-11.474-1.227-15.924-2.731c-4.45-1.504-6.497-6.092-7.255-8.496-.758-2.406-1.656-6.886-1.656-6.886s-.151.553 2.152 3.492c2.837 3.623 2.159 2.713 5.39 5.205Z"
          style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linejoin:round"
        />
      </g>
    </g>
  </svg>
</template>
<script setup>
  import { getRaceCode } from "@/utils/GlobalState"

  const race = getRaceCode()
  defineProps({
    showArrows: {
      type: Boolean,
      default: false,
    }
  })
</script>
