<script setup>
  import LengthExample from "@/components/svg/LengthExample.vue"
  import { setCurrentViewContext } from "@/utils/GlobalState"

  setCurrentViewContext(null, "library")
</script>
<template>
  <article class="library-item">
    <h1 class="title">
      {{ $gettext("How to measure length") }}
    </h1>
    <h2 class="subtitle">
      {{ $gettext("Children younger than 2 years or older who are unable to stand") }}
    </h2>
    <section class="columns mb-5 mt-5">
      <div class="column">
        <h2>{{ $gettext("You Need") }}</h2>
        <ul>
          <li>{{ $gettext("A length board or length mat") }}</li>
          <li>{{ $gettext("An assistant") }}</li>
        </ul>
      </div>
      <div class="column is-three-quarters">
        <LengthExample />
      </div>
    </section>
    <div class="columns mb-6">
      <section class="column">
        <div class="block">
          <h2>{{ $gettext("Follow these steps") }}</h2>
    
          <ol>
            <li>{{ $gettext("Place length board on a flat surface (floor or a steady table).") }} </li>
            <li>{{ $gettext("Remove child’s shoes, hats and hair ornaments.") }} </li>
            <li>{{ $gettext("Lay child flat and centered on the length mat/board.") }} </li>
            <li>{{ $gettext("Stand to the side of the child where the length board numbers show.") }} </li>
            <li>{{ $gettext("Ask the assistant to stand behind the headpiece. The assistant’s head should be straight over the child’s head, looking directly into the child’s eyes.") }} </li>
            <li>{{ $gettext("Ask assistant to align the top of the head against the fixed headpiece. Head should be positioned so that the child’s line of sight is perpendicular to the base of the board.") }} </li>
            <li>{{ $gettext("Straighten the child’s legs by placing your hand on the child’s shin or knees and pressing them firmly but gently against the board.") }} </li>
            <li>{{ $gettext("Adjust foot piece so that the child’s feet are flat against it.") }} </li>
            <li>{{ $gettext("Read and record length to the nearest 0.1 cm (e.g. 86.4 cm).") }} </li>
          </ol>
        </div>
      </section>
      <aside class="column card">
        <div class="card-content">
          <h2>{{ $gettext("Tips") }}</h2>
          <ul>
            <li>{{ $gettext("If you are standing to the right of the child, hold the child’s shins or knees with the left hand and the foot piece with the right hand.") }}</li>
            <li>{{ $gettext("The person standing to the side of the child, not the assistant, should be the one reading the measurement.") }}</li>
            <li>{{ $gettext("The head and the foot pieces should press firmly against the child’s head and feet.") }}</li>
            <li>{{ $gettext("Before reading the measurement make sure all of the child’s body parts are properly positioned.") }}</li>
            <li>{{ $gettext("Do not measure length using a measuring tape or ruler taped to a baby scale or floor.") }}</li>
            <li>{{ $gettext("Do not use the length board on children older than 2 years who are unable to stand if they are longer than the board.") }}</li>
          </ul>
        </div>
      </aside>
    </div>
    <div class="columns">
      <div class="column">
        <div class="message is-spoon-blue">
          <div class="message-header">
            <p style="margin-bottom: 0">
              {{ $gettext("When measuring children, always:") }}
            </p>
          </div>
          <div class="message-body">
            <ul>
              <li>{{ $gettext("Sanitize your hands before and afterwards.") }}</li>
              <li>{{ $gettext("Sanitize equipment after use.") }}</li>
              <li>{{ $gettext("Store equipment in a dry place.") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
