<script setup>
  import HeightProfileExample from "@/components/svg/HeightProfileExample.vue"
  import HeightThreeQuarterViewExample from "@/components/svg/HeightThreeQuarterViewExample.vue"
  import { setCurrentViewContext } from "@/utils/GlobalState"

  setCurrentViewContext(null, "library")
</script>
<template>
  <article class="library-item">
    <h1 class="title">
      {{ $gettext("How to measure length") }}
    </h1>
    <h2 class="subtitle">
      {{ $gettext("Children 2 years and older and who are able to stand") }}
    </h2>
    <section
      class="columns is-mobile mt-5 mb-5"
      style="align-items: end"
    >
      <div class="column is-two-thirds">
        <h2>{{ $gettext("You Need") }}</h2>
        <ul>
          <li>{{ $gettext("A height board or a wall-mounted height rod") }}</li>
          <li>{{ $gettext("An assistant") }}</li>
        </ul>
        <HeightThreeQuarterViewExample />
      </div>
      <div class="column">
        <HeightProfileExample />
      </div>
    </section>
    <div class="columns mb-6">
      <section class="column">
        <div class="block">
          <h2>{{ $gettext("Follow these steps") }}</h2>

          <ol>
            <li>{{ $gettext("If using height board, place on flat floor surface against a wall.") }}</li>
            <li>{{ $gettext("Remove child’s shoes, hat and hair ornaments.") }}</li>
            <li>{{ $gettext("Ask the child to stand in the center and against the base of the board or wall.") }}</li>
            <li>{{ $gettext("Stand or kneel to the side of the child where the height board numbers show.") }}</li>
            <li>{{ $gettext("Ask assistant to kneel on the side opposite of you.") }}</li>
            <li>{{ $gettext("Ask assistant to make sure legs are straight and heels and calves are against board or wall.") }}</li>
            <li>{{ $gettext("Make sure the child’s shoulders are level, hands are at the child’s side, and the head, shoulder blades and buttocks are against the board/wall.") }}</li>
            <li>{{ $gettext("Place your hand under the child’s chin and gently close your hand. Do not cover the child’s mouth or ears.") }}</li>
            <li>{{ $gettext("Ask child to look straight ahead until line of sight is level with the ground. Adjust child’s head as needed.") }}</li>
            <li>{{ $gettext("Slide the headpiece downwards through the child’s hair.") }}</li>
            <li>{{ $gettext("Read and record height to the nearest 0.1 cm (e.g. 112.8 cm).") }}</li>
          </ol>
        </div>
      </section>
      <aside class="column card">
        <div class="card-content">
          <h2>{{ $gettext("Tips") }}</h2>
          <ul>
            <li>{{ $gettext("If you are standing to the left side of the child, hold the headpiece with the right hand and the child’s chin with the left hand.") }}</li>
            <li>{{ $gettext("The headpiece should press firmly against the child’s head pushing through the hair.") }}</li>
            <li>{{ $gettext("The person lowering the head piece, not the assistant, should be the one reading the measurement.") }}</li>
            <li>{{ $gettext("Before reading the measurement make sure all of the child’s body parts are properly positioned.") }}</li>
            <li>{{ $gettext("Do not measure height using a measuring tape or ruler attached to a wall.") }}</li>
          </ul>
        </div>
      </aside>
    </div>
    <div class="columns">
      <div class="column">
        <div class="message is-spoon-blue">
          <div class="message-header">
            <p style="margin-bottom: 0">
              {{ $gettext("When measuring children, always:") }}
            </p>
          </div>
          <div class="message-body">
            <ul>
              <li>{{ $gettext("Sanitize your hands before and afterwards.") }}</li>
              <li>{{ $gettext("Sanitize equipment after use.") }}</li>
              <li>{{ $gettext("Store equipment in a dry place.") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<style scoped>
  svg {
    height: auto;
  }
</style>
